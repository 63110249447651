export interface WebhookConfigInterface {
    url?: string;
    method: string;
    basicAuth: {
        enabled: boolean;
        login?: string;
        password?: string
    },
    headers: WebhookHeaderInterface[]
}

export interface WebhookHeaderInterface {
    name: string;
    value: string;
}

export const WEBHOOK_METHODS = ["post", "get"];

export function getEmptyWebhookConfigModel(): WebhookConfigInterface {
    return {
        url: null,
        method: "post",
        basicAuth: {
            enabled: false,
            login: null,
            password: null
        },
        headers: []
    }
}