import MyRouterView from "~/core-ui/vue/router/MyRouterView.vue";
import {Route, RouteConfig} from "~/node_modules/vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import Dictionary from "~/ts/library/Dictionary";
import {RouteConfigMultipleViews, RouteConfigSingleView} from "vue-router/types/router";

export default class RouteHelper {

    public static isRouteForGuest(route: Route): boolean {
        return route.matched.some(record => (record.meta as IRouteMeta).guest);
    }


    public static isRouteForDealer(route: Route): boolean {
        return route.matched.some(record => (record.meta as IRouteMeta).dealer);
    }

    public static isRouteForGuestOrUser(route: Route): boolean {
        return route.matched.some(record => (record.meta as IRouteMeta).guestOrUser);
    }

    static toQuery(value: Dictionary<any>): Dictionary<string> {
        return {
            q: JSON.stringify(value)
        }
    }

    static fromQuery(query: Dictionary<string>): any {
        if (query.q) {
            try {
                return JSON.parse(query.q as string);
            } catch (e) {
            }
        }
        return null;
    }


    public static setDefaultRoutesComponents(routes: RouteConfig[], force: boolean = false) {
        for (let route of routes) {
            this.setDefaultRouteComponents(route, force);
        }
    }

    public static setDefaultRouteComponents(routeConfig: RouteConfig, force: boolean = false) {
        let hasComponent = (routeConfig as RouteConfigSingleView).component || ((routeConfig as RouteConfigMultipleViews).components && (routeConfig as RouteConfigMultipleViews).components.default);
        if (!routeConfig.meta) {
            routeConfig.meta = {};
        }
        let meta: IRouteMeta = routeConfig.meta;

        if (force || !hasComponent) {

            if (!routeConfig.props) {
                routeConfig.props = {};
            }


            if (!hasComponent) {
                if (!(routeConfig as RouteConfigMultipleViews).components) {
                    (routeConfig as RouteConfigMultipleViews).components = {};
                }

                (routeConfig as RouteConfigMultipleViews).components.default = MyRouterView;
            }

            if (meta.keepAlive == null) {
                meta.keepAlive = true;
            }

            if (routeConfig.children) {
                let childrensWithoutParams = routeConfig.children.filter(children => children.path.indexOf(":") == -1);
                if (childrensWithoutParams.length) {
                    meta.redirectToChildren = childrensWithoutParams;
                }
            }
        }
        if (routeConfig.children && routeConfig.children.length > 0) {
            for (let children of routeConfig.children) {
                RouteHelper.setDefaultRouteComponents(children);
            }
        }
    }
}