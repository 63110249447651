import {
    ContentFormParamsInterface,
    SenderFormSettingsModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {ContentVariableGroupInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {SenderFormParamsInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {PropType} from "vue";

export function useContentFormTabProps<Value, ContentFormParams extends ContentFormParamsInterface = ContentFormParamsInterface>() {
    return {
        value: {
            type: Object as PropType<Value>
        },
        contentFormParams: {
            type: Object as PropType<ContentFormParams>
        },
        contentVariableGroups: {
            type: Array as PropType<ContentVariableGroupInterface[]>
        },
        onTemplatesButtonClick: {
            type: Function as PropType<() => void>
        },
        formParams: {
            type: Object as PropType<SenderFormParamsInterface>
        },
        settingsModel: {
            type: Object as PropType<SenderFormSettingsModelInterface>
        }
    }
}