import AbstractEntity from "~/ts/library/AbstractEntity";

export class CouponRegister extends AbstractEntity {
    id: string;
    isEnabled: boolean;
    descr: string;
    dateTimeCreate: string;
    dateTimeStart: string;
    couponCodeLength: number;
    stopSchemeAfterDays: number;
    stopSchemeDateTime: string;
    stopSchemeIndefinitely: boolean;
    instruction: string;
    tags: [];
}