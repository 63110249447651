export const HTML_BLOCK_TYPE_TEXT_FLOAT_IMG = "textFloatImg";
export const HTML_BLOCK_TYPE_IMAGE_UNDER_TEXT = "imgUnderText";
export const HTML_BLOCK_TYPE_BUTTON = "button";
export const HTML_BLOCK_TYPE_H1 = "h1";
export const HTML_BLOCK_TYPE_H2 = "h2";
export const HTML_BLOCK_TYPE_H3 = "h3";
export const HTML_BLOCK_TYPE_HEADER = "header";
export const HTML_BLOCK_TYPE_HR = "hr";
export const HTML_BLOCK_TYPE_IMAGE = "img";
export const HTML_BLOCK_TYPE_STRUCTURE = "structure";
export const HTML_BLOCK_TYPE_TEXT = "text";
export const HTML_BLOCK_TYPE_ATTENTION_TEXT = "attentionText";
export const HTML_BLOCK_SAVED = "saved";
export const HTML_BLOCK_CONTENTS = "contents";
export const HTML_BLOCK_CODE = "code";

