import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ITextBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/text/ITextBlockValues";
import TextDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/text/TextDisplay.vue";
import {
    HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM,
    HtmlConstructorBlockToolbarPosition
} from "~/cabinet/vue/interface/htmlConstructor/blocks/HtmlConstructorBlockToolbarPosition";
import {Component} from "vue";


export default class TextBlockType extends AbstractBlockType<ITextBlockValues, any> {


    get displayComponent(): Component {
        return TextDisplay;
    }

    get formComponent(): Component {
        return null;
    }

    get isBlockWithoutPadding(): boolean {
        return true;
    }

    get toolbarPosition(): HtmlConstructorBlockToolbarPosition {
        return HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM;
    }
}
