import Vue from "vue";
import {MessageBoxData} from "element-ui/types/message-box";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IShowMessageOptions} from "~/ts/vuePlugins/ShowMessagePlugin";

export interface ShowApiResultMessageOptionsInterface {
}

export abstract class AbstractApiResult<T, ResponseFormat> {
    protected response: ResponseFormat;
    protected _data: T;

    constructor(response: ResponseFormat, dataType?: AbstractEntityBuilder<T>) {
        this.response = response;
        this._data = this.getDataFromResponse(response);
        if (dataType) {
            this._data = dataType.new(this._data);
        }
        let xhprof = this.getParam("xhprof");
        if (xhprof) {
            console.log(xhprof);
        }
    }

    public abstract get isSuccess(): boolean;

    public abstract get code(): number;

    public abstract get descr(): string;

    protected abstract getDataFromResponse(response: ResponseFormat): any;


    get data(): T {
        return this._data;
    }

    private getKeys(key: string | string[] = null) {
        if (key == null) {
            key = [];
        } else if (typeof key == "string") {
            key = [key];
        }
        return key;
    }

    public getParam(key: string | string[] = null, defaultValue: any = null): any {
        return ObjectHelper.getParam(this.response, this.getKeys(key), defaultValue)
    }


    public getData(key: string | string[] = null, defaultValue: any = null): any {
        return ObjectHelper.getParam(this.data, this.getKeys(key), defaultValue)
    }


    protected get showMessageText(): string {
        return this.descr;
    }


    public showMessage(options?: ShowApiResultMessageOptionsInterface): Promise<MessageBoxData> {
        let descr = this.showMessageText;
        if (!options) {
            options = {};
        }

        return Vue.prototype.$showMessage({
            message: descr,
            type: this.isSuccess ? "success" : "error",
            ...options
        });
    }

    public showMessageOnError(): boolean {
        let result = !this.isSuccess;
        if (result) {
            (async () => {
                try {
                    await this.showMessage();
                } catch (e) {

                }
            })();

        }
        return result;
    }

    public static async showMessage(message: string, type: "error" | "success", dangerouslyUseHTMLString?: boolean) {
        try {
            await Vue.prototype.$showMessage({
                message,
                type,
                dangerouslyUseHTMLString,
                options: {}
            } as IShowMessageOptions);
        } catch (e) {

        }
    }


    public static async showError(message: string) {
        await this.showMessage(message, "error");
    }

    public static async showSuccess(message: string) {
        await this.showMessage(message, "success");
    }
}

