export const FILTER_TYPE_SECONDS = "seconds";
export const FILTER_TYPE_TEXT = "text";
export const FILTER_TYPE_SELECT = "select";
export const FILTER_TYPE_INTEGER = "int";
export const FILTER_TYPE_LOCATION = "location";
export const FILTER_TYPE_COMBINED = "combined";

export const FILTER_TYPE_DATE = "date";
export const FILTER_TYPE_DATETIME = "datetime";
export const FILTER_TYPE_TIME = "time";
export const FILTER_TYPE_FLOAT = "float";
export const FILTER_TYPE_TIME_RANGE = "timeRange";
export const FILTER_TYPE_TEXTAREA = "textarea";
export const FILTER_TYPE_HTML = "html";
export const FILTER_TYPE_CHECKBOX = "checkbox";
export const FILTER_TYPE_HIDDEN = "hidden";
