import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {RouteConfig} from "~/node_modules/vue-router";
import {__} from "~/ts/library/Translate";
import Section from "~/cabinet/vue/layout/lcab/Aside/Section";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import ClientRoutesBuilder from "~/cabinet/ts/routes/ClientRoutesBuilder";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {Component} from "vue";

const ROUTING_TARIFS = "tarifs";
//const ROUTING_API = "api";


export default abstract class UslugaRouteBuilder {
    protected usluga: Usluga;
    private descr: string;
    protected clientRoutesBuilder: ClientRoutesBuilder;

    constructor(clientRoutesBuilder: ClientRoutesBuilder, usluga: Usluga, descr: string = null) {
        this.clientRoutesBuilder = clientRoutesBuilder;
        if (!descr) {
            descr = usluga.descr;
        }
        this.descr = descr;
        this.usluga = usluga;
    }

    protected getRouteName(routeName: string) {
        return UslugaRouteBuilder.getRouteName(this.usluga.id, routeName);
    }

    protected static getRouteName(uslugaId: string, routeName: string) {
        return uslugaId + "_" + routeName;
    }

    public getTarifRouteName() {
        return UslugaRouteBuilder.getTarifRouteNameForUsluga(this.usluga.id);
    }

    public static getTarifRouteNameForUsluga(uslugaId: string) {
        return UslugaRouteBuilder.getRouteName(uslugaId, ROUTING_TARIFS);

    }

    public static getActiveForSelfAccessId(uslugaId: string): string {
        return "usluga_activeForSelf_" + uslugaId;
    }

    make(): RouteConfig {
        let route = this.makeRoute();
        let section = Section.createForRoute(route, this.descr, this.usluga.icon);
        section.iconColor = this.usluga.iconColor;
        let badgeValue = this.usluga.badgeValue;
        if (badgeValue) {
            section.setBadge(new MenuBadge(`usluga_${this.usluga.id}`, null, badgeValue));
        } else if (this.usluga.badgeId) {
            section.setBadge(new MenuBadge(this.usluga.badgeId));
        }
        let meta: IRouteMeta = route.meta ? route.meta : {};
        meta.uslugaId = this.usluga.id;
        route.meta = meta;

        let tarifRoute = this.getTarifRoute();
        if (tarifRoute) {
            route.children.push(tarifRoute);
            meta.menu.push(this.getTarifMenuItem());
        }


        if (!meta.access) {
            meta.access = [];
        }

        if (Array.isArray(meta.access)) {
            meta.access.push(...[
                this.usluga.activeForSelfAccessId,
                () => AccountStore.access.value.checkAccessForUslugaSection(this.usluga.id)
            ]);
        }

        RouteHelper.setDefaultRouteComponents(route);
        return route;
    }

    protected abstract makeRoute(): RouteConfig;

    private get tarifComponent(): Component | null {
        return this.usluga ? this.usluga.client.tarifComponent : null;
    }

    protected getTarifRoute(): RouteConfig | null {
        let tarifComponent = this.tarifComponent;

        return tarifComponent ? {
            path: "tarif",
            name: this.getTarifRouteName(),
            component: tarifComponent,
            props: {
                uslugaId: this.usluga.id
            },
            meta: {
                access: [
                    () => AccountStore.access.value.checkAccessForTarifInfo()
                ]
            } as IRouteMeta
        } as RouteConfig : null;
    }

    protected getTarifMenuItem(): MenuItem {
        return (new MenuItem(this.getTarifRouteName(), __("Тарифы")))
            .setIcon("usd-circle");
    }
}