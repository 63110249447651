import Currency from "~/cabinet/ts/service/Currency";
import AbstractEntity, {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ComponentOptions} from "vue";
import {UseTarifListInterface} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/useTarifList";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {TarifPath} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/Interfaces";
import SinglePriceEdit from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/SinglePriceEdit.vue";
import SinglePriceTableCell from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/SinglePriceTableCell.vue";

export class TarifPriceType {
    constructor(private className: AbstractEntityBuilder<AbstractTarifPrice>, public id: string, public descr: string) {
    }

    createNew(data?: any): AbstractTarifPrice {
        return this.className.new(data);
    }

    getClassName() {
        return this.className;
    }
}

export abstract class AbstractTarifPrice extends AbstractEntity {
    public currencyId: string = AccountStore.currencyArray.value?.[0]?.id ?? "rub";
    private priceTypeId: string = null;

    protected afterInit() {
        this.priceTypeId = this.getPriceTypeId()
    }

    abstract get tableComponent(): ComponentOptions<any> | any;

    abstract get editComponent(): ComponentOptions<any> | any;

    abstract getPriceTypeId(): string;

    abstract applyActionProfitPercentByCostTarifPrice(percent: number, cost: AbstractTarifPrice): this;

    abstract applyActionMinRaznostByCostTarifPrice(minRaznost: number, cost: AbstractTarifPrice): this;

    abstract applyActionUpdateByPercent(percent: number): this;

    abstract applyActionPlusPrice(plusValue: number): this;

    public abstract priceToArray(): number[];

    abstract makeConvertCurrencyTo(newCurrencyId: string): void;

    abstract formatPrice(): this;


    public copyFrom(source: AbstractTarifPrice) {
        this.currencyId = source.currencyId;
        return this.applySumFromSource(source);
    }

    getCurrencyId(): string {
        return this.currencyId;
    }

    public setCurrencyId(value: string) {
        this.currencyId = value;
        return this;
    }

    public getPriceForHighlight(tarifList: UseTarifListInterface<any>, tarifId: string, path: TarifPath): { sum: number, currencyId: string } {
        //console.log("toSinglePrice common", tarifId, path);
        return {
            sum: this.getMaxPrice(),
            currencyId: this.getCurrencyId()
        }
        //return SingleTarifPrice.new().copyFrom(this);
    }


    public getMaxPrice(): number {
        return Math.max(...this.priceToArray());
    }

    public getMinPrice(): number {
        return Math.min(...this.priceToArray());
    }

    public convertCurrencyTo(newCurrencyId: string): this {
        if (this.getCurrencyId() !== newCurrencyId) {
            this.makeConvertCurrencyTo(newCurrencyId);
            this.currencyId = newCurrencyId;
        }
        return this;
    }

    public getSinglePriceBySum(sum: number): SingleTarifPrice {
        let value: SingleTarifPriceInterface = {
            sum,
            currencyId: this.getCurrencyId()
        };
        return SingleTarifPrice.new(value);
    }

    public getSinglePriceByMaxSum(): SingleTarifPrice {
        return this.getSinglePriceBySum(this.getMaxPrice());
    }

    protected abstract applySumFromSource(source: AbstractTarifPrice): this;

    protected incrPriceByPercent(sum: number, percent: number) {
        let onePercent = sum / 100;
        let result = onePercent * percent + sum;
        result = result > 0 ? Currency.formatPrice(result, this.getCurrencyId()) : 0;
        return result;
    }

    public getValueForModel(): any {
        return ObjectHelper.jsonClone(this);
    }
}

interface TarifPriceInterface {
    currencyId: string;
}

interface SingleTarifPriceInterface extends TarifPriceInterface {
    sum: number
}

export class SingleTarifPrice extends AbstractTarifPrice {
    public sum: number = 1;

    get editComponent(): ComponentOptions<any> | any {
        return SinglePriceEdit;
    }

    get tableComponent(): ComponentOptions<any> | any {
        return SinglePriceTableCell;
    }

    getPriceTypeId(): string {
        return "singlePrice";
    }

    applyActionMinRaznostByCostTarifPrice(minRaznost: number, cost: AbstractTarifPrice): this {
        let result = this.sum;
        let costCurrency = cost.getCurrencyId();
        let currencyId = this.currencyId;
        let localCost = Currency.convert(cost.getMaxPrice(), costCurrency, currencyId);
        let delta = result - localCost;
        if (delta < minRaznost) {
            result += minRaznost - delta;
        }
        this.sum = result;
        return this;
    }

    applyActionPlusPrice(plusValue: number): this {
        let result = this.sum + plusValue;
        if (result < 0) {
            result = 0;
        }
        this.sum = result;
        return this;
    }

    applyActionProfitPercentByCostTarifPrice(percent: number, cost: AbstractTarifPrice): this {
        let result = this.sum;
        let costMaxPrice = cost.getMaxPrice();
        let incrCost = this.incrPriceByPercent(costMaxPrice, percent);
        if (incrCost > 0) {
            let incrCostInTarifCurrency = Currency.convert(incrCost, cost.getCurrencyId(), this.getCurrencyId());
            if (incrCostInTarifCurrency > result) {
                result = incrCostInTarifCurrency;
            }
        }
        this.sum = result;
        return this;
    }


    applyActionUpdateByPercent(percent: number): this {
        let result = this.sum;
        let onePercent = result / 100;
        result = onePercent * percent + result;
        result = result > 0 ? Currency.formatPrice(result, this.getCurrencyId()) : 0;
        this.sum = result;
        return this;
    }

    formatPrice(): this {
        this.sum = this.sum > 0 ? Currency.formatPrice(this.sum, this.currencyId) : 0;
        return this;
    }

    priceToArray(): number[] {
        return [this.sum];
    }

    makeConvertCurrencyTo(newCurrencyId: string): void {
        this.sum = Currency.convert(this.sum, this.getCurrencyId(), newCurrencyId);
    }

    protected applySumFromSource(source: AbstractTarifPrice): this {
        this.sum = source.getMaxPrice();
        return this;
    }
}

export class NumericTarifPrice extends SingleTarifPrice {
    getValueForModel() {
        return this.sum;
    }

    getPriceTypeId(): string {
        return "numeric";
    }
}

export abstract class TarifPriceWithPricesDictionary extends AbstractTarifPrice {
    applyActionMinRaznostByCostTarifPrice(minRaznost: number, cost: AbstractTarifPrice): this {
        this.fetchAndModifyBasedOnKeys(cost, (price1, price2) => {
            return price1.applyActionMinRaznostByCostTarifPrice(minRaznost, price2).sum;
        });
        return this;
    }

    applyActionPlusPrice(plusValue: number): this {
        return this.fetchAndModifyKeys(price => {
            return price.applyActionPlusPrice(plusValue).sum
        });
    }

    applyActionProfitPercentByCostTarifPrice(percent: number, cost: AbstractTarifPrice): this {
        this.fetchAndModifyBasedOnKeys(cost, (price1, price2) => {
            return price1.applyActionProfitPercentByCostTarifPrice(percent, price2).sum;
        });
        return this;
    }

    applyActionUpdateByPercent(percent: number): this {
        return this.fetchAndModifyKeys(price => {
            return price.applyActionUpdateByPercent(percent).sum
        });
    }

    formatPrice(): this {
        return this.fetchAndModifyKeys(singlePrice => {
            return singlePrice.formatPrice().sum;
        });
    }

    makeConvertCurrencyTo(newCurrencyId: string): void {
        this.fetchAndModifyKeys(singlePrice => {
            return singlePrice.convertCurrencyTo(newCurrencyId).sum;
        });
    }

    priceToArray(): number[] {
        return ObjectHelper.arrayFromDictionary(this.getPricesDictionary());
    }

    protected abstract getPricesDictionary(): Dictionary<number>;

    protected applySumFromSource(source: AbstractTarifPrice): this {
        if (source.getPriceTypeId() == this.getPriceTypeId() && source instanceof TarifPriceWithPricesDictionary) {
            let prices = this.getPricesDictionary();
            for (let key in prices) {
                if (prices.hasOwnProperty(key)) {
                    delete prices[key];
                }
            }
            let sourceDictionary = source.getPricesDictionary();
            for (let key in sourceDictionary) {
                if (sourceDictionary.hasOwnProperty(key)) {
                    prices[key] = sourceDictionary[key];
                }
            }
        } else {
            let sourceToSingle = source.getSinglePriceByMaxSum();
            this.fetchAndModifyKeys(() => {
                return sourceToSingle.sum
            });
        }
        return this;
    }

    private fetchAndModifyKeys(callback: (price: SingleTarifPrice, key: string) => number) {
        let prices = this.getPricesDictionary();
        for (let key in prices) {
            if (prices.hasOwnProperty(key)) {
                prices[key] = callback(
                    this.getSinglePriceBySum(prices[key]),
                    key
                )
            }
        }
        return this;
    }

    private fetchAndModifyBasedOnKeys(price2: AbstractTarifPrice, callback: (price1: SingleTarifPrice, price2: SingleTarifPrice) => number) {
        if (this.isPriceKeysSame(price2) && price2 instanceof TarifPriceWithPricesDictionary) {
            this.fetchAndModifySameKeys(price2, callback);
        } else {
            this.fetchAndModifyDifferentKeys(price2, callback);
        }
        return this;
    }

    private isPriceKeysSame(price2: AbstractTarifPrice): boolean {
        if (this.getPriceTypeId() == price2.getPriceTypeId()) {
            if (price2 instanceof TarifPriceWithPricesDictionary) {
                let prices2 = price2.getPricesDictionary();
                return JSON.stringify(ObjectHelper.getKeys(this.getPricesDictionary())) == JSON.stringify(ObjectHelper.getKeys(prices2));
            }
        }
        return false;
    }

    private fetchAndModifySameKeys(price2: TarifPriceWithPricesDictionary, callback: (price1: SingleTarifPrice, price2: SingleTarifPrice) => number) {
        return this.fetchAndModifyKeys((simplePrice, key) => {
            return callback(
                simplePrice,
                price2.getSinglePriceBySum(price2.getPricesDictionary()[key])
            )
        });
    }

    private fetchAndModifyDifferentKeys(price2: AbstractTarifPrice, callback: (price1: SingleTarifPrice, price2: SingleTarifPrice) => number) {
        return this.fetchAndModifyKeys((simplePrice) => {
            return callback(
                simplePrice,
                price2.getSinglePriceByMaxSum()
            )
        });

    }
}