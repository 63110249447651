import {
    HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_TOP,
    HtmlConstructorBlockToolbarPosition
} from "~/cabinet/vue/interface/htmlConstructor/blocks/HtmlConstructorBlockToolbarPosition";
import Dictionary from "~/ts/library/Dictionary";
import IBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/IBlockValues";
import {
    IHtmlConstructorBlock,
    IHtmlConstructorServerBlockType
} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {Component} from "vue";


export default abstract class AbstractBlockType<T extends IBlockValues, ParamsForEditor> {
    readonly descr: string;
    readonly id: string;
    readonly icon: string;
    readonly descrForCommonStyles: string;
    readonly paramsForEditor: ParamsForEditor;
    readonly iconForCommonStyles: string;
    readonly styleForm: IForm;
    private readonly _defaultValue: T;

    constructor(serverBlockType: IHtmlConstructorServerBlockType<ParamsForEditor>) {
        this.descr = serverBlockType.descr;
        this.id = serverBlockType.id;
        this.descrForCommonStyles = serverBlockType.descrForCommonStyles;
        this.icon = serverBlockType.icon;
        this.iconForCommonStyles = serverBlockType.iconForCommonStyles;
        this._defaultValue = ObjectHelper.jsonClone(serverBlockType.defaultValue) as T;
        this.styleForm = serverBlockType.styleForm;
        this.paramsForEditor = serverBlockType.paramsForEditor;
    }

    abstract get formComponent(): Component;

    abstract get displayComponent(): Component;

    get defaultValue(): T {
        return ObjectHelper.jsonClone(this._defaultValue);
    }

    get isSaveable(): boolean {
        return true;
    }

    get isShowEditButton() {
        return true;
    }


    //abstract get descr(): string;


    get isOnce(): boolean {
        return false;
    }

    get isBlockWithoutPadding(): boolean {
        return false;
    }

    get toolbarPosition(): HtmlConstructorBlockToolbarPosition {
        return HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_TOP;
    }

    hasLeftRightPadding(value: T, styles: Dictionary<any>): boolean {
        return true;
    }

    async getNewBlockForList(): Promise<IHtmlConstructorBlock<T>> {
        return {
            type: this.id,
            values: {} as T
        }
    }
}
