import Dictionary from "./Dictionary";
import {Timeouts} from "~/ts/library/delay/Timeouts";
import {removeArrayElement} from "~/ts/library/ToggleArrayElement";

const ANY_EVENT = "anyEvent";

export default class EventManager {
    private listeners: Dictionary<Array<(data: any) => void>> = {};
    private storedEvents: Dictionary<any[]> = {};

    public addAnyEventListener(callback: (data: IAnyEvent) => void): () => void {
        return this.addEventListener(ANY_EVENT, callback);
    }

    public addEventListener(event: string, callback: (data: any) => void): () => void {
        this.listeners[event] == null ? this.listeners[event] = [callback] : this.listeners[event].push(callback);
        if (this.storedEvents[event]) {
            while (this.storedEvents[event].length) {
                if (this.hasListeners(event)) {
                    let data = this.storedEvents[event].shift();
                    this.emit(event, data);
                }
            }
            if (!this.storedEvents[event].length) {
                this.storedEvents[event] = null;
                delete this.storedEvents[event];
            }
        }
        return () => {
            this.removeEventListener(event, callback);
        };
    }

    public once(event: string, callback: (data: any) => void, timeout: number = 0, timeoutCallback: () => void = null): () => void {
        let timeoutId: number;
        let removeCallback: () => void;
        let onceCallback = (data: any) => {
            removeCallback();
            callback(data);
            if (timeoutId) {
                Timeouts.clear(timeoutId);
            }
        };
        removeCallback = this.addEventListener(event, onceCallback);
        if (timeout) {
            timeoutId = Timeouts.set(() => {
                removeCallback();
                if (timeoutCallback) {
                    timeoutCallback();
                }
            }, timeout);
        }
        return removeCallback;
    }


    public removeEventListener(event: string, callback: (data: any) => void) {
        let callbacks = this.listeners[event];
        if (callbacks) {
            removeArrayElement(callbacks, callback);
            if (!callbacks.length) {
                delete this.listeners[event];
            }
            /*
            let index = callbacks.indexOf(callback);
            if (index > -1) {
                callbacks.splice(index, 1);
            }
             */
        }
    }

    public hasListeners(event: string): boolean {
        let list = this.listeners[event];
        return list && list.length > 0;
    }

    public emit(event: string, data?: any, storeUntilFirstListener: boolean = false): number {
        let count = 0;
        let callbacks = this.listeners[event];
        if (callbacks && callbacks.length) {
            callbacks = [...callbacks];
            count = callbacks.length;
            for (let i = 0; i < count; i++) {
                callbacks[i](data);
            }
        } else if (storeUntilFirstListener) {
            if (!this.storedEvents[event]) {
                this.storedEvents[event] = [];
            }
            this.storedEvents[event].push(data);
        }
        if (event != ANY_EVENT) {
            let anyEvent: IAnyEvent = {event, data};
            count += this.emit(ANY_EVENT, anyEvent);
        }
        return count;
    }

    public removeAllEventListeners() {
        this.listeners = {};
    }
}

export interface IAnyEvent {
    event: string,
    data: any
}

//export default new EventManager();