let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";


class Random {
    public static randomNumber(min: number, max: number): number {
        return Math.random() * (max - min) + min;
    }

    public static shuffle<T>(arr: Array<T>): Array<T> {
        for (let j, x, i = arr.length; i; j = Math.floor(Math.random() * i), x = arr[--i], arr[i] = arr[j], arr[j] = x) ;
        return arr;
    }

    public static randomArrayElement<T>(array: Array<T>): T | null {
        return this.shuffle(array)[0];
    }

    public static uid(min: number = 32, max?: number): string {
        let stringLength = max ? this.randomNumber(min, max) : min;
        let res = '';
        for (var i = 0; i < stringLength; i++) {
            let rnum = Math.floor(Math.random() * chars.length);
            res += chars.substring(rnum, rnum + 1);
        }
        return res;
    }
}

export default Random;