import {AbstractTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";

abstract class AbstractAgentTarifPrice extends AbstractTarifPrice {

    get editComponent(): any {
        return null;
    }

    get tableComponent(): any {
        return null;
    }

    applyActionMinRaznostByCostTarifPrice(minRaznost: number, cost: AbstractTarifPrice): this {
        return this;
    }

    applyActionPlusPrice(plusValue: number): this {
        return this;
    }

    applyActionProfitPercentByCostTarifPrice(percent: number, cost: AbstractTarifPrice): this {
        return this;
    }

    applyActionUpdateByPercent(percent: number): this {
        return this;
    }

    formatPrice(): this {
        return this;
    }

    makeConvertCurrencyTo(newCurrencyId: string): void {
    }

    priceToArray(): number[] {
        return [0];
    }

    protected applySumFromSource(source: AbstractTarifPrice): this {
        return this;
    }
}

export class AgentCommonTarifPriceParam extends AbstractAgentTarifPrice {
    minSum: number;
    outputMoneyTypeId: string;

    getPriceTypeId(): string {
        return "common";
    }
}

export class AgentUslugaTarifPriceParam extends AbstractAgentTarifPrice {
    dayLimit: number = 0;
    proc: number = 0;


    getPriceTypeId(): string {
        return "usluga";
    }
}