import {computed} from "vue";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";

let prefix = computed(() => LcabLayoutStore.appUrlPrefix.value);


export default class DocumentationHelper {
    public static getUrl(sections: string[], hash?: string): string {
        return `${prefix.value}/json-doc/${sections.join(',')}${hash ? '#' + hash : ''}`;
    }
}