import Dictionary from "~/ts/library/Dictionary";
import AbstractHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/AbstractHtmlConstructorType";
import EmailConstructorType from "~/cabinet/vue/interface/htmlConstructor/constructorType/email/EmailConstructorType";
import OnlineChatHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/onlineChatHtml/OnlineChatHtmlConstructorType";
import KnowledgeBaseHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/knowledgeBase/KnowledgeBaseHtmlConstructorType";
import NewsHtmlConstructorType from "./news/NewsHtmlConstructorType";

export const HTML_CONSTRUCTOR_EMAIL_TYPE_ID = "email";
export const HTML_CONSTRUCTOR_ONLINE_CHAT_HTML_TYPE_ID = "onlineChatHtml";
export const HTML_CONSTRUCTOR_KNOWLEDGE_BASE_TYPE_ID = "knowledgeBase";
export const HTML_CONSTRUCTOR_NEWS_TYPE_ID = "news";

export const HTML_CONSTRUCTOR_TYPES: Dictionary<AbstractHtmlConstructorType> = {
    [HTML_CONSTRUCTOR_EMAIL_TYPE_ID]: new EmailConstructorType(),
    [HTML_CONSTRUCTOR_ONLINE_CHAT_HTML_TYPE_ID]: new OnlineChatHtmlConstructorType(),
    [HTML_CONSTRUCTOR_KNOWLEDGE_BASE_TYPE_ID]: new KnowledgeBaseHtmlConstructorType(),
    [HTML_CONSTRUCTOR_NEWS_TYPE_ID]: new NewsHtmlConstructorType()
}
