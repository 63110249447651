import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {USLUGA_ID_CALL_GATE} from "~/cabinet/ts/Constant";

const CallGate: Usluga = Usluga.new({
    id: USLUGA_ID_CALL_GATE,
    badgeValue: "New",
    dealer: {
        config: {
            tarifComponent: () => import(/* webpackChunkName: "dealer-callgate" */ '~/cabinet/vue/dealer/config/uslugi/tarif/callGate/CallGateTarifList.vue')
        }
    },
    client: {
        tarifComponent: () => import(/* webpackChunkName: "client-callgate" */ '~/cabinet/vue/client/tarif/CallGateTarifs.vue')
    }
});

export default CallGate;
