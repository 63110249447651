import {RawLocation, Route, RouteConfig} from "vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import PublicLayout from "~/cabinet/vue/layout/public/Layout.vue";
import {DASHBOARD_ID} from "~/cabinet/ts/routes/DashboardRouteBuilder";
import {Location} from "vue-router/types/router";

export const PUBLIC_WEBHOOK_DOC_ROUTE_NAME = "publicWebhookDocumentation";
export const PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME = "publicOnlineChatWidgetDocumentation";


export function getWebhookDocumentationLocation(platformId: string): RawLocation {
    return {
        name: PUBLIC_WEBHOOK_DOC_ROUTE_NAME,
        params: {
            platformId
        }
    }
}

export function getJavascriptDocumentationLocation(methodId: string): RawLocation {
    return {
        name: PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME,
        params: {
            sectionId: methodId
        }
    }
}

export default class PublicRoutesBuilder {
    public make(): RouteConfig {
        let result: RouteConfig = {
            name: "PUBLIC_ROOT_ROUTE",
            path: "/public",
            component: PublicLayout,
            meta: {
                guestOrUser: true
            } as IRouteMeta,
            children: [
                this.getOnlineRenewLicense(),
                this.getTarifs(),
                this.getDocumentation(),
                this.getApplication(),
                this.getDashboardAnonymousLink()
            ] as RouteConfig[]
        };
        RouteHelper.setDefaultRouteComponents(result, true);
        return result;
    }

    private getOnlineRenewLicense(): RouteConfig {
        return {
            name: "PUBLIC_ONLINE_RENEW_LICENSE",
            path: "online/renewLicense",
            component: () => import(/* webpackChunkName: "online-renew-license" */ '~/cabinet/vue/public/online/renewLicense/RenewLicense.vue'),
        }
    }

    private getTarifs(): RouteConfig {
        return {
            name: "PUBLIC_TARIFS",
            path: "tarifs",
            children: [
                {
                    path: ":uslugaId/:ocode?",
                    component: () => import(/* webpackChunkName: "public-tarifs" */ '~/cabinet/vue/public/tarifs/PublicTarifs.vue'),
                    props: (route: Route) => ({
                        uslugaId: route.params.uslugaId,
                        ocode: route.params.ocode
                    })
                }
            ]
        }
    }

    private getDashboardAnonymousLink(): RouteConfig {
        const name = "PUBLIC_ANONYMOUS_DASHBOARD_LINK";
        const dashboardName = `${name}_DASHBOARD`;
        return {
            name,
            path: "dashboard/:linkId",
            component: () => import(/* webpackChunkName: "dashboard" */ '~/cabinet/vue/client/dashboard/AnonymousDashboardLink.vue'),
            props: (route: Route) => ({
                linkId: route.params.linkId,
                getRawLocationByDashboardId: (dashboardId: string) => {
                    return {
                        name: dashboardName,
                        params: {
                            linkId: route.params.linkId,
                            [DASHBOARD_ID]: dashboardId
                        }
                    } as Location;
                }
            }),
            children: [
                {
                    name: dashboardName,
                    path: ":dashboardId",
                    props: (route: Route) => ({
                        linkId: route.params.linkId,
                        [DASHBOARD_ID]: route.params.dashboardId
                    })

                }
            ]
        }
    }

    private getDocumentation(): RouteConfig {
        return {
            path: 'documentation',
            meta: {guestOrUser: true} as IRouteMeta,
            children: [
                {
                    name: PUBLIC_WEBHOOK_DOC_ROUTE_NAME,
                    path: 'webhook/:platformId/:sectionId?',
                    component: () => import(/* webpackChunkName: "webhook-doc" */ '~/cabinet/vue/public/documentation/WebhookDocumentation.vue'),
                    props: (route: Route) => ({
                        platformId: route.params.platformId,
                        sectionId: route.params.sectionId
                    })
                },

                {
                    name: PUBLIC_ONLINE_CHAT_WIDGET_DOC_ROUTE_NAME,
                    path: 'onlineChatWidget/:sectionId?',
                    component: () => import(/* webpackChunkName: "online-chat-widget-doc" */ '~/cabinet/vue/public/documentation/OnlineChatWidgetDocumentation.vue'),
                    props: (route: Route) => ({
                        sectionId: route.params.sectionId
                    })
                }
            ]
        }
    }

    private getApplication(): RouteConfig {
        return {
            path: 'app',
            children: [
                {
                    name: "PUBLIC_OPEN_CLIENT_IN_APPLICATION",
                    path: 'openClient/:username',
                    component: () => import(/* webpackChunkName: "public-client-in-application" */ '~/cabinet/vue/public/application/OpenClientInApplication.vue'),
                    props: (route: Route) => ({
                        username: route.params.username
                    })
                },
            ]
        }
    }
}