var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.MyDialog,{attrs:{"dialog":{
            customClass: 'overflow right no-padding w-75',
            appendToBody: true
        },"visible":_setup.isVisible,"button-text":"Сохранить текущую форму в шаблон","mobile-full-width-button":"","title":"Шаблоны"},on:{"update:visible":function($event){_setup.isVisible=$event},"apply":function($event){_setup.saveDialogVisible = true}}},[_c(_setup.MyRemoteDataTable,{ref:"table",attrs:{"data-url":`/api/sender/templates/${_vm.uslugaId}/${_vm.formId}/getTemplateList`,"disabled":!_setup.isVisible,"items":_setup.items,"draggable":"","row-key":"id"},on:{"update:items":function($event){_setup.items=$event},"row-click":_setup.onRowClick,"draggable-stop":_setup.onDraggableStop}},[_c('el-table-column',{attrs:{"label":"Название шаблона","prop":"descr","width":"180px"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Контент"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c(_setup.MessageContent,{attrs:{"usluga-id":_vm.uslugaId,"message":row.message,"in-reports":""}})]}}])}),_vm._v(" "),_c('el-table-column',{staticClass:"text-right",attrs:{"width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c(_setup.MyIconButton,{attrs:{"delete":""},on:{"click":function($event){return _setup.deleteTemplate(row)}},nativeOn:{"click":function($event){$event.stopPropagation();}}})]}}])})],1),_vm._v(" "),_c(_setup.MyDialog,{attrs:{"dialog":{
                appendToBody: true
            },"form":{
                model: _setup.saveTemplateModel
            },"visible":_setup.saveDialogVisible,"title":"Сохранение шаблона"},on:{"update:visible":function($event){_setup.saveDialogVisible=$event},"apply":_setup.saveTemplate}},[_c('el-form-item',[_c('el-switch',{attrs:{"active-text":"Создать новый шаблон","inactive-text":"Обновить старый шаблон"},model:{value:(_setup.saveTemplateModel.isNew),callback:function ($$v) {_vm.$set(_setup.saveTemplateModel, "isNew", $$v)},expression:"saveTemplateModel.isNew"}})],1),_vm._v(" "),(!_setup.saveTemplateModel.isNew)?_c('el-form-item',{attrs:{"rules":_vm.requiredRule,"prop":"id"}},[_c('el-select',{staticClass:"w-100",attrs:{"default-first-option":"","filterable":"","placeholder":"Выберите шаблон"},model:{value:(_setup.saveTemplateModel.id),callback:function ($$v) {_vm.$set(_setup.saveTemplateModel, "id", $$v)},expression:"saveTemplateModel.id"}},_vm._l((_setup.items),function(row){return _c('el-option',{key:row.id,attrs:{"label":row.descr,"value":row.id}})}),1)],1):_c('el-form-item',{attrs:{"rules":_vm.requiredRule,"prop":"descr"}},[_c('el-input',{attrs:{"placeholder":"Введите название шаблона"},model:{value:(_setup.saveTemplateModel.descr),callback:function ($$v) {_vm.$set(_setup.saveTemplateModel, "descr", $$v)},expression:"saveTemplateModel.descr"}})],1)],1),_vm._v(" "),_c('el-dialog',{attrs:{"visible":_setup.useDialogVisible,"append-to-body":"","title":"Использовать шаблон","width":"500px"},on:{"update:visible":function($event){_setup.useDialogVisible=$event}}},[_c('el-row',_vm._l((_setup.useTypes),function(item){return _c('el-col',{key:item.descr,staticClass:"mb-3"},[_c('el-button',{staticClass:"w-100",attrs:{"type":item.type,"round":""},on:{"click":function($event){return _setup.useTemplate(item.keys)}}},[_vm._v("\n                    "+_vm._s(item.descr)+"\n                ")])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }