import AbstractEntity from "~/ts/library/AbstractEntity";
import EventType from "~/cabinet/ts/data/events/EventType";
import PlatformEvent from "~/cabinet/ts/data/events/PlatformEvent";

export default class EventPlatformAuth extends AbstractEntity {
    public id: string;
    public descr: string;
    public types: EventType[];
    public events: PlatformEvent[];

}