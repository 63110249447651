import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import IHrBlockValues, {IHrBlockStyles} from "~/cabinet/vue/interface/htmlConstructor/blocks/hr/IHrBlockValues";
import HrDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/hr/HrDisplay.vue";
import {Component} from "vue";


export default class HrBlockType extends AbstractBlockType<IHrBlockValues, any> {

    get displayComponent(): Component {
        return HrDisplay;
    }

    get formComponent(): Component {
        return null;
    }


    hasLeftRightPadding(value: IHrBlockValues, styles: IHrBlockStyles): boolean {
        return !styles.bodyHrNoPadding;
    }


}
