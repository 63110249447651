import Dictionary from "~/ts/library/Dictionary";
import EventManager from "~/ts/library/EventManager";

const DelayEventManager = new EventManager();

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default class Delay {
    private static getEventName(name: string) {
        return "delay_" + name;
    }

    public static override(name: string) {
        DelayEventManager.emit(this.getEventName(name));
    }

    private static throttleStartTime: Dictionary<number> = {};

    public static async throttle(delay: number, name: string) {
        let now = Date.now();
        if (!this.throttleStartTime[name]) {
            this.throttleStartTime[name] = now;
        } else {
            delay -= now - this.throttleStartTime[name];
        }
        if (delay > 0) {
            await this.make(delay, name, true);
            delete this.throttleStartTime[name];
        }
    }

    public static async make(delay: number, name?: string, allowOverride: boolean = true) {
        if (name != null) {
            let listenerEvent = this.getEventName(name);

            if (allowOverride || !DelayEventManager.hasListeners(listenerEvent)) {
                this.override(name);
                let overrided = false;
                try {
                    await new Promise((resolve, reject) => {
                        DelayEventManager.once(
                            listenerEvent, data => resolve(data), delay, () => reject());
                    });
                    overrided = true;
                } catch (e) {
                }
                if (overrided) {
                    throw new Error("delay " + name + " overrided");
                }
                return;
            } else {
                throw new Error(name + " already delayed");
            }
        }

        await sleep(delay);
    }
}