import {
    FilterModel,
    FiltersModel,
    ICombinedFilterPart,
    IFilter,
    IFilterLazyLoadOptions,
    IFilterOption,
    IFiltersData,
    IOperation,
    ISecondsFilterValue
} from "~/cabinet/vue/interface/filter/Interfaces";
import MyFilterValueComponent from "~/cabinet/vue/interface/filter/MyFilterValueComponent";
import Dictionary from "~/ts/library/Dictionary";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import CombinedFilterValue from "~/cabinet/vue/interface/filter/types/CombinedFilterValue";
import HashString from "~/ts/library/HashString";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {
    FILTER_TYPE_COMBINED,
    FILTER_TYPE_DATE,
    FILTER_TYPE_DATETIME,
    FILTER_TYPE_LOCATION,
    FILTER_TYPE_SECONDS,
    FILTER_TYPE_TIME_RANGE
} from "~/cabinet/vue/interface/filter/MyFilterTypes";
import DateHelper from "~/ts/library/Date";
import MyFilterOrganizationLocationHelper
    from "~/cabinet/vue/interface/filter/types/Location/MyFilterOrganizationLocationHelper";
import {__, __sprintf} from "~/ts/library/Translate";
import {ref, Ref} from "vue";

type LazyLoadOptionsResult = {
    options: IFilterOption[],
    placeholder: string
};

let cache: Dictionary<Dictionary<LazyLoadOptionsResult | Promise<LazyLoadOptionsResult>>> = {};


let valueFormatters: Dictionary<(filter: IFilter, value: any) => Promise<string>> = {
    [FILTER_TYPE_DATE]: async (filter: IFilter, value: any) => {
        return value ? (new DateHelper(value)).dateObject.toLocaleDateString() : "";
    },
    [FILTER_TYPE_DATETIME]: async (filter: IFilter, value: any) => {
        if (value) {
            let date = (new DateHelper(value)).dateObject;
            return date.toLocaleDateString() + " " + date.toLocaleTimeString();
        }
        return "";
    },
    [FILTER_TYPE_LOCATION]: async (filter: IFilter, value: any) => {
        let str = "";
        if (value) {
            let result = await MyFilterOrganizationLocationHelper.fetchByValue(value);
            str = result.descr;
        }
        return str;
    },
    [FILTER_TYPE_SECONDS]: async (filter: IFilter, value: ISecondsFilterValue) => {
        return DateHelper.secondsFormat(value.seconds * value.multiply);
    },
    [FILTER_TYPE_TIME_RANGE]: async (filter: IFilter, value: string[]) => {
        return value && value.length == 2 ? `c ${value[0]} по ${value[1]}` : null;
    },
    [FILTER_TYPE_COMBINED]: async (filter: IFilter, value: CombinedFilterValue) => {
        let result: string[] = [];
        let childFilters = filter.combined.filters;
        for (let part of childFilters) {
            if (value && value[part.id] != null) {
                if (filter.combined.hideParentValueInPreview) {
                    let childHasValue = false;
                    for (let childFilter of childFilters) {
                        if (childFilter.parentFilterId == part.id && value[childFilter.id] != null) {
                            childHasValue = true;
                            break;
                        }
                    }
                    if (childHasValue) {
                        continue;
                    }
                }
                result.push(...await MyFilterHelper.prepareValue(part, [value[part.id]], value));
            }
        }
        return result.join(filter.combined.valuesPreviewGlue);
    }
};


export default class MyFilterHelper {
    private static operations: Ref<Dictionary<IOperation>> = ref(null);

    public static setOperations(operations: Dictionary<IOperation>) {
        this.operations.value = operations;
    }

    public static getOperations(filtersData?: IFiltersData) {
        return filtersData?.operations ? filtersData.operations : this.operations.value;
    }

    public static hasVisibleFilters(filter: IFilter, operation: IOperation) {
        return filter.type != "hidden" && (operation.filterInputs == null || operation.filterInputs.length > 0);
    }

    public static isSingleMultipleComponentSituation(filter: IFilter, operation: IOperation) {
        let filters = MyFilterHelper.getFilterInputs(filter, operation);
        let result = false;
        if (filters.length == 1) {
            if (filters[0].multiple) {
                let valueComponent = new MyFilterValueComponent(filters[0], operation, filter);
                if (valueComponent.isMultipleRealizedByComponent) {
                    result = true;
                }
            }
        }
        return result;
    }

    public static getFilterInputs(filter: IFilter, operation: IOperation): IFilter[] {
        let result = operation.filterInputs == null ? [filter] : operation.filterInputs;

        return result.map((item: IFilter | null) => {
            let valueComponent = new MyFilterValueComponent(item, operation, filter);
            return valueComponent.filter;
        });
    }

    public static async getFilterOptions(filter: IFilter, combinedFilterValue: CombinedFilterValue = null): Promise<LazyLoadOptionsResult> {
        let result: LazyLoadOptionsResult = {
            options: filter.sortedOptions,
            placeholder: filter.placeholder
        };
        if (filter.optionsLazyLoad) {
            if (combinedFilterValue) {
                //оптимизон для того, чтобы не перезагружать постоянно списки
                //подразумеваем, что список у фильтра зависит только от родителя
                let parentId = (filter as ICombinedFilterPart).parentFilterId;
                if (parentId) {
                    combinedFilterValue = combinedFilterValue[parentId] ? {[parentId]: combinedFilterValue[parentId]} : null;
                }
            }
            let cacheKey = MyFilterHelper.getLazyLoadCacheKey(filter.optionsLazyLoad, combinedFilterValue);
            if (!cache[filter.id]) {
                cache[filter.id] = {};
            }
            if (cache[filter.id][cacheKey] != null) {
                result = await cache[filter.id][cacheKey];
            } else {
                cache[filter.id][cacheKey] = this.lazyLoadFilterOptions(filter, combinedFilterValue);
                result = await cache[filter.id][cacheKey];
                cache[filter.id][cacheKey] = result;
            }
        }
        return result;
    }

    public static async getOptionByValue(filter: IFilter, value: any, combinedFilterValue: CombinedFilterValue = null) {
        let result = await this.getFilterOptions(filter, combinedFilterValue);
        let option = result.options.find(item => item.value == value);
        if (!option && cache[filter.id]) {
            for (let key in cache[filter.id]) {
                if (cache[filter.id].hasOwnProperty(key)) {
                    let result = await cache[filter.id][key];
                    option = result.options.find(item => item.value == value);
                    if (option) {
                        break;
                    }
                }
            }
        }
        return option;
    }

    private static getLazyLoadCacheKey(optionsLazyLoad: IFilterLazyLoadOptions, combinedFilterValue: CombinedFilterValue = null) {
        return optionsLazyLoad.url + optionsLazyLoad.params + HashString.make(JSON.stringify(combinedFilterValue));
    }

    private static async lazyLoadFilterOptions(filter: IFilter, combinedFilterValue: CombinedFilterValue = null): Promise<LazyLoadOptionsResult> {
        let result = await (new LcabApiRequest({
            url: filter.optionsLazyLoad.url,
            p: {
                params: filter.optionsLazyLoad.params,
                combinedFilterValue: combinedFilterValue
            }
        })).send();

        return result.isSuccess ? {
            options: result.getData("options"),
            placeholder: result.getData("placeholder")
        } : {
            options: [],
            placeholder: ""
        };

    }

    public static isFilterWithOptions(filter: IFilter): boolean {
        return (filter.sortedOptions && filter.sortedOptions.length > 0) || filter.optionsLazyLoad != null;
    }

    private static addDefaultValue(filtersData: IFiltersData, result: FilterModel, filterId: string) {
        let filter = filtersData.filters[filterId];
        let defaultValues = filter.defaultValues;
        if (defaultValues || filtersData.isStatic) {
            if (!defaultValues) {
                let operationId = filter.allowedOperations[0];
                let operation = this.getOperations(filtersData)[operationId];
                let values = this.getDefaultValues(filter, operation);
                defaultValues = {
                    [operationId]: values
                }
            }
            result[filter.id] = defaultValues;
        }
    }


    private static isOperationHasInputs(operation: IOperation): boolean {
        return !!(operation.filterInputs == null || operation.filterInputs.length);
    }


    private static getDefaultValues(filter: IFilter, operation: IOperation) {
        if (filter.defaultValues && filter.defaultValues[operation.id]) {
            return ObjectHelper.jsonClone(filter.defaultValues[operation.id]);
        }
        let values = [];
        if (!this.isOperationHasInputs(operation)) {
            values.push([true]);
        }
        return values;
    }

    public static removeEmptyValues(value: any) {
        let result = value;
        if (value) {
            result = Array.isArray(value) ? {} : ObjectHelper.jsonClone(value);
            for (let filterId in result) {
                if (result.hasOwnProperty(filterId)) {

                    let hasValues = false;
                    for (let operationId in result[filterId]) {
                        if (result[filterId].hasOwnProperty(operationId)) {
                            if (result[filterId][operationId] && result[filterId][operationId].length) {
                                hasValues = true;
                                break;
                            }
                        }
                    }
                    if (!hasValues) {
                        delete result[filterId];
                    }
                }
            }
            if (!ObjectHelper.hasKeys(result)) {
                result = null;
            }
        }
        return result;
    }

    private static normalizeValueInGroup(result: FilterModel, filtersData: IFiltersData) {
        for (let filterId in result) {
            if (result.hasOwnProperty(filterId)) {
                let filter = filtersData.filters[filterId];
                if (filter) {
                    let hasValues = false;
                    for (let operationId in result[filterId]) {
                        if (result[filterId].hasOwnProperty(operationId)) {
                            if (result[filterId][operationId] && result[filterId][operationId].length) {
                                hasValues = true;
                                break;
                            }
                        }
                    }
                    if (!hasValues) {
                        this.addDefaultValue(filtersData, result, filterId);
                    }
                } else {
                    delete result[filterId];
                }
            }
        }
        //let apply = false;
        for (let filterId in filtersData.filters) {
            if (filtersData.filters.hasOwnProperty(filterId)) {
                let filter = filtersData.filters[filterId];
                if (!result[filter.id]) {
                    this.addDefaultValue(filtersData, result, filterId);
                    //apply = true;
                }
            }
        }
        return result;
    }

    public static normalizeValue(value: FiltersModel, filtersData: IFiltersData, byReference: boolean = false): FiltersModel {
        let result = Array.isArray(value) && !value.length ? {} : (byReference ? value : ObjectHelper.jsonClone(value));
        if (Array.isArray(result)) {
            for (let orGroup of result) {
                this.normalizeValueInGroup(orGroup, filtersData);
            }
            for (let index = result.length - 1; index >= 0; index--) {
                if (!ObjectHelper.hasKeys(result[index])) {
                    result.splice(index, 1);
                }
            }
            if (result.length == 1 || (result.length && !filtersData.isOrAvailable)) {
                result = result[0];
            } else if (!result.length) {
                result = {};
            }
        } else {
            this.normalizeValueInGroup(result, filtersData);
        }
        return result;
    }


    public static async prepareValue(filter: IFilter, valueList: any[], combinedFilterValue: CombinedFilterValue = null) {
        let result: string[] = [];
        let promises: Promise<string>[] = [];

        let convertArrayToString = false;

        for (let _value of valueList) {
            if (Array.isArray(_value)) {
                //это ломает отображние timeRange фильтра =\
                //ИЛИ НЕТ? Вернул ибо ломалось отображение в операции "день недели"
                if (filter.type != FILTER_TYPE_TIME_RANGE) {
                    convertArrayToString = true;
                }
            }
            let valueArray = convertArrayToString ? _value : [_value];
            for (let value of valueArray) {
                if (MyFilterHelper.isFilterWithOptions(filter)) {
                    let option: IFilterOption | null = await MyFilterHelper.getOptionByValue(filter, value, combinedFilterValue);

                    if (option) {
                        value = typeof option.previewDescr == "string" ? option.previewDescr : option.descr;
                    } else {
                        value = __("Удалено") + " - " + value;
                    }
                    result.push(value);
                } else if (valueFormatters[filter.type]) {
                    let promise = valueFormatters[filter.type](filter, value);
                    promises.push(promise);
                } else {
                    result.push(value);
                }
            }
        }
        if (promises.length) {
            result = await Promise.all(promises);
        }
        if (filter.valueTemplate != null && filter.valueTemplate != "%s") {
            for (let i = 0; i < result.length; i++) {
                result[i] = __sprintf(filter.valueTemplate, result[i]);
            }
        }

        if (convertArrayToString && result.length > 1) {

            result = [result.join(", ")];
        }

        return result;

    }

    public static isEmptyModel(value: FiltersModel): boolean {
        let result = true;
        if (Array.isArray(value)) {
            for (let orGroup of value) {
                result = this.isEmptyModel(orGroup);
                if (!result) {
                    break;
                }
            }
        } else {
            for (let key in value) {
                if (result && value.hasOwnProperty(key)) {
                    for (let operation in value[key]) {
                        if (value[key].hasOwnProperty(operation)) {
                            if (ObjectHelper.hasKeys(value[key][operation])) {
                                result = false;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return result;

    }

}