import {InjectionKey} from "vue";
import IHtmlConstructorContent from "~/cabinet/vue/interface/htmlConstructor/IHtmlConstructorContent";
import IStructureInjectInterface
    from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureInjectInterface";
import IHtmlConstructorEditor from "~/cabinet/vue/interface/htmlConstructor/IHtmlConstructorEditor";

export const HTML_CONSTRUCTOR_DRAG_GROUP = "htmlConstructor";


export type HtmlBlockAlign = "left" | "right" | "center";

export const HTML_BLOCK_ALIGN_LEFT = "left";
export const HTML_BLOCK_ALIGN_RIGHT = "right";
export const HTML_BLOCK_ALIGN_CENTER = "center";

export const HtmlConstructorContentInject = Symbol() as InjectionKey<IHtmlConstructorContent>;
export const ParentStructureInject = Symbol() as InjectionKey<IStructureInjectInterface>;
export const HtmlConstructorEditorInject = Symbol() as InjectionKey<IHtmlConstructorEditor>;
export const ImagesDontResizableInject = Symbol() as InjectionKey<boolean>;