import {ElMessageBoxOptions} from "element-ui/types/message-box";
import MessageBox from "element-ui/lib/message-box.js";
import {__} from "~/ts/library/Translate";

export default function ElConfirm(message: string, options?: ElMessageBoxOptions) {
    if (!options) {
        options = {};
    }
    options = {
        ...{
            title: __("Требуется подтверждение"),
            cancelButtonText: __("Отмена")
        },
        ...options
    };
    return MessageBox.confirm(message, options);
}