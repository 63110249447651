import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import Dictionary from "~/ts/library/Dictionary";
import ActionOutput from "~/cabinet/ts/data/events/ActionOutput";
import ActionDescription from "~/cabinet/ts/data/events/ActionDescription";
import Random from "~/ts/library/Random";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import CascadeSchemeRelation from "~/cabinet/ts/data/sender/cascade/CascadeSchemeRelation";

export interface ActionBlockParamModelInterface {
    formValues?: Dictionary<any>
    [key: string]: any
}

export default class ActionBlock extends AbstractEntity {
    id: string = null;
    enabled: boolean = true;
    actionDescriptionId: string = null;
    outputTime: number = null;
    isFavorite: boolean = false;
    position: {
        x: number,
        y: number,
        repositionOnMount?: boolean
    } = null;
    param: ActionBlockParamModelInterface = null;
    info?: string = null;
    outputs?: ActionOutput[] = [];
    shortenInputRelations: boolean = false;
    color?: string;
    boundingRect?: {
        top: number;
        left: number;
        width: number;
        height: number;
    } = null;
    hoveredByNewRelation: boolean = false;
    private inputPositionCache: {
        scale: number,
        x: number,
        y: number,
        value: any
    };

    protected afterInit() {
        super.afterInit();
        if (!this.param) {
            this.param = {};
        }
    }

    public setId() {
        this.id = Random.uid(32).toLowerCase();
    }

    protected getFields(): AbstractEntityFields {
        return {
            outputs: ActionOutput
        };
    }

    public getInputPositionCache(scale: number) {
        let cache = this.inputPositionCache;
        if (cache && cache.scale == scale && cache.x == this.position.x && cache.y == this.position.y) {
            return cache.value;
        }
        return null;
    }

    public setInputPositionCache(scale: number, value: any) {
        this.inputPositionCache = {
            scale,
            x: this.position.x,
            y: this.position.y,
            value
        };
        return this;
    }

    public getContentVariables(commonContentVariables: ContentVariableInterface[], relationsByBlock?: CascadeSchemeRelation[], blockTypes?: ActionDescription<any>[]) {
        let result: ContentVariableInterface[] = [];

        if (relationsByBlock && blockTypes) {
            let connectedOutputs: ActionOutput[] = [];

            let relations = relationsByBlock.filter(relation => relation.childBlockId == this.id);
            for (let relation of relations) {
                let parentBlock = relation.parentBlock;
                if (parentBlock) {
                    let parentBlockType = blockTypes.find(blockType => blockType.id == parentBlock.actionDescriptionId);
                    if (parentBlockType) {
                        let outputs = parentBlockType.getOutputs(parentBlock);
                        let output = outputs.find(output => output.id === relation.outputId);
                        if (output) {
                            if (connectedOutputs.indexOf(output) == -1) {
                                connectedOutputs.push(output);
                            }
                        }
                    }
                }
            }

            for (let output of connectedOutputs) {
                if (output.filters) {
                    let filters = output.filters.filters;
                    for (let filterId in filters) {
                        if (filters.hasOwnProperty(filterId)) {
                            if (!result.find(item => item.id == filterId)) {
                                result.push({
                                    id: filterId,
                                    descr: filters[filterId].descr,
                                    groupDescr: "Данные из предыдущего блока"
                                });
                            }
                        }
                    }
                }
            }


        }


        result.push(...commonContentVariables);

        return result;

    }
}
