import Dictionary from "~/ts/library/Dictionary";
import Vue from "vue";

let sprintf = require("sprintf-js").sprintf;
let strings: Dictionary<string> = Vue.observable({});
export const __ = function (str: string, ...variables: any[]): string {
    return __sprintf(str, ...variables);
};

export const __sprintf = function (str: string, ...variables: any[]): string {
    if (typeof str == "string") {
        if (!strings.hasOwnProperty(str)) {
            Vue.set(strings, str, null);
        }
        if (strings[str] != null) {
            str = strings[str];
            arguments[0] = str;
        }
        if (variables[0] != null && Array.isArray(variables[0])) {
            variables = variables[0];
            variables.unshift(str);
        }
        if (variables.length) {
            return sprintf.apply(null, arguments);
        } else {
            return arguments[0];
        }
    } else {
        return str;
    }
};

export default class Translate {
    public static setStrings(newStrings: Dictionary<string>) {
        for (let key in strings) {
            if (strings.hasOwnProperty(key)) {
                if (!newStrings.hasOwnProperty(key)) {
                    Vue.set(strings, key, null);
                }
            }
        }
        for (let key in newStrings) {
            if (newStrings.hasOwnProperty(key)) {
                Vue.set(strings, key, newStrings[key]);
            }
        }
    }
}