import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import IStructureBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import {HTML_BLOCK_TYPE_STRUCTURE} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";

export default class HtmlConstructorHelper {
    public static wrapBlocksToStructureBlock(blocks: IHtmlConstructorBlock<any>[]) {
        let block: IHtmlConstructorBlock<IStructureBlockValues> = {
            type: HTML_BLOCK_TYPE_STRUCTURE,
            values: {
                selfStyle: false,
                style: null,//this.editDialog.model.style,
                columns: "100",
                blockLists: [
                    blocks
                ]
            }
        };
        return block;
    }
}