import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ITextUnderImageBlockValues
    from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/ITextUnderImageBlockValues";
import TextUnderImageDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/TextUnderImageDisplay.vue";
import TextUnderImageForm from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/TextUnderImageForm.vue";
import {Component} from "vue";


export default class TextUnderImageBlockType extends AbstractBlockType<ITextUnderImageBlockValues, any> {


    get displayComponent(): Component {
        return TextUnderImageDisplay;
    }

    get formComponent(): Component {
        return TextUnderImageForm;
    }

    hasLeftRightPadding(value: ITextUnderImageBlockValues): boolean {
        return false;
    }


}
