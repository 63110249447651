import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {ExtractPropTypes, inject, PropType} from "vue";
import {
    HtmlConstructorContentInject,
    HtmlConstructorEditorInject
} from "~/cabinet/vue/interface/htmlConstructor/Constants";

export function useBlockFormProps<Value, BlockType extends AbstractBlockType<any, any> = AbstractBlockType<any, any>>() {
    return {
        blockType: {
            type: Object as PropType<BlockType>
        },
        value: {
            type: Object as PropType<Value>
        }
    }
}

export function useBlockForm(props: Readonly<ExtractPropTypes<ReturnType<typeof useBlockFormProps>>>) {
    let htmlConstructorEditor = inject(HtmlConstructorEditorInject);
    let htmlConstructorContent = inject(HtmlConstructorContentInject);
    return {
        htmlConstructorContent,
        htmlConstructorEditor
    };
}