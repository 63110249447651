import Dictionary from "~/ts/library/Dictionary";
import Vue, {set} from "vue";
import ObjectHelper from "~/ts/library/ObjectHelper";

export type AbstractEntityBuilder<T extends AbstractEntity | any> = {
    new: <T>(this: Function & { prototype: T }, data?: any) => T,
    getDiscriminator: <T>(this: Function & { prototype: T }) => Discriminator,
    apply: any,
    bind: any,
    prototype: T,
    call: any,
    length: any,
    arguments: any,
    caller: any,
    name: any,
    [Symbol.hasInstance]: any
};

export type EntityBuilder = AbstractEntityBuilder<any>;

export abstract class AbstractEntityField<T extends AbstractEntity> {
    private builder: EntityBuilder;

    constructor(builder: AbstractEntityBuilder<T>) {
        this.builder = builder;
    }

    protected build(data?: Dictionary<any>): T {
        return (this.builder as AbstractEntityBuilder<T>).new(data) as T;
    }

    public abstract new(): any;
}

export class EntityField<T extends AbstractEntity> extends AbstractEntityField<T> {
    public new(data?: Dictionary<any>) {
        return this.build(data);
    }
}

export class DictionaryField<T extends AbstractEntity> extends AbstractEntityField<T> {
    public new(data?: Dictionary<any>): Dictionary<T> {
        let result: Dictionary<any> = {};
        if (data) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    let value = data[key];
                    result[key] = this.build(value);
                }
            }
        }
        return result;
    }
}

export class NullableField<T extends AbstractEntity> extends AbstractEntityField<T> {
    public new(data?: any): T | null {
        let result: T | null = null;
        if (data) {
            result = this.build(data);
        }
        return result;
    }
}


export type AbstractEntityFields = Dictionary<AbstractEntityField<any> | AbstractEntityBuilder<any>>;

export default abstract class AbstractEntity {
    protected constructor() {

    }

    protected isFreezeObject(): boolean {
        return false;
    }

    protected getDiscriminator(): Discriminator {
        return null;
    }

    public clone(disableReactivity: boolean = false): this {
        let result = new (this as any).constructor();
        (result as AbstractEntity).init(ObjectHelper.jsonClone(this), disableReactivity)
        return result;
    }

    protected afterInit() {

    }

    private static makeNestedField(field: EntityField<any>, value: any) {
        if (Array.isArray(value)) {
            return value.map(item => field.new(item));
        } else {
            return field.new(value);
        }
    }

    protected getFields(): AbstractEntityFields {
        return null;
    }

    private init(data?: Dictionary<any>, disableReactivity: boolean = false) {
        let fields = this.getFields();
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                let value = data[key];
                if (fields) {
                    let field = fields[key];
                    if (field) {
                        if (!(field instanceof AbstractEntityField)) {
                            field = new EntityField(field);
                        }
                        value = AbstractEntity.makeNestedField(field, value);
                    }
                }
                (this as any)[key] = value;
            }
        }
        if (this.isFreezeObject()) {
            Object.freeze(this);
        } else {
            if (!disableReactivity) {
                Vue.observable(this);
            }
        }
        this.afterInit();
        return this;
    }

    static new<T extends AbstractEntity>(this: Function & { prototype: T }, data?: any): T {
        let builder = (this as any as typeof AbstractEntity).getBuilder(data);
        let result = new (builder as any)();
        (result as AbstractEntity).init(data);
        return result;
    }

    private static getBuilder<T extends AbstractEntity>(data: Dictionary<any> | null): AbstractEntityBuilder<T> {
        let result: AbstractEntityBuilder<T> = this as any;
        if (data) {
            let discriminator = this.getDiscriminator();
            if (discriminator) {
                let field = discriminator.fieldName;
                let newBuilder = discriminator.classList[data[field]];
                if (newBuilder) {
                    result = newBuilder;
                }
            }
        }
        return result;
    }

    private static discriminator: Discriminator = undefined;

    static getDiscriminator<T extends AbstractEntity>(): Discriminator {
        if (typeof this.discriminator == "undefined") {
            this.discriminator = (new (this as any)()).getDiscriminator();
        }
        return this.discriminator;
    }

    public static dictionary() {
        return new DictionaryField(this);
    }

    public copyFrom(source: any) {
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                if (this.hasOwnProperty(key)) {
                    (this as any)[key] = source[key];
                } else {
                    set(this, key, source[key]);
                }
            }
        }
    }
}

export class Discriminator {
    public readonly fieldName: string;
    public readonly classList: Dictionary<AbstractEntityBuilder<any>>;

    constructor(fieldName: string, classList: Dictionary<AbstractEntityBuilder<any>>) {
        this.fieldName = fieldName;
        this.classList = classList;
    }
}

export class EmptyEntity extends AbstractEntity {

}