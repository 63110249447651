import AbstractAnalyticCounter from "~/cabinet/ts/service/analytics/AbstractAnalyticCounter";
import Dictionary from "~/ts/library/Dictionary";

export default class MailRuPixel extends AbstractAnalyticCounter<any[]> {
    public getCounterObject(): any[] | null {
        return (window as any)._tmr;
    }

    protected async reachGoalMake(counter: any[], eventId: string, sum: number | undefined, currencyId: string | undefined): Promise<void> {
        counter.push({type: "reachGoal", goal: eventId, value: sum});
    }

    protected async setUserParamsMake(counter: any[], userId: string, otherParams: Dictionary<any>): Promise<void> {
        counter.push({type: "setUserID", userid: userId});
        counter.push({type: "setUserParams", params: otherParams});
    }

}