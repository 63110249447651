import Dictionary from "~/ts/library/Dictionary";
import BadgeManager from "~/cabinet/vue/interface/menu/BadgeManager";

export default class MenuBadge {
    private _id: string;
    private _params?: Dictionary<any> | (() => Dictionary<any>);
    private staticValue: number | string;
    private _type: string;
    private _hidden: boolean = false;
    private _icon: string;
    private _iconColor: string;
    private _isUsedInParent = true;

    constructor(id: string, params?: Dictionary<any> | (() => Dictionary<any>), staticValue?: string | number) {
        this._id = id;
        this._params = params;
        this.staticValue = staticValue;
    }

    get id() {
        return this._id;
    }

    get params() {
        return typeof this._params == "function" ? this._params() : this._params;
    }

    public get value(): number | string | null {
        return this.staticValue ? this.staticValue : BadgeManager.getValue(this);
    }

    setType(type: "info" | "success" | "primary" | "warning") {
        this._type = type;
        return this;
    }

    setIcon(icon: string, color: string) {
        this._icon = icon;
        this._iconColor = color;
        return this;
    }

    get icon() {
        return this._icon;
    }

    get iconColor() {
        return this._iconColor;
    }


    get type(): string | null {
        return this._type;
    }

    get isHidden(): boolean {
        return this._hidden;
    }

    setIsHidden(hidden: boolean) {
        this._hidden = hidden;
        return this;
    }

    get isUsedInParent(): boolean {
        return this._isUsedInParent;
    }

    setIsUsedInParent(value: boolean) {
        this._isUsedInParent = value;
        return this;
    }
}