<template>
    <div class="slider" ref="wrapper">
        <div class="slider__track" ref="track" v-on="trackSlide ? { mousedown: select, touchstart: select } : { }">
            <div class="slider__fill" ref="fill"></div>
            <div class="slider__handle" v-for="handle in handles" @mousedown="select" @touchstart="select"
                 :style="`transform: translate(${handle.position}px, 0); background-color: ${handle.color};`">
                <div class="slider__label" v-if="label">{{ handle.value }}</div>
            </div>
        </div>
        <input class="slider__input" ref="input" :type="colorCode ? 'text' : 'number'" v-show="editable"
               @change="updateValue($event.target.value)"/>
    </div>
</template>

<script>
import {mixColors} from 'color-fns';
import {debounce, getClosestValue, getEventCords} from '../utils';

export default {
    name: 'VerteSlider',
    props: {
        gradient: Array,
        classes: Array,
        colorCode: {type: Boolean, default: false},
        editable: {type: Boolean, default: true},
        reverse: {type: Boolean, default: false},
        label: {type: Boolean, default: false},
        trackSlide: {type: Boolean, default: true},
        min: {type: Number, default: 0},
        max: {type: Number, default: 255},
        step: {type: Number, default: 1},
        value: {type: Number, default: 0},
        handlesValue: {type: Array, default: () => [0]}
    },
    data: () => ({
        fill: {
            translate: 0,
            scale: 0
        },
        multiple: false,
        currentValue: 0,
        handles: [],
        values: []
    }),
    watch: {
        gradient(val) {
            this.initGradient(val);
            this.reloadHandlesColor();
        },
        values() {
            this.multiple = this.values.length > 1;
            this.fill = this.multiple ? false : this.fill || {};
        },
        value(val, oldVal) {
            if (val === oldVal || val === this.currentValue) return;

            this.updateValue(this.value, true);
        }
    },
    methods: {
        init() {
            this.$emitInputEvent = debounce(() => {
                this.$emit('input', this.currentValue);
            });
            this.multiple = this.values.length > 1;
            this.values = this.handlesValue;
            this.handles = this.handlesValue.map((value, index) => {
                return {value, position: 0, color: '#fff'};
            });
            if (this.values.length === 1) {
                this.values[0] = Number(this.value);
            }
            this.values.sort();

            this.initElements();
            if (this.gradient) {
                this.initGradient(this.gradient);
            }
            this.initEvents();
            this.values.forEach((handle, index) => {
                this.activeHandle = index;
                this.updateValue(handle, true);
            });
        },
        initElements() {
            this.wrapper = this.$refs.wrapper;
            this.track = this.$refs.track;
            this.fill = this.$refs.fill;

            this.wrapper.classList.toggle('slider--editable', this.editable);
            this.wrapper.classList.toggle('slider--reverse', this.reverse);
            if (this.classes) {
                this.wrapper.classList.add(...this.classes);
            }
        },
        initGradient(gradient) {
            if (gradient.length > 1) {
                this.fill.style.backgroundImage = `linear-gradient(90deg, ${gradient})`;
                return;
            }
            this.fill.style.backgroundImage = '';
            this.fill.style.backgroundColor = gradient[0];
            this.handles.forEach(handle => {
                handle.style.color = gradient[0];
            });
        },
        handleResize() {
            this.updateWidth();
            this.updateValue(this.currentValue, true);
        },
        initEvents() {
            window.addEventListener('resize', this.handleResize);
        },
        /**
         * fire select events
         */
        select(event) {
            event.preventDefault();
            event.stopPropagation();
            // check if  left mouse is clicked
            if (event.buttons === 2) return;

            this.updateWidth();
            this.track.classList.add('slider--dragging');
            this.ticking = false;

            const stepValue = this.getStepValue(event);

            if (this.multiple) {
                let closest = getClosestValue(this.values, stepValue);
                this.activeHandle = this.values.indexOf(closest);
            }
            this.updateValue(stepValue);

            this.tempDrag = this.dragging.bind(this);
            this.tempRelease = this.release.bind(this);
            document.addEventListener('mousemove', this.tempDrag);
            document.addEventListener('touchmove', this.tempDrag);
            document.addEventListener('touchend', this.tempRelease);
            document.addEventListener('mouseup', this.tempRelease);
        },
        /**
         * dragging motion
         */
        dragging(event) {
            const stepValue = this.getStepValue(event);
            if (!this.ticking) {
                window.requestAnimationFrame(() => {
                    this.updateValue(stepValue);
                    this.ticking = false;
                });

                this.ticking = true;
            }
        },
        /**
         * release handler
         */
        release() {
            this.track.classList.remove('slider--dragging');
            document.removeEventListener('mousemove', this.tempDrag);
            document.removeEventListener('touchmove', this.tempDrag);
            document.removeEventListener('mouseup', this.tempRelease);
            document.removeEventListener('touchend', this.tempRelease);
        },
        getStepValue(event) {
            const {x} = getEventCords(event);

            const mouseValue = (x - this.currentX);
            const stepCount = parseInt((mouseValue / this.stepWidth) + 0.5, 10);
            const stepValue = (stepCount * this.step) + this.min;
            if (!this.decimalsCount) {
                return stepValue;
            }
            return Number(stepValue.toFixed(this.decimalsCount));
        },
        updateWidth() {
            const trackRect = this.track.getBoundingClientRect();
            this.currentX = trackRect.left;
            this.width = trackRect.width;
            this.stepWidth = (this.width / (this.max - this.min)) * this.step;
        },
        /**
         * get the filled area percentage
         * @param  {Object} slider
         * @param  {Number} value
         * @return {Number}
         */
        getPositionPercentage(value) {
            return ((value - this.min) / (this.max - this.min)).toFixed(2);
        },
        normalizeValue(value) {
            if (isNaN(Number(value))) {
                return this.value;
            }
            if (this.multiple) {
                const prevValue = this.values[this.activeHandle - 1] || this.min;
                const nextValue = this.values[this.activeHandle + 1] || this.max;
                value = Math.min(Math.max(Number(value), prevValue), nextValue);
            }
            return Math.min(Math.max(Number(value), this.min), this.max);
        },
        addHandle(value) {
            const closest = getClosestValue(this.values, value);
            const closestIndex = this.values.indexOf(closest);
            const closestValue = this.values[closestIndex];
            const newIndex = closestValue <= value ? closestIndex + 1 : closestIndex;
            this.handles.splice(newIndex, 0, {
                value,
                position: 0,
                color: '#fff'
            });
            this.values.splice(newIndex, 0, value);

            this.activeHandle = newIndex;
            this.currentValue = null;
            this.updateValue(value);
        },
        removeHandle(index) {
            this.handles.splice(index, 1);
            this.values.splice(index, 1);
            this.activeHandle = index === 0 ? index + 1 : index - 1;
        },
        /**
         * get the handle color
         * @param  {Number} positionPercentage
         * @return {Number} handle hex color code
         */
        getHandleColor(positionPercentage) {
            const colorCount = this.gradient.length - 1;
            const region = positionPercentage;
            for (let i = 1; i <= colorCount; i++) {
                // check the current zone
                if (region >= ((i - 1) / colorCount) && region <= (i / colorCount)) {
                    // get the active color percentage
                    const colorPercentage = (region - ((i - 1) / colorCount)) / (1 / colorCount);
                    // return the mixed color based on the zone boundary colors
                    return mixColors(this.gradient[i - 1], this.gradient[i], colorPercentage);
                }
            }
            return 'rgb(0, 0, 0)';
        },
        /**
         * update the slider fill, value and color
         * @param {Number} value
         */

        reloadHandlesColor() {
            this.handles.forEach((handle, index) => {
                const positionPercentage = this.getPositionPercentage(handle.value);
                const color = this.getHandleColor(positionPercentage);
                this.handles[index].color = color.toString();
            });
        },

        updateValue(value, muted = false) {
            // if (Number(value) === this.value) return;

            window.requestAnimationFrame(() => {
                const normalized = this.normalizeValue(value);
                const positionPercentage = this.getPositionPercentage(normalized);

                if (this.fill) {
                    this.fill.translate = positionPercentage * this.width;
                    this.fill.scale = 1 - positionPercentage;
                }

                this.values[this.activeHandle] = normalized;
                this.handles[this.activeHandle].value = normalized;
                this.handles[this.activeHandle].position = positionPercentage * this.width;
                this.currentValue = normalized;
                this.$refs.input.value = this.currentValue;

                if (this.gradient) {
                    const color = this.getHandleColor(positionPercentage);
                    this.handles[this.activeHandle].color = color.toString();
                    if (this.colorCode) {
                        this.currentValue = color;
                    }
                }

                if (muted) return;
                this.$emitInputEvent();
            });
        }
    },
    created() {
        const stepSplited = this.step.toString().split('.')[1];
        this.currentValue = this.value;
        this.decimalsCount = stepSplited ? stepSplited.length : 0;
    },
    mounted() {
        this.init();
        this.$nextTick(() => {
            this.updateWidth();
            this.updateValue(undefined, true);
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style lang="scss">
@import '../sass/variables';

.slider {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: $margin;
    font-size: 20px;

    &:hover, &--dragging {
        .slider-label {
            visibility: visible;
            opacity: 1;
        }
    }
}

.slider__input {
    margin-bottom: 0;
    padding: 0.3em;
    margin-left: 0.2em;
    max-width: 70px;
    width: 20%;
    border: 0;
    text-align: center;
    font-size: $fontTiny;
    -webkit-appearance: none;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:focus {
        outline: none;
        border-color: $blue;
    }
}

.slider__track {
    position: relative;
    flex: 1;
    margin: 3px;
    width: auto;
    height: 8px;
    background: $white;
    will-change: transfom;
    background-image: $checkerboard;
    background-size: 6px 6px;
    background-position: 0 0, 3px -3px, 0 3px, -3px 0px;
    border-radius: 10px;
}

.slider__handle {
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    color: $black;
    margin: -2px 0 0 -8px;
    width: 12px;
    height: 12px;
    border: 2px solid $white;
    background-color: currentColor;
    border-radius: 50%;
    box-shadow: 0 1px 4px -2px rgba($black, 10%);
}

.slider__label {
    position: absolute;
    top: -3em;
    left: 0.4em;
    z-index: 999;
    visibility: hidden;
    padding: 6px;
    min-width: 3em;
    border-radius: $borderRadius;
    background-color: $black;
    color: $white;
    text-align: center;
    font-size: $fontTiny;
    line-height: 1em;
    opacity: 0;
    transform: translate(-50%, 0);
    white-space: nowrap;

    &:before {
        position: absolute;
        bottom: -0.6em;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-width: 0.6em 0.6em 0 0.6em;
        border-style: solid;
        border-color: $black transparent transparent transparent;
        content: '';
        transform: translate3d(-50%, 0, 0);
    }
}

.slider__fill {
    width: 100%;
    height: 100%;
    transform-origin: left top;
    border-radius: 10px;
}
</style>
