const CSRF_LOCAL_STORAGE_KEY = "csrf-token";

export default class CsrfToken {
    private static csrfToken?: string;

    public static set(token: string) {
        this.csrfToken = token;
        try {
            localStorage.setItem(CSRF_LOCAL_STORAGE_KEY, token);
        } catch (e) {

        }
    }

    public static get(): string | null {
        let result: string;
        try {
            result = localStorage.getItem(CSRF_LOCAL_STORAGE_KEY);
        } catch (e) {

        }
        if (!result) {
            result = this.csrfToken;
        }
        return result;
    }
}