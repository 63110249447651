import Dictionary from "~/ts/library/Dictionary";
import Delay from "~/ts/library/Delay";

export default abstract class AbstractAnalyticCounter<CounterObject> {
    public async reachGoal(eventId: string, sum?: number, currencyId?: string) {
        while (true) {
            let counter = this.getCounterObject();
            if (counter) {
                this.reachGoalMake(counter, eventId, sum, currencyId);
                break;
            } else {
                await Delay.make(1000);
            }
        }
    }

    public async setUserParams(userId: string, otherParams: Dictionary<any>) {
        while (true) {
            let counter = this.getCounterObject();
            if (counter) {
                this.setUserParamsMake(counter, userId, otherParams);
                break;
            } else {
                await Delay.make(1000);
            }
        }
    }

    abstract getCounterObject(): CounterObject | null;

    protected abstract reachGoalMake(counter: CounterObject, eventId: string, sum?: number, currencyId?: string): Promise<void>;

    protected abstract setUserParamsMake(counter: CounterObject, userId: string, otherParams: Dictionary<any>): Promise<void>;
}