
import Vue from 'vue'
import {prefixCls} from '../constant'

export default Vue.extend({
    data() {
        return {
            prefixCls
        }
    }
})
