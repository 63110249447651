import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ITextFloatImageBlockValues
    from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/ITextFloatImageBlockValues";
import TextFloatImageDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/TextFloatImageDisplay.vue";
import TextFloatImageForm from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/TextFloatImageForm.vue";
import {Component} from "vue";


export default class TextFloatImageBlockType extends AbstractBlockType<ITextFloatImageBlockValues, any> {

    get displayComponent(): Component {
        return TextFloatImageDisplay;
    }

    get formComponent(): Component {
        return TextFloatImageForm;
    }

    get isAllowedInStructure(): boolean {
        return false;
    }
}
