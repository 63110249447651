import AbstractHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/AbstractHtmlConstructorType";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {HTML_BLOCK_TYPE_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import {Component} from "vue";
import NewsHtmlContentDisplay
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/news/NewsHtmlContentDisplay.vue";

export default class NewsHtmlConstructorType extends AbstractHtmlConstructorType {
    get contentDisplayComponent(): Component {
        return NewsHtmlContentDisplay;
    }

    getDefaultBlockLists(): IHtmlConstructorBlock<any>[] {
        return [
            {
                type: HTML_BLOCK_TYPE_TEXT,
                values: {}
            }
        ];
    }

    get isStyleEditableInContentEditor(): boolean {
        return false;
    }
}