function onlyUnique(value: any, index: number, self: any[]) {
    return self ? self.indexOf(value) === index : true;
}

export default class ArrayHelper {
    public static intersect(a: any[], b: any[]): any[] {
        var t;
        if (b.length > a.length) {
            t = b, b = a, a = t; // indexOf to loop over shorter
        }
        return a.filter(function (e) {
            return b.indexOf(e) > -1;
        });
    }

    public static unique<T>(array: T[]): T[] {
        return array.filter(onlyUnique);
    }

    public static keys(array: any[]): number[] {
        return array.map((value, index) => index);
    }
}