import {CascaderOption} from "element-ui/types/cascader-panel";
import md5 from "~/ts/library/md5";

export interface CascadeOptionWithDescrArray extends CascaderOption {
    descrArray?: string[];
    descrArrayMd5?: string;
}

export default class CascaderHelper {
    public static createOptions(items: CascaderOptionInterface[]): CascadeOptionWithDescrArray[] {
        let result: CascadeOptionWithDescrArray[] = [];
        for (let item of items) {
            let currentLevel = result;
            let descr = item.descr;
            for (let i = 0; i < descr.length; i++) {
                let last = descr.length - 1 == i;
                let descrAtLevel = descr[i];
                let descrUntilLevel = descr.slice(0, i + 1);
                let descrArrayMd5 = md5(descrUntilLevel.join("||"));
                if (!last) {
                    let item = currentLevel.find(item => item.label == descrAtLevel && item.children);
                    if (!item) {
                        item = {
                            label: descrAtLevel,
                            children: [],
                            value: descrAtLevel,
                            descrArray: descrUntilLevel,
                            descrArrayMd5
                        };
                        currentLevel.push(item);
                    }
                    currentLevel = item.children;
                } else {
                    currentLevel.push({
                        label: descrAtLevel,
                        value: item.id,
                        descrArray: descrUntilLevel,
                        descrArrayMd5
                    });
                }
            }
        }
        return result;
    }

    public static cascaderValueToId(value: string[]): string {
        return value[value.length - 1];
    }

    public static idToCascaderValue(items: CascaderOptionInterface[], id?: string | null): string[] | null {
        if (typeof id == "string") {
            let item = items.find(item => item.id == id);
            if (item) {
                let result = [...item.descr];
                result[result.length - 1] = id;
                return result;
            }
        }
        return null;
    }

    public static filterMethod(node: CascaderOption, keyword: string): boolean {
        let label = node.label.toString().toLowerCase();
        return label.includes(keyword.toLowerCase());
    }
}

export interface CascaderOptionInterface {
    id: string;
    descr: string[];
}