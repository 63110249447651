import LcabApiResult from "~/cabinet/ts/api/LcabApiResult";
import ISenderCreateResult from "~/cabinet/vue/client/sender/send/create/ISenderCreateResult";
import {getCurrency} from "~/cabinet/ts/service/Currency";
import {computed, ExtractPropTypes, PropType} from "vue";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {
    SenderContentFormModelInterface,
    SenderFormModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";

export function useSenderCreateConfirmationInfo<Value extends SenderContentFormModelInterface, Result extends ISenderCreateResult>(props: Readonly<ExtractPropTypes<ReturnType<typeof useSenderCreateConfirmationInfoProps>>>) {
    let model = useObjectModel<SenderFormModelInterface<Value>>(props);
    let isSuccess = computed<boolean>(() => {
        return props.result && props.result.isSuccess;
    });

    let senderResult = computed<Result>(() => {
        return props.result ? props.result.getData() : null;
    });

    let currency = computed(() => {
        return getCurrency(senderResult.value.price.currencyId).shortDescr;
    });

    let isDone = computed(() => props.result != null);

    let senderResultDescr = computed(() => props.result?.descr);

    return {
        model,
        isSuccess,
        senderResult,
        currency,
        isDone,
        senderResultDescr,
        uslugaId: computed(() => props.uslugaId)
    }
}

export function useSenderCreateConfirmationInfoProps() {
    return {
        value: {
            type: Object as PropType<any>,
            required: false
        },
        uslugaId: {
            type: String,
            required: true
        },
        back: {
            type: Function as PropType<() => void>,
            required: true
        },
        send: {
            type: Function as PropType<() => void>,
            required: true
        },
        result: {
            type: Object as PropType<LcabApiResult<any>>,
            required: false
        }
    }
}