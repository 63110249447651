import {
    ISenderFormSite,
    IUslugaAccess,
    SenderFormParamsInterface,
    SenderSourceInterface
} from "~/cabinet/vue/client/sender/send/Interfaces";
import {Component, computed, ExtractPropTypes, getCurrentInstance, PropType, ref, set} from "vue";

import {
    DateTimeSendValueInterface,
    SenderCouponModelInterface,
    SenderMagicUrlModelInterface,
    SenderMessageTimeoutValueInterface,
    SenderOnlineChatParamsValueInterface,
    SenderReplyOnlineChatModelInterface,
    SmoothSendValueInterface
} from "~/cabinet/vue/client/sender/send/settings/Interfaces";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import ContentVariablesHelper from "~/cabinet/vue/client/sender/send/ContentVariablesHelper";
import {useObjectModelByExtractPropTypes} from "~/ts/vuePlugins/useObjectModel";

export function useSenderContentForm<
    Value extends SenderContentFormModelInterface,
    SenderFormParams extends SenderCreateFormParamsInterface<ContentFormParams>,
    ContentFormParams extends ContentFormParamsInterface = ContentFormParamsInterface
>(
    props: Readonly<ExtractPropTypes<ReturnType<typeof useSenderContentFormProps<Value, SenderFormParams, ContentFormParams>>>>,
    types: { id: string, descr: string, component: Component }[],
    getDefaultValue: () => Value,
    afterModelInitialize?: (model: Value) => void
) {
    let typeContents = ref<Dictionary<any>>({});

    let model = useObjectModelByExtractPropTypes<Value>(props, () => {
        return {
            type: types[0] ? types[0].id : null,
            data: null,
            ...(getDefaultValue() as any)
        };
    }, model => {
        onInput(model.value.type, model.value.data);
        setType(model.value, model.value.type);
        if (afterModelInitialize) {
            afterModelInitialize(model.value);
        }
    });


    let contentVariableGroups = computed(() => {
        return ContentVariablesHelper.contentVariablesToGroups((props.sendFormParams as SenderFormParams).formParams.contentVariables);
    });


    let contentFormParams = computed<ContentFormParams>(() => {
        return (props.sendFormParams as SenderFormParams).contentFormParams;
    });

    let type = computed(() => model.value.type);

    let availableTypes = computed(() => {
        let availableTypes = (props.sendFormParams as SenderFormParams).formParams.availableContentTypes;
        if (Array.isArray(availableTypes) && availableTypes.length) {
            types = types.filter(type => availableTypes.indexOf(type.id) > -1);
        }
        return types;
    });


    function setType(model: Value, type: string) {
        model.data = typeContents.value[type];
        model.type = type;
    }


    let sources = computed(() => contentFormParams.value.sources);

    let selectedSource = computed(() => {
        let sourceId = model.value.source;
        let result = null;
        if (sourceId) {
            result = sources.value.find(source => sourceId == source.id);
        }
        return result;
    });

    function onInput(newType: string, value: any) {
        set(typeContents.value, newType, value);

        if (model && model.value && model.value.type == newType) {
            if (model.value.data != value) {
                model.value.data = value;
            }
        }
    }

    let instance = getCurrentInstance().proxy;

    let formParams = computed(() => (props.sendFormParams as SenderFormParams).formParams);
    return {
        contentVariableGroups,
        contentFormParams,
        type,
        availableTypes,
        instance: computed(() => instance),
        setType: (type: string) => setType(model.value, type),
        sources,
        selectedSource,
        onInput,
        formParams,
        model,
        contactbookDataSourceId: computed(() => props.contactbookDataSourceId),
        sendFormParams: computed(() => props.sendFormParams as SenderFormParams),
        settingsModel: computed(() => props.settingsModel),
        onTemplatesButtonClick: computed(() => props.onTemplatesButtonClick),
        typeContents
    };
}

export type UseSenderContentFormExpose<Value extends SenderContentFormModelInterface = SenderContentFormModelInterface, SenderFormParams extends SenderCreateFormParamsInterface = SenderCreateFormParamsInterface> = ReturnType<typeof useSenderContentForm<Value, SenderFormParams>>;

export function useSenderContentFormProps<
    Value extends SenderContentFormModelInterface,
    SenderFormParams extends SenderCreateFormParamsInterface<ContentFormParams>,
    ContentFormParams extends ContentFormParamsInterface = ContentFormParamsInterface
>() {
    return {
        value: {
            type: Object as PropType<Value>,
            required: false
        },
        sendFormParams: {
            type: Object as PropType<SenderFormParams>,
            required: false
        },
        contactbookDataSourceId: {
            type: String,
            required: false
        },
        onTemplatesButtonClick: {
            type: Function as PropType<() => void>,
            required: false
        },
        uslugaId: {
            type: String,
            required: true
        },
        settingsModel: {
            type: Object as PropType<SenderFormSettingsModelInterface>,
            required: false
        },
        isForYourOrgManagers: {
            type: Boolean,
            required: false
        }
    }
}


export interface SenderFormSettingsModelInterface {
    dateTimeSend: DateTimeSendValueInterface,
    startBot: SenderOnlineChatParamsValueInterface,
    onlineChatParams: SenderOnlineChatParamsValueInterface,
    smooth: SmoothSendValueInterface,
    messageTimeout: SenderMessageTimeoutValueInterface,
    timeRange: {
        enabled: boolean,
        range: [string, string]
    },
    replyToOnlineChat: SenderReplyOnlineChatModelInterface,
    magicUrl: SenderMagicUrlModelInterface,
    coupon: SenderCouponModelInterface,
    orgDetailId: string,
    tags: string[],
    clearForm: boolean,
    destId: number
}

export interface SenderContentFormModelInterface {
    type?: string,
    data: any,
    source?: string
}

export interface SenderFormModelInterface<Content extends SenderContentFormModelInterface> {
    settings: SenderFormSettingsModelInterface,
    recipients: SenderFormReceiversInterface,
    content: Content,
    isUseStandartContent: boolean
}


export interface SenderCreateFormParamsInterface<ContentFormParams extends ContentFormParamsInterface = ContentFormParamsInterface> {
    filters: IFiltersData,
    //groups: ISenderFormGroup[],
    defaultStopListGroupIds: number[],
    isAvailableReplyToChat: boolean;
    isAvailableMagicUrl: boolean;
    isAvailableStartBot: boolean;
    isSourceProvidesSiteId: boolean;
    isAvailableMessageTimeout: boolean;
    onlineChatSites?: ISenderFormSite[];
    repeatModel: SenderFormModelInterface<any>;
    formParams: SenderFormParamsInterface;
    contentFormParams: ContentFormParams;
    uslugaAccess: IUslugaAccess;
    couponCodeContentVariable: string;
    couponPolicyList: {
        id: string, descr: string
    }[];
    dests?: {
        id: number,
        descr: string
    }[],
    contactbookGroups: {
        id: number,
        descr: string
    }[]
}


export interface SenderFormReceiversInterface {
    contactbookDataSourceId?: string,
    contacts?: number[];
    groups?: number[];
    filters?: any;
    organizationFilters?: any,
    organizationUsers?: OrganizationUserForReceiversInterface[];
    organizationAdditionalContacts?: OrganizationAdditionalContactForReceiversInterface[];
    stopListGroupIds?: number[];
    stopListEnabled?: boolean;
    otherRecipientsId?: string;
    other?: {
        phonebookContactId?: number,
        recipientId: string,
        contactRegister?: {
            id: string,
            dataSourceId: string
        }
    }[],
    fromRegister?: {
        uslugaId: string,
        orgId: string,
        sendId: string,
        statusId: string,
        undeliveredReasonId?: string
    },
    fromExcelFile?: any,
    limit?: {
        enabled: boolean,
        first: number,
        last: number,
        random: boolean
    }
}

export interface OrganizationUserForReceiversInterface {
    orgId: string;
    userId: string;
}

export interface OrganizationAdditionalContactForReceiversInterface {
    orgId: string;
    id: string;
}

/** @deprecated */
export const SENDER_FORM_RECEIVERS_TYPE_PHONEBOOK = "phonebook";
/** @deprecated */
export const SENDER_FORM_RECEIVERS_TYPE_DEALER_CLIENTS = "dealerClients";
/** @deprecated */
export const SENDER_FORM_RECEIVERS_TYPE_DEALER_SPECIFIED_CLIENT_CONTACTS = "dealerSpecifiedClientContacts";

/** @deprecated */
export type SenderFormRecepientTypeId = "phonebook" | "dealerClients" | "dealerSpecifiedClientContacts";

export const CONTACTBOOK_DATA_SOURCE_DEALER = "dealer";

export interface ContentFormParamsInterface {
    //recipientTypeId?: string,
    //contentVariables: IContentVariable[],
    sources: SenderSourceInterface[]
}

export interface SenderContentFormValueInterface {
    source?: string
}
