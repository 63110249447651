import {RouteConfig} from "~/node_modules/vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";

export default class Section extends MenuItem{
    private visibleInMenu: boolean = true;

    public static createForRoute(route: RouteConfig, descr: string, icon: string): Section {
        let section = new Section(route.name, descr, icon);
        if (!route.meta) {
            route.meta = {};
        }
        let meta = route.meta as IRouteMeta;
        (route.meta as IRouteMeta).section = section;
        if (meta.menu != null && meta.menu.length) {
            section.setChildrenItems(meta.menu);
        }
        return section;
    }

    get isVisibleInMenu() {
        return this.visibleInMenu;
    }

    setVisibleInMenu(value: boolean) {
        this.visibleInMenu = value;
        return this;
    }
}