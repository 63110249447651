import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import ObjectHelper from "~/ts/library/ObjectHelper";
import MyFilterPreviewTag from "~/cabinet/vue/interface/filter/MyFilterPreviewTag.vue";
import StringHelper from "~/ts/library/StringHelper";
import Random from "~/ts/library/Random";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";

let singleVarRegexp = /\[(.+?)(\(variable-params\)(.+?)\(\/variable-params\))?]/;
const paramsTag = "variable-params";
export default class TextInputWithVariablesHelper {
    private variables: ContentVariableInterface[];
    private id: string;
    private isHtml: boolean;

    constructor(variables: ContentVariableInterface[], isHtml: boolean = false) {
        this.isHtml = isHtml;
        this.setVariables(variables);
        this.id = Random.uid();
    }

    public getId() {
        return this.id;
    }

    public setVariables(variables: ContentVariableInterface[]) {
        this.variables = variables;
        return this;
    }

    //public className = "noneditable-variable";// "noneditable-variable el-tag el-tag--info el-tag--small";
    public static className = "noneditable-variable";// "noneditable-variable el-tag el-tag--info el-tag--small";

    public static getAnchorByVariable(name: string): string {
        return `[${name}]`;
    }

    private static escapeRegExp(str: string): string {
        if (typeof str == "string") {
            return str.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        } else {
            return str;
        }
    }

    public static getRegexpByVariables(variables: string[], isHtml: boolean = false) {
        let items = variables.map(variable => `(\\[(${this.escapeRegExp(variable)})(\\(${paramsTag}\\)(.+?)\\(\\/${paramsTag}\\))?\\])`);
        items.push(`(\\[([А-Яа-я]+)\\])`);
        if (isHtml) {
            //чтобы скипнуть аттрибуты тэгов
            items = items.map(item => `${item}(?!([^<]*)>)`)
        }
        let regexp = items.join("|");

        return new RegExp(regexp, "g");
    }


    get regexp() {
        return TextInputWithVariablesHelper.getRegexpByVariables(
            this.variables.map(varialble => varialble.id),
            this.isHtml
        );
    }


    getLabel(id: string, asyncCallback: (result: string) => void) {
        let match = singleVarRegexp.exec(id);
        if (match.length) {
            let i = 0;
            let variable = this.variables.find(item => item.id == match[1]);
            if (variable) {
                let result = variable.descr;
                if (match[3] && variable.params) {
                    (async () => {
                        let newResult = result;
                        try {
                            let filterValues = JSON.parse(match[3]);
                            if (filterValues && ObjectHelper.hasKeys(filterValues)) {
                                for (let filterId in filterValues) {
                                    if (filterValues.hasOwnProperty(filterId)) {
                                        let filter = variable.params.filters[filterId];
                                        if (filter) {
                                            let operations = filterValues[filterId];
                                            for (let operationId in operations) {
                                                if (operations.hasOwnProperty(operationId)) {
                                                    let operation = MyFilterHelper.getOperations(variable.params)[operationId];
                                                    if (operation) {
                                                        let values = operations[operationId];
                                                        if (i != 0) {
                                                            newResult += ", ";
                                                        } else {
                                                            newResult += " (";
                                                        }
                                                        i++;
                                                        newResult += await new Promise(resolve => {
                                                            var instance = new MyFilterPreviewTag({
                                                                propsData: {
                                                                    values,
                                                                    operation,
                                                                    filter,
                                                                    filtersData: {}
                                                                }
                                                            });

                                                            instance.$once("new-value", () => {
                                                                instance.$nextTick(() => {
                                                                    let html = instance.$el.innerHTML;
                                                                    instance.$destroy();
                                                                    resolve(StringHelper.htmlToText(html).trim());
                                                                });
                                                            });

                                                            instance.$mount();
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (i > 0) {
                                newResult += ")";
                            }

                            asyncCallback(newResult);
                        } catch (e) {

                        }
                    })();

                }
                return result;
            } else {
                return id;//id.substr(1, id.length - 2);
            }
        }
        return id;
    }

}