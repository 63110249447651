import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {AsyncVueComponent} from "~/ts/vuePlugins/Interface";
import {useSenderStore} from "~/cabinet/ts/store/Sender";
import {RouteConfig} from "~/node_modules/vue-router";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import AbstractSourceCardSection from "~/cabinet/vue/client/sender/source/AbstractSourceCardSection.vue";
import {SenderSourceForListInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {PHONEBOOK_COLUMN_NAME_PHONE} from "~/cabinet/vue/client/phonebook/contacts/constants";
import {Component} from "vue";

//export type VueComponent = AsyncVueComponent | Component;

export default class SenderUsluga extends Usluga {
    contentFormComponent: Component;
    hideContentFormWhenHasNoSources: boolean = false;
    isRegionalTimeAvailable: boolean;

    recipientIdPhonebookField: string = PHONEBOOK_COLUMN_NAME_PHONE;

    sourceRouting?: {
        getRoutes: () => RouteConfig;
        getMenu: () => MenuItem;
    };

    rules?: AsyncVueComponent;

    source?: {
        editable?: boolean;
        cardSection?: Component | InstanceType<typeof AbstractSourceCardSection>;
        cardSectionDisplayCondition?: (source: SenderSourceForListInterface) => boolean,
        cardLicenseForm?: Component | InstanceType<typeof AbstractSourceCardSection>;
        cardHeader?: Component | InstanceType<typeof AbstractSourceCardSection>;
        listPrependInfo?: Component;
        routing?: {
            getRoutes: () => RouteConfig;
            getMenu: () => MenuItem;
        },
        formComponent?: Component,
        formModelGetter?: (model: any) => any,
        title: string,
        dealersApprove?: boolean,
        dealersApproveComponent?: Component
    };


    additionalConfigRoutes?: {
        menu: MenuItem[],
        routes: RouteConfig[]
    };

    report: {
        recipientColumnWidth: number;
        recipientComponent?: Component;
        sourceComponent?: Component;
        contentComponent?: Component;
        tasksReportExpandedInfo?: Component;
        opsosCorrectAvailable?: boolean;
    };
    create: {
        topContentComponent?: Component,
        hideReceiversByContentForm?: boolean,
        otherRecipientPlaceholder?: string;
        hideOtherRecipient?: boolean;

        confirm: {
            taskPreviewComponent: Component;
            taskInfoComponent?: Component;
            lg: number;
            md: number;
            priceDescr?: string
        },
    };

    store?: ReturnType<typeof useSenderStore>

    get otherRecipientPlaceholder(): string {
        let result = this.create.otherRecipientPlaceholder;
        if (!result) {
            result = "Введите телефоны через Enter";
        }
        return result;
    }
}