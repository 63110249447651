import {ISenderMessageStatus} from "~/cabinet/ts/data/sender/ISenderMessageStatus";
import Dictionary from "~/ts/library/Dictionary";
import {computed, ref} from "vue";

export function useSenderStore() {
    let data = ref<IInitialSenderUslugaData>();
    let statusList = computed(() => data.value?.statusList ?? []);
    let reportTableStatusIdList = computed(() => data.value?.reportTableStatusIdList ?? []);
    let timeRange = computed(() => data.value?.timeRange);
    let tagTypes = computed(() => data.value?.tagTypes ?? []);
    let magicUrlExpire = computed(() => data.value?.magicUrlExpire ?? 0);

    let statusDictionary = computed<Dictionary<ISenderMessageStatus>>(() => {
        let result: Dictionary<ISenderMessageStatus> = {};
        for (let status of statusList.value) {
            result[status.id] = status;
        }
        return result;
    });

    let reportTableStatusList = computed<ISenderMessageStatus[]>(() => {
        let result = [];
        for (let id of reportTableStatusIdList.value) {
            let status = statusDictionary.value[id];
            if (status) {
                result.push(status);
            }
        }
        return result;
    });

    return {
        setInitialUslugaData(newData: IInitialSenderUslugaData) {
            return data.value = newData;
        },
        statusList,
        reportTableStatusIdList,
        timeRange,
        tagTypes,
        magicUrlExpire,
        statusDictionary,
        reportTableStatusList
    }
}

export interface ITagType {
    id: string;
    descr: string;
    color?: string;
}

interface ITimeRange {
    isAnyTime: boolean,
    range: string[]
}


interface IInitialSenderUslugaData {
    statusList: ISenderMessageStatus[];
    reportTableStatusIdList: string[];
    timeRange: ITimeRange,
    tagTypes: ITagType[],
    magicUrlExpire: number
}