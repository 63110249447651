import {ExtractPropTypes, inject, PropType} from "vue";
import {IHtmlConstructorEditorModel} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {HtmlConstructorEditorInject} from "~/cabinet/vue/interface/htmlConstructor/Constants";

export function useContentDisplayProps<AdditionalEditorTypeParams>() {
    return {
        value: {
            type: Object as PropType<IHtmlConstructorEditorModel<AdditionalEditorTypeParams>>
        }
    }
}

export function useContentDisplay<AdditionalEditorTypeParams>(
    props: Readonly<ExtractPropTypes<ReturnType<typeof useContentDisplayProps<AdditionalEditorTypeParams>>>>,
    getDefaultValue: () => IHtmlConstructorEditorModel<AdditionalEditorTypeParams> = null
) {
    let htmlConstructorEditor = inject(HtmlConstructorEditorInject);

    let model = useObjectModel(props, getDefaultValue ? getDefaultValue : () => undefined);
    return {
        model,
        htmlConstructorEditor
    }
}