import { render, staticRenderFns } from "./ElementWithOptionsConstructor.vue?vue&type=template&id=d174d3a0&scoped=true&"
import script from "./ElementWithOptionsConstructor.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ElementWithOptionsConstructor.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d174d3a0",
  null
  
)

export default component.exports