import UslugaRouteBuilder from "~/cabinet/ts/routes/UslugaRouteBuilder";
import {RouteConfig} from "~/node_modules/vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";

const ROOT_ROUTE_NAME = "CALL_PASSWORD_ROOT";
const REPORTS_ROUTE_NAME = "CALL_PASSWORD_REPORTS";
const ABOUT_ROUTE_NAME = "CALL_PASSWORD_ABOUT";
const API_ROUTE_NAME = "CALL_PASSWORD_API";


export default class CallPasswordRouteBuilder extends UslugaRouteBuilder {
    protected makeRoute(): RouteConfig {
        return {
            name: ROOT_ROUTE_NAME,
            path: "callPassword",
            children: [
                {
                    name: REPORTS_ROUTE_NAME,
                    path: "reports",
                    component: () => import(/* webpackChunkName: "client-callpassword" */ '~/cabinet/vue/client/callPassword/reports/CallPasswordList.vue')
                },
                {
                    name: ABOUT_ROUTE_NAME,
                    path: "about",
                    component: () => import(/* webpackChunkName: "client-callpassword" */ '~/cabinet/vue/client/callPassword/CallPasswordAbout.vue')
                },
                {
                    name: API_ROUTE_NAME,
                    path: "api",
                    component: () => import(/* webpackChunkName: "client-callpassword" */ '~/cabinet/vue/client/callPassword/CallPasswordApiTokens.vue')
                }
            ],
            meta: {
                menu: [
                    new MenuItem(REPORTS_ROUTE_NAME, "Отчёты", "chart-bar"),
                    new MenuItem(API_ROUTE_NAME, "API", "code"),
                    new MenuItem(ABOUT_ROUTE_NAME, "Об услуге", "question-circle")
                ]
            } as IRouteMeta
        };
    }

}