import { render, staticRenderFns } from "./ColorPreview.vue?vue&type=template&id=477132a5&scoped=true&"
import script from "./ColorPreview.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ColorPreview.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ColorPreview.vue?vue&type=style&index=0&id=477132a5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477132a5",
  null
  
)

export default component.exports