import {HtmlBlockAlign} from "~/cabinet/vue/interface/htmlConstructor/Constants";
import Dictionary from "~/ts/library/Dictionary";
import IBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/IBlockValues";

export const HTML_CONSTRUCTOR_HEADER_TYPE_TEXT = "text";
export const HTML_CONSTRUCTOR_HEADER_TYPE_IMAGE = "image";

export const HTML_CONSTRUCTOR_HEADER_TYPES: Dictionary<string> = {
    [HTML_CONSTRUCTOR_HEADER_TYPE_TEXT]: "Текст",
    [HTML_CONSTRUCTOR_HEADER_TYPE_IMAGE]: "Картинка"
};

export interface IHeaderBlockValues extends IBlockValues {
    h1: string,
    h2: string,
    href: string,
    align: HtmlBlockAlign,
    src: string,
    headerType: "text" | "image"
}

export interface IHeaderBlockStyles {
    headerBackground: string,
    headerBackgroundImage: string,
    headerBackgroundRepeat: string,
    headerH1Color: string,
    headerH1Font: string,
    headerH1Size: number,
    headerH2FontWeight: string,
    headerH1FontWeight: string,
    headerH2Color: string,
    headerH2Font: string,
    headerH2Size: number
}