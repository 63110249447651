import CascadeSchemeRelation from "~/cabinet/ts/data/sender/cascade/CascadeSchemeRelation";
import ActionBlock from "~/cabinet/ts/data/events/ActionBlock";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {InjectionKey, Ref} from "vue";


export const FLOW_CHART_POINT_SIZE = 16;

export interface INewRelationLine {
    blockId: string,
    outputId: string,
    coords?: {
        x: number,
        y: number,
        hoverBlockId?: string
    }

}

export interface IFlowChartModel {
    blocks: ActionBlock[],
    relations: CascadeSchemeRelation[]
}

export interface IFlowChartContentVariable extends ContentVariableInterface {
    isGlobalVar?: boolean;
}

export interface FlowChartExposeInterface {
    getModel(): IFlowChartModel;

    importScheme(value: IFlowChartModel): void;
}

export const FlowChartScaleValueInjectionKey = Symbol() as InjectionKey<Ref<number>>;