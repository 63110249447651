import { render, staticRenderFns } from "./AccountButton.vue?vue&type=template&id=7acdb950&scoped=true&"
import script from "./AccountButton.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AccountButton.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./AccountButton.vue?vue&type=style&index=0&id=7acdb950&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7acdb950",
  null
  
)

export default component.exports