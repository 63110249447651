import Dictionary from "~/ts/library/Dictionary";

export function $icon(icon: string, className: string = "far") {
    if (icon.indexOf("icon-") == 0) {
        icon = icon.substr(5);
    }

    if (oldIcons[icon]) {
        icon = oldIcons[icon];
    }

    className = brandIcons.indexOf(icon) > -1 ? "fab" : className;

    return "-icon " + className + " fa-" + icon;
}

export function $iconLight(icon: string) {
    return $icon(icon, "fal");
}

let IconPlugin = {
    install: function (Vue: any) {
        Vue.prototype.$icon = $icon;
        Vue.prototype.$iconLight = $iconLight;
        Vue.filter('icon', function (value: string) {
            return Vue.prototype.$icon(value);
        });
    }
};

export default IconPlugin;

//TODO: нужны ради пизделки. Можно будет удалить после переделки кабинета
let oldIcons: Dictionary<string> = {
    "address-book-o": "address-book",
    "address-card-o": "address-card",
    "area-chart": "chart-area",
    "arrow-circle-o-down": "arrow-alt-circle-down",
    "arrow-circle-o-left": "arrow-alt-circle-left",
    "arrow-circle-o-right": "arrow-alt-circle-right",
    "arrow-circle-o-up": "arrow-alt-circle-up",
    "arrows": "arrows-alt",
    "arrows-alt": "expand-arrows-alt",
    "arrows-h": "arrows-alt-h",
    "arrows-v": "arrows-alt-v",
    "asl-interpreting": "american-sign-language-interpreting",
    "automobile": "car",
    "bank": "university",
    "bar-chart": "chart-bar",
    "bar-chart-o": "chart-bar",
    "bathtub": "bath",
    "battery": "battery-full",
    "battery-0": "battery-empty",
    "battery-1": "battery-quarter",
    "battery-2": "battery-half",
    "battery-3": "battery-three-quarters",
    "battery-4": "battery-full",
    "bell-o": "bell",
    "bell-slash-o": "bell-slash",
    "bitbucket-square": "bitbucket",
    "bitcoin": "btc",
    "bookmark-o": "bookmark",
    "building-o": "building",
    "cab": "taxi",
    "calendar": "calendar-alt",
    "calendar-check-o": "calendar-check",
    "calendar-minus-o": "calendar-minus",
    "calendar-o": "calendar",
    "calendar-plus-o": "calendar-plus",
    "calendar-times-o": "calendar-times",
    "caret-square-o-down": "caret-square-down",
    "caret-square-o-left": "caret-square-left",
    "caret-square-o-right": "caret-square-right",
    "caret-square-o-up": "caret-square-up",
    "cc": "closed-captioning",
    "chain": "link",
    "chain-broken": "unlink",
    "check-circle-o": "check-circle",
    "check-square-o": "check-square",
    "circle-o": "circle",
    "circle-o-notch": "circle-notch",
    "circle-thin": "circle",
    "clock-o": "clock",
    "close": "times",
    "cloud-download": "cloud-download-alt",
    "cloud-upload": "cloud-upload-alt",
    "cny": "yen-sign",
    "code-fork": "code-branch",
    "comment-o": "comment",
    "commenting": "comment-dots",
    "commenting-o": "comment-dots",
    "comments-o": "comments",
    "credit-card-alt": "credit-card",
    "cutlery": "utensils",
    "dashboard": "tachometer-alt",
    "deafness": "deaf",
    "dedent": "outdent",
    "diamond": "gem",
    "dollar": "dollar-sign",
    "dot-circle-o": "dot-circle",
    "drivers-license": "id-card",
    "drivers-license-o": "id-card",
    "eercast": "sellcast",
    "envelope-o": "envelope",
    "envelope-open-o": "envelope-open",
    "eur": "euro-sign",
    "euro": "euro-sign",
    "exchange": "exchange-alt",
    "external-link": "external-link-alt",
    "external-link-square": "external-link-square-alt",
    "eyedropper": "eye-dropper",
    "fa": "font-awesome",
    "facebook": "facebook-f",
    "facebook-official": "facebook",
    "feed": "rss",
    "file-archive-o": "file-archive",
    "file-audio-o": "file-audio",
    "file-code-o": "file-code",
    "file-excel-o": "file-excel",
    "file-image-o": "file-image",
    "file-movie-o": "file-video",
    "file-o": "file",
    "file-pdf-o": "file-pdf",
    "file-photo-o": "file-image",
    "file-picture-o": "file-image",
    "file-powerpoint-o": "file-powerpoint",
    "file-sound-o": "file-audio",
    "file-text": "file-alt",
    "file-text-o": "file-alt",
    "file-video-o": "file-video",
    "file-word-o": "file-word",
    "file-zip-o": "file-archive",
    "files-o": "copy",
    "flag-o": "flag",
    "flash": "bolt",
    "floppy-o": "save",
    "folder-o": "folder",
    "folder-open-o": "folder-open",
    "frown-o": "frown",
    "futbol-o": "futbol",
    "gbp": "pound-sign",
    "ge": "empire",
    "gear": "cog",
    "gears": "cogs",
    "gittip": "gratipay",
    "glass": "glass-martini",
    "google-plus": "google-plus-g",
    "google-plus-circle": "google-plus",
    "google-plus-official": "google-plus",
    "group": "users",
    "hand-grab-o": "hand-rock",
    "hand-lizard-o": "hand-lizard",
    "hand-o-down": "hand-point-down",
    "hand-o-left": "hand-point-left",
    "hand-o-right": "hand-point-right",
    "hand-o-up": "hand-point-up",
    "hand-paper-o": "hand-paper",
    "hand-peace-o": "hand-peace",
    "hand-pointer-o": "hand-pointer",
    "hand-rock-o": "hand-rock",
    "hand-scissors-o": "hand-scissors",
    "hand-spock-o": "hand-spock",
    "hand-stop-o": "hand-paper",
    "handshake-o": "handshake",
    "hard-of-hearing": "deaf",
    "hdd-o": "hdd",
    "header": "heading",
    "heart-o": "heart",
    "hospital-o": "hospital",
    "hotel": "bed",
    "hourglass-1": "hourglass-start",
    "hourglass-2": "hourglass-half",
    "hourglass-3": "hourglass-end",
    "hourglass-o": "hourglass",
    "id-card-o": "id-card",
    "ils": "shekel-sign",
    "inr": "rupee-sign",
    "institution": "university",
    "intersex": "transgender",
    "jpy": "yen-sign",
    "keyboard-o": "keyboard",
    "krw": "won-sign",
    "legal": "gavel",
    "lemon-o": "lemon",
    "level-down": "level-down-alt",
    "level-up": "level-up-alt",
    "life-bouy": "life-ring",
    "life-buoy": "life-ring",
    "life-saver": "life-ring",
    "lightbulb-o": "lightbulb",
    "line-chart": "chart-line",
    "linkedin": "linkedin-in",
    "linkedin-square": "linkedin",
    "long-arrow-down": "long-arrow-alt-down",
    "long-arrow-left": "long-arrow-alt-left",
    "long-arrow-right": "long-arrow-alt-right",
    "long-arrow-up": "long-arrow-alt-up",
    "mail-forward": "share",
    "mail-reply": "reply",
    "mail-reply-all": "reply-all",
    "map-marker": "map-marker-alt",
    "map-o": "map",
    "meanpath": "font-awesome",
    "meh-o": "meh",
    "minus-square-o": "minus-square",
    "mobile": "mobile-alt",
    "mobile-phone": "mobile-alt",
    "money": "money-bill-alt",
    "moon-o": "moon",
    "mortar-board": "graduation-cap",
    "navicon": "bars",
    "newspaper-o": "newspaper",
    "paper-plane-o": "paper-plane",
    "paste": "clipboard",
    "pause-circle-o": "pause-circle",
    "pencil": "pencil-alt",
    "pencil-square": "pen-square",
    "pencil-square-o": "edit",
    "photo": "image",
    "picture-o": "image",
    "pie-chart": "chart-pie",
    "play-circle-o": "play-circle",
    "plus-square-o": "plus-square",
    "question-circle-o": "question-circle",
    "ra": "rebel",
    "refresh": "sync",
    "remove": "times",
    "reorder": "bars",
    "repeat": "redo",
    "resistance": "rebel",
    "rmb": "yen-sign",
    "rotate-left": "undo",
    "rotate-right": "redo",
    "rouble": "ruble-sign",
    "rub": "ruble-sign",
    "ruble": "ruble-sign",
    "rupee": "rupee-sign",
    "s15": "bath",
    "scissors": "cut",
    "send": "paper-plane",
    "send-o": "paper-plane",
    "share-square-o": "share-square",
    "shekel": "shekel-sign",
    "sheqel": "shekel-sign",
    "shield": "shield-alt",
    "sign-in": "sign-in-alt",
    "sign-out": "sign-out-alt",
    "signing": "sign-language",
    "sliders": "sliders-h",
    "smile-o": "smile",
    "snowflake-o": "snowflake",
    "soccer-ball-o": "futbol",
    "sort-alpha-asc": "sort-alpha-down",
    "sort-alpha-desc": "sort-alpha-up",
    "sort-amount-asc": "sort-amount-down",
    "sort-amount-desc": "sort-amount-up",
    "sort-asc": "sort-up",
    "sort-desc": "sort-down",
    "sort-numeric-asc": "sort-numeric-down",
    "sort-numeric-desc": "sort-numeric-up",
    "spoon": "utensil-spoon",
    "square-o": "square",
    "star-half-empty": "star-half",
    "star-half-full": "star-half",
    "star-half-o": "star-half",
    "star-o": "star",
    "sticky-note-o": "sticky-note",
    "stop-circle-o": "stop-circle",
    "sun-o": "sun",
    "support": "life-ring",
    "tablet": "tablet-alt",
    "tachometer": "tachometer-alt",
    "television": "tv",
    "thermometer": "thermometer-full",
    "thermometer-0": "thermometer-empty",
    "thermometer-1": "thermometer-quarter",
    "thermometer-2": "thermometer-half",
    "thermometer-3": "thermometer-three-quarters",
    "thermometer-4": "thermometer-full",
    "thumb-tack": "thumbtack",
    "thumbs-o-down": "thumbs-down",
    "thumbs-o-up": "thumbs-up",
    "ticket": "ticket-alt",
    "times-circle-o": "times-circle",
    "times-rectangle": "window-close",
    "times-rectangle-o": "window-close",
    "toggle-down": "caret-square-down",
    "toggle-left": "caret-square-left",
    "toggle-right": "caret-square-right",
    "toggle-up": "caret-square-up",
    "trash": "trash-alt",
    "trash-o": "trash-alt",
    "try": "lira-sign",
    "turkish-lira": "lira-sign",
    "unsorted": "sort",
    "usd": "dollar-sign",
    "user-circle-o": "user-circle",
    "user-o": "user",
    "vcard": "address-card",
    "vcard-o": "address-card",
    "video-camera": "video",
    "vimeo": "vimeo-v",
    "volume-control-phone": "phone-volume",
    "warning": "exclamation-triangle",
    "wechat": "weixin",
    "wheelchair-alt": "accessible-icon",
    "window-close-o": "window-close",
    "won": "won-sign",
    "y-combinator-square": "hacker-news",
    "yc": "y-combinator",
    "yc-square": "hacker-news",
    "yen": "yen-sign",
    "youtube-play": "youtube"
};


let brandIcons =
    [
        "500px",
        "accessible-icon",
        "accusoft",
        "acquisitions-incorporated",
        "adn",
        "adobe",
        "adversal",
        "affiliatetheme",
        "airbnb",
        "algolia",
        "alipay",
        "amazon",
        "amazon-pay",
        "amilia",
        "android",
        "angellist",
        "angrycreative",
        "angular",
        "app-store",
        "app-store-ios",
        "apper",
        "apple",
        "apple-pay",
        "artstation",
        "asymmetrik",
        "atlassian",
        "audible",
        "autoprefixer",
        "avianex",
        "aviato",
        "aws",
        "bandcamp",
        "battle-net",
        "behance",
        "behance-square",
        "bimobject",
        "bitbucket",
        "bitcoin",
        "bity",
        "black-tie",
        "blackberry",
        "blogger",
        "blogger-b",
        "bluetooth",
        "bluetooth-b",
        "bootstrap",
        "btc",
        "buffer",
        "buromobelexperte",
        "buysellads",
        "canadian-maple-leaf",
        "cc-amazon-pay",
        "cc-amex",
        "cc-apple-pay",
        "cc-diners-club",
        "cc-discover",
        "cc-jcb",
        "cc-mastercard",
        "cc-paypal",
        "cc-stripe",
        "cc-visa",
        "centercode",
        "centos",
        "chrome",
        "chromecast",
        "cloudscale",
        "cloudsmith",
        "cloudversify",
        "codepen",
        "codiepie",
        "confluence",
        "connectdevelop",
        "contao",
        "cpanel",
        "creative-commons",
        "creative-commons-by",
        "creative-commons-nc",
        "creative-commons-nc-eu",
        "creative-commons-nc-jp",
        "creative-commons-nd",
        "creative-commons-pd",
        "creative-commons-pd-alt",
        "creative-commons-remix",
        "creative-commons-sa",
        "creative-commons-sampling",
        "creative-commons-sampling-plus",
        "creative-commons-share",
        "creative-commons-zero",
        "critical-role",
        "css3",
        "css3-alt",
        "cuttlefish",
        "d-and-d",
        "d-and-d-beyond",
        "dashcube",
        "delicious",
        "deploydog",
        "deskpro",
        "dev",
        "deviantart",
        "dhl",
        "diaspora",
        "digg",
        "digital-ocean",
        "discord",
        "discourse",
        "dochub",
        "docker",
        "draft2digital",
        "dribbble",
        "dribbble-square",
        "dropbox",
        "drupal",
        "dyalog",
        "earlybirds",
        "ebay",
        "edge",
        "elementor",
        "ello",
        "ember",
        "empire",
        "envira",
        "erlang",
        "ethereum",
        "etsy",
        "evernote",
        "expeditedssl",
        "facebook",
        "facebook-f",
        "facebook-messenger",
        "facebook-square",
        "fantasy-flight-games",
        "fedex",
        "fedora",
        "figma",
        "firefox",
        "first-order",
        "first-order-alt",
        "firstdraft",
        "flickr",
        "flipboard",
        "fly",
        "font-awesome",
        "font-awesome-alt",
        "font-awesome-flag",
        "font-awesome-logo-full",
        "fonticons",
        "fonticons-fi",
        "fort-awesome",
        "fort-awesome-alt",
        "forumbee",
        "foursquare",
        "free-code-camp",
        "freebsd",
        "fulcrum",
        "galactic-republic",
        "galactic-senate",
        "get-pocket",
        "gg",
        "gg-circle",
        "git",
        "git-alt",
        "git-square",
        "github",
        "github-alt",
        "github-square",
        "gitkraken",
        "gitlab",
        "gitter",
        "glide",
        "glide-g",
        "gofore",
        "goodreads",
        "goodreads-g",
        "google",
        "google-drive",
        "google-play",
        "google-plus",
        "google-plus-g",
        "google-plus-square",
        "google-wallet",
        "gratipay",
        "grav",
        "gripfire",
        "grunt",
        "gulp",
        "hacker-news",
        "hacker-news-square",
        "hackerrank",
        "hips",
        "hire-a-helper",
        "hooli",
        "hornbill",
        "hotjar",
        "houzz",
        "html5",
        "hubspot",
        "imdb",
        "instagram",
        "intercom",
        "internet-explorer",
        "invision",
        "ioxhost",
        "itch-io",
        "itunes",
        "itunes-note",
        "java",
        "jedi-order",
        "jenkins",
        "jira",
        "joget",
        "joomla",
        "js",
        "js-square",
        "jsfiddle",
        "kaggle",
        "keybase",
        "keycdn",
        "kickstarter",
        "kickstarter-k",
        "korvue",
        "laravel",
        "lastfm",
        "lastfm-square",
        "leanpub",
        "less",
        "line",
        "linkedin",
        "linkedin-in",
        "linode",
        "linux",
        "lyft",
        "magento",
        "mailchimp",
        "mandalorian",
        "markdown",
        "mastodon",
        "maxcdn",
        "medapps",
        "medium",
        "medium-m",
        "medrt",
        "meetup",
        "megaport",
        "mendeley",
        "microsoft",
        "mix",
        "mixcloud",
        "mizuni",
        "modx",
        "monero",
        "napster",
        "neos",
        "nimblr",
        "node",
        "node-js",
        "npm",
        "ns8",
        "nutritionix",
        "odnoklassniki",
        "odnoklassniki-square",
        "old-republic",
        "opencart",
        "openid",
        "opera",
        "optin-monster",
        "osi",
        "page4",
        "pagelines",
        "palfed",
        "patreon",
        "paypal",
        "penny-arcade",
        "periscope",
        "phabricator",
        "phoenix-framework",
        "phoenix-squadron",
        "php",
        "pied-piper",
        "pied-piper-alt",
        "pied-piper-hat",
        "pied-piper-pp",
        "pinterest",
        "pinterest-p",
        "pinterest-square",
        "playstation",
        "product-hunt",
        "pushed",
        "python",
        "qq",
        "quinscape",
        "quora",
        "r-project",
        "raspberry-pi",
        "ravelry",
        "react",
        "reacteurope",
        "readme",
        "rebel",
        "red-river",
        "reddit",
        "reddit-alien",
        "reddit-square",
        "redhat",
        "renren",
        "replyd",
        "researchgate",
        "resolving",
        "rev",
        "rocketchat",
        "rockrms",
        "safari",
        "salesforce",
        "sass",
        "schlix",
        "scribd",
        "searchengin",
        "sellcast",
        "sellsy",
        "servicestack",
        "shirtsinbulk",
        "shopware",
        "simplybuilt",
        "sistrix",
        "sith",
        "sketch",
        "skyatlas",
        "skype",
        "slack",
        "slack-hash",
        "slideshare",
        "snapchat",
        "snapchat-ghost",
        "snapchat-square",
        "soundcloud",
        "sourcetree",
        "speakap",
        "speaker-deck",
        "spotify",
        "squarespace",
        "stack-exchange",
        "stack-overflow",
        "stackpath",
        "staylinked",
        "steam",
        "steam-square",
        "steam-symbol",
        "sticker-mule",
        "strava",
        "stripe",
        "stripe-s",
        "studiovinari",
        "stumbleupon",
        "stumbleupon-circle",
        "superpowers",
        "supple",
        "suse",
        "symfony",
        "teamspeak",
        "telegram",
        "telegram-plane",
        "tencent-weibo",
        "the-red-yeti",
        "themeco",
        "themeisle",
        "think-peaks",
        "trade-federation",
        "trello",
        "tripadvisor",
        "tumblr",
        "tumblr-square",
        "twitch",
        "twitter",
        "twitter-square",
        "typo3",
        "uber",
        "ubuntu",
        "uikit",
        "uniregistry",
        "untappd",
        "ups",
        "usb",
        "usps",
        "ussunnah",
        "vaadin",
        "viacoin",
        "viadeo",
        "viadeo-square",
        "viber",
        "vimeo",
        "vimeo-square",
        "vimeo-v",
        "vine",
        "vk",
        "vnv",
        "vuejs",
        "waze",
        "weebly",
        "weibo",
        "weixin",
        "whatsapp",
        "whatsapp-square",
        "whmcs",
        "wikipedia-w",
        "windows",
        "wix",
        "wizards-of-the-coast",
        "wolf-pack-battalion",
        "wordpress",
        "wordpress-simple",
        "wpbeginner",
        "wpexplorer",
        "wpforms",
        "wpressr",
        "xbox",
        "xing",
        "xing-square",
        "y-combinator",
        "yahoo",
        "yammer",
        "yandex",
        "yandex-international",
        "yarn",
        "yelp",
        "yoast",
        "youtube",
        "youtube-square",
        "zhihu"
    ];