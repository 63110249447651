import AbstractEntity, {AbstractEntityFields, Discriminator} from "~/ts/library/AbstractEntity";
import ActionOutput from "~/cabinet/ts/data/events/ActionOutput";
import ActionBlock, {ActionBlockParamModelInterface} from "~/cabinet/ts/data/events/ActionBlock";
import SenderBlockPreview from "~/cabinet/vue/client/events/action/types/common/sender/send/SenderBlockPreview.vue";
import SenderBlockForm from "~/cabinet/vue/client/events/action/types/common/sender/send/SenderBlockForm.vue";
import UserType from "~/cabinet/ts/data/events/UserType";
import WebhookBlockPreview from "~/cabinet/vue/client/events/action/types/common/webhook/WebhookBlockPreview.vue";
import WebhookBlockForm from "~/cabinet/vue/client/events/action/types/common/webhook/WebhookBlockForm.vue";
import DelayBlockPreview from "~/cabinet/vue/client/events/action/types/common/delay/DelayBlockPreview.vue";
import DelayBlockForm from "~/cabinet/vue/client/events/action/types/common/delay/DelayBlockForm.vue";
import CallBlockForm from "~/cabinet/vue/client/events/action/types/dealer/call/CallBlockForm.vue";
import CabinetNotificationBlockPreview
    from "~/cabinet/vue/client/events/action/types/dealer/cabinetNotification/CabinetNotificationBlockPreview.vue";
import CabinetNotificationForm
    from "~/cabinet/vue/client/events/action/types/dealer/cabinetNotification/CabinetNotificationForm.vue";
import OnlineChatMessageToOperatorBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toOperator/OnlineChatMessageToOperatorBlockPreview.vue";
import OnlineChatMessageToOperatorForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toOperator/OnlineChatMessageToOperatorForm.vue";
import {IForm, IOption} from "~/cabinet/vue/interface/form/elements/Interfaces";
import PhonebookWithGroupSelectForm
    from "~/cabinet/vue/client/events/action/types/phonebook/PhonebookWithGroupSelectForm.vue";
import PhonebookWithGroupSelectBlockPreview
    from "~/cabinet/vue/client/events/action/types/phonebook/PhonebookWithGroupSelectBlockPreview.vue";
import OnlineChatRedirectBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/redirect/OnlineChatRedirectBlockPreview.vue";
import OnlineChatRedirectBlockForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/redirect/OnlineChatRedirectBlockForm.vue";
import OnlineChatOpenWidgetTabBlockForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/openWidgetTab/OnlineChatOpenWidgetTabBlockForm.vue";
import OnlineChatOpenWidgetTabBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/openWidgetTab/OnlineChatOpenWidgetTabBlockPreview.vue";
import EventAsActionBlockForm
    from "~/cabinet/vue/client/events/action/types/common/eventAsAction/EventAsActionBlockForm.vue";
import EventAsActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/common/eventAsAction/EventAsActionBlockPreview.vue";
import OnlineChatMessageToClientBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toClient/OnlineChatMessageToClientBlockPreview.vue";
import OnlineChatMessageToClientForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toClient/OnlineChatMessageToClientForm.vue";
import OnlineChatRequestRateForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/requestRate/OnlineChatRequestRateForm.vue";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import FilterBlockForm from "~/cabinet/vue/client/events/action/types/common/filter/FilterBlockForm.vue";
import FilterBlockOutput from "~/cabinet/vue/client/events/action/types/common/filter/FilterBlockOutput.vue";
import {
    DiscountCouponBlockParam,
    ICabinetNotificationParam,
    ICallAlgoBlockParam,
    IDelayCascadeBlockParam,
    IEventAsActionParam,
    IFilterCascadeBlockParam,
    IGlobalVarsBlockParam,
    INoteCascadeBlockParam,
    IOnlineChatForwardClientToOperatorParam,
    IOnlineChatMessageToClientParam,
    IOnlineChatMessageToOperatorParam,
    IOnlineChatOpenWidgetTabParam,
    IOnlineChatRedirectParam,
    IOnlineChatRequestRateParam,
    IPhonebookWithGroupSelectParam,
    ISenderCascadeBlockParam,
    ISendNotificationToClientOnlineChatOperatorParam,
    IWebhookCascadeBlockParam,
    ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID
} from "~/cabinet/vue/client/events/action/types/Interface";
import SendNotificationToClientOnlineChatOperatorForm
    from "~/cabinet/vue/client/events/action/types/dealer/sendNotificationToClientOnlineChatOperator/SendNotificationToClientOnlineChatOperatorForm.vue";
import SendNotificationToClientOnlineChatOperatorBlockPreview
    from "~/cabinet/vue/client/events/action/types/dealer/sendNotificationToClientOnlineChatOperator/SendNotificationToClientOnlineChatOperatorBlockPreview.vue";
import OnlineChatForwardClientToOperatorForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/forwardClientToOperator/OnlineChatForwardClientToOperatorForm.vue";
import OnlineChatForwardClientToOperatorBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/forwardClientToOperator/OnlineChatForwardClientToOperatorBlockPreview.vue";
import GlobalVarsBlockPreview
    from "~/cabinet/vue/client/events/action/types/common/globalVars/GlobalVarsBlockPreview.vue";
import GlobalVarsBlockForm from "~/cabinet/vue/client/events/action/types/common/globalVars/GlobalVarsBlockForm.vue";
import Dictionary from "~/ts/library/Dictionary";
import {IFilter, IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {IFlowChartContentVariable} from "~/cabinet/vue/interface/flowChart/FlowChartInterfaces";
import ContactbookGroupBlockForm
    from "~/cabinet/vue/client/events/action/types/phonebook/ContactbookGroupBlockForm.vue";
import OnlineChatMessageToClientBlockOutput
    from "~/cabinet/vue/client/events/action/types/onlineChat/message/toClient/OnlineChatMessageToClientBlockOutput.vue";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {Component} from "vue";
import DiscountCouponBlockPreview
    from "~/cabinet/vue/client/events/action/types/dealer/discountCoupon/DiscountCouponBlockPreview.vue";
import DiscountCouponBlockForm
    from "~/cabinet/vue/client/events/action/types/dealer/discountCoupon/DiscountCouponBlockForm.vue";
import AbtractDataProviderActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/dataProvider/AbtractDataProviderActionBlockPreview.vue";
import AbtractDataProviderActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/AbtractDataProviderActionBlockForm.vue";
import DataProviderEntitySaveActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionBlockForm.vue";
import DataProviderEntitySaveActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionBlockPreview.vue";
import ToggleTagActionBlockForm
    from "~/cabinet/vue/client/events/action/types/common/toggleTag/ToggleTagActionBlockForm.vue";
import ToggleTagActionBlockPreview
    from "~/cabinet/vue/client/events/action/types/common/toggleTag/ToggleTagActionBlockPreview.vue";
import {
    AbstractDataProviderActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/AbstractDataProviderActionParamInterface";
import {
    DataProviderEntitySaveActionLinkInterface,
    DataProviderEntitySaveActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/entitySave/DataProviderEntitySaveActionParamInterface";
import {
    ToggleTagActionParamInterface
} from "~/cabinet/vue/client/events/action/types/common/toggleTag/ToggleTagActionParamInterface";
import {
    CrmSendMessageActionParamInterface
} from "~/cabinet/vue/client/events/action/types/dataProvider/crm/sendMessage/CrmSendMessageActionParamInterface";
import CrmSendMessageActionBlockForm
    from "~/cabinet/vue/client/events/action/types/dataProvider/crm/sendMessage/CrmSendMessageActionBlockForm.vue";
import OnlineChatRunExternalBotBlockPreview
    from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/OnlineChatRunExternalBotBlockPreview.vue";
import OnlineChatRunExternalBotForm
    from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/OnlineChatRunExternalBotForm.vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import Random from "~/ts/library/Random";
import {
    RunExternalBotActionModelInterface
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/Interface";
import NoteBlockPreview from "~/cabinet/vue/client/events/action/types/common/note/NoteBlockPreview.vue";
import NoteBlockForm from "~/cabinet/vue/client/events/action/types/common/note/NoteBlockForm.vue";


export type ActionDescriptionGroup = { type: "group", groupDescr: string, actionDescriptions: ActionDescription<any>[] };

export const GLOBAL_VARS_ACTION_DESCRIPTION_ID = "globalVars";
export const WEBHOOK_ACTION_DESCRIPTION_ID = "webhook";
export const DISCOUNT_COUPON_ACTION_DESCRIPTION_ID = "discountCoupon";
export const ONLINE_CHAT_MESSAGE_FOR_CLIENT_ACTION_DESCRIPTION_ID = "onlineChatMessageForClient";
export const ONLINE_CHAT_REQUEST_RATE_ACTION_DESCRIPTION_ID = "onlineChatRequestRate";

export function groupActionDescriptions(actionDescriptions: ActionDescription<any>[]) {
    let result: (ActionDescription<any> | ActionDescriptionGroup)[] = [];
    for (let actionDescription of actionDescriptions) {
        if (actionDescription.isReadyToUse) {
            let otherItemsOfThisGroup = actionDescriptions.filter(item => item.groupDescr == actionDescription.groupDescr);
            if (!actionDescription.groupDescr || otherItemsOfThisGroup.length == 1 || otherItemsOfThisGroup.length == actionDescriptions.length) {
                result.push(actionDescription);
            } else {
                let group = (result as ActionDescriptionGroup[]).find(item => item.type == "group" && item.groupDescr == actionDescription.groupDescr);
                if (!group) {
                    group = {
                        type: "group",
                        groupDescr: actionDescription.groupDescr,
                        actionDescriptions: [],
                    };
                    result.push(group);
                }
                group.actionDescriptions.push(actionDescription);
            }
        }
    }
    return result;
}

export interface IActionDataField extends IDataField {

}

export interface IEventDataField extends IDataField {

}

export interface IDataField {
    id: string,
    descr: string,
    typeId?: string,
    groupDescr?: string
}

interface IActionDescriptionFrontendParams {
}

export default class ActionDescription<Model, FrontendParams extends IActionDescriptionFrontendParams = IActionDescriptionFrontendParams> extends AbstractEntity {
    public id: string;
    public typeId: string;
    public platformId: string;
    public platformDescr: string;
    public frontendParams: FrontendParams;
    public descr: string;
    public groupDescr?: string;
    public extraDescr?: string;
    public iconId: string;
    public dialogWidth: number;

    public isOutputDependOnBlock?: boolean;
    public authPrimaryId?: string;
    private outputs: ActionOutput[];
    public maxOutputTime: number;
    public minOutputTime: number;
    public defaultOutputTime: number;
    public isUseOutputTime?: boolean;
    public isRemovable: boolean;
    public isEditable: boolean;
    private readyToUse: boolean;
    public isInputAllowed: boolean;
    public backgroundColor: string;
    public textColor: string;
    public isExpert: boolean;
    public form?: IForm;
    public availableError?: string;

    makeNewBlock() {
        return new ActionBlock();
    }

    getOutputs(block: ActionBlock) {
        return this.isOutputDependOnBlock ? block.outputs : this.outputs;
    }

    protected getFields(): AbstractEntityFields {
        return {
            outputs: ActionOutput
        };
    }

    protected getDiscriminator(): Discriminator {
        return new Discriminator("typeId", {
            sender: SenderActionDescription,
            [WEBHOOK_ACTION_DESCRIPTION_ID]: WebhookActionDescription,
            [DISCOUNT_COUPON_ACTION_DESCRIPTION_ID]: DiscountCouponActionDescription,
            delay: DelayActionDescription,
            note: NoteActionDescription,
            filter: FilterActionDescription,
            abtest: AbTestActionDescription,
            call: CallActionDescription,
            cabinetNotification: CabinetNotificationActionDescription,
            onlineChatMessage: OnlineChatMessageToOperatorActionDescription,
            [ONLINE_CHAT_MESSAGE_FOR_CLIENT_ACTION_DESCRIPTION_ID]: OnlineChatMessageToClientActionDescription,
            [ONLINE_CHAT_REQUEST_RATE_ACTION_DESCRIPTION_ID]: OnlineChatRequestRateActionDescription,
            onlineChatForwardClientToOperator: OnlineChatForwardClientToOperatorActionDescription,
            phonebookActionWithGroupSelect: PhonebookActionWithGroupActionDescription,
            onlineChatRedirect: OnlineChatClientRedirectActionDescription,
            onlineChatOpenTab: OnlineChatClientOpenWidgetTabActionDescription,
            //crm: CrmActionDescription,
            eventAsAction: EventAsActionDescription,
            sendNotificationToClientOnlineChatOperator: SendNotificationToClientOnlineChatOperatorActionDescription,
            [GLOBAL_VARS_ACTION_DESCRIPTION_ID]: GlobalVarsActionDescription,
            contactbookAddToGroup: ContactbookGroupActionDescription,
            contactbookRemoveFromGroup: ContactbookGroupActionDescription,
            dataProviderEntitySave: DataProviderEntitySaveActionDescription,
            crmSendMessage: CrmSendMessageActionDescription,
            toggleTags: ToggleTagActionDescription,
            onlineChatRunExternalBot: OnlineChatRunExternalBotActionDescription
        });
    }

    get previewComponent(): Component {
        return null;
    }

    get formComponent(): Component {
        return null;
    }

    get outputComponent(): Component {
        return null;
    }

    get isReadyToUse(): boolean {
        return this.readyToUse || AccountStore.isSandbox.value;
    }

    isNeedOutputTimeByActionBlock(actionBlock: ActionBlock): boolean {
        return true;
    }

    validateModel(model: Model): string | null {
        return null;
    }

    protected isFreezeObject(): boolean {
        return true;
    }

}


interface IActionDescriptionWithUserTypeFrontendParams extends IActionDescriptionFrontendParams {
    userTypes: UserType[];
}

export class AbstractActionDescriptionWithUserType<Model, FrontendParams extends IActionDescriptionWithUserTypeFrontendParams = IActionDescriptionWithUserTypeFrontendParams> extends ActionDescription<Model, FrontendParams> {
    get isUseUserType(): boolean {
        return this.userTypes && this.userTypes.length > 0;
    }

    get userTypes(): UserType[] {
        return this.frontendParams.userTypes;
    }
}

interface ISenderActionFrontendParams extends IActionDescriptionWithUserTypeFrontendParams {
    uslugaId: string,
    senderFormId: string,
    standartContentParams?: any
    senderRecipientType: "manager" | "client";
}

export class SenderActionDescription extends AbstractActionDescriptionWithUserType<ISenderCascadeBlockParam, ISenderActionFrontendParams> {
    get previewComponent(): Component {
        return SenderBlockPreview;
    }

    get formComponent(): Component {
        return SenderBlockForm;
    }

    get isReadyToUse(): boolean {
        return !!SenderUslugi[this.frontendParams.uslugaId];
    }
}

export class WebhookActionDescription extends ActionDescription<IWebhookCascadeBlockParam, IWebhookActionFrontendParams> {
    get previewComponent(): Component {
        return WebhookBlockPreview;
    }

    get formComponent(): Component {
        return WebhookBlockForm;
    }
}

export class OnlineChatRunExternalBotActionDescription extends ActionDescription<RunExternalBotActionModelInterface, RunExternalBotActionFrontendParamsInterface> {
    get previewComponent(): Component {
        return OnlineChatRunExternalBotBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatRunExternalBotForm;
    }
}

export class DiscountCouponActionDescription extends ActionDescription<DiscountCouponBlockParam, DiscountCouponActionFrontendParamsInterface> {

    get previewComponent(): Component {
        return DiscountCouponBlockPreview;
    }

    get formComponent(): Component {
        return DiscountCouponBlockForm;
    }
}

export interface IWebhookActionFrontendParams extends IActionDescriptionFrontendParams {
    methods: string[];
}

export interface DiscountCouponActionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    uslugi: {
        id: string,
        descr: string
    }[]
}

export class ContactbookGroupActionDescription extends ActionDescription<ActionBlockParamModelInterface, IContactbookGroupActionFrontendParams> {
    get formComponent(): Component {
        return ContactbookGroupBlockForm;
    }
}

interface IContactbookGroupActionFrontendParams extends IActionDescriptionFrontendParams {

}

export class GlobalVarsActionDescription extends ActionDescription<IGlobalVarsBlockParam> {
    get previewComponent(): Component {
        return GlobalVarsBlockPreview;
    }

    get formComponent(): Component {
        return GlobalVarsBlockForm;
    }
}


export class DelayActionDescription extends ActionDescription<IDelayCascadeBlockParam> {
    get previewComponent(): Component {
        return DelayBlockPreview;
    }

    get formComponent(): Component {
        return DelayBlockForm;
    }
}

export class NoteActionDescription extends ActionDescription<INoteCascadeBlockParam> {
    get previewComponent(): Component {
        return NoteBlockPreview;
    }

    get formComponent(): Component {
        return NoteBlockForm;
    }
}


interface IFilterActionFrontendParams extends IActionDescriptionFrontendParams {
    filters: IFiltersData;
    globalVarFilterSample: IFilter;
}

export class FilterActionDescription extends ActionDescription<IFilterCascadeBlockParam, IFilterActionFrontendParams> {
    get formComponent(): Component {
        return FilterBlockForm;
    }

    get outputComponent(): Component {
        return FilterBlockOutput;
    }

    getFilters(contentVariables?: IFlowChartContentVariable[]) {
        let result = ObjectHelper.jsonClone(this.frontendParams.filters);
        if (contentVariables) {
            let groupId = 'schemeGlobalVariables';
            let hasGlobalVar = false;
            for (let item of contentVariables) {
                if (item.isGlobalVar) {
                    hasGlobalVar = true;
                    let filter = ObjectHelper.jsonClone(this.frontendParams.globalVarFilterSample);
                    filter.group = groupId;
                    filter.id = item.id;
                    filter.descr = filter.descrForPreview = item.descr;
                    result.filters[item.id] = filter;
                }
            }
            if (hasGlobalVar) {
                result.groups = {
                    [groupId]: {
                        id: groupId,
                        descr: 'Глобальные переменные',
                        descrArray: ['Глобальные переменные']
                    },
                    ...result.groups
                };
            }
        }
        return result;
    }
}


export class CallActionDescription extends AbstractActionDescriptionWithUserType<ICallAlgoBlockParam> {
    get formComponent(): Component {
        return CallBlockForm;
    }
}


export class CabinetNotificationActionDescription extends AbstractActionDescriptionWithUserType<ICabinetNotificationParam> {
    get previewComponent(): Component {
        return CabinetNotificationBlockPreview;
    }

    get formComponent(): Component {
        return CabinetNotificationForm;
    }
}


export class SendNotificationToClientOnlineChatOperatorActionDescription extends AbstractActionDescriptionWithUserType<ISendNotificationToClientOnlineChatOperatorParam, SendNotificationToClientOnlineChatOperatorFrontendParamsInterface> {
    get previewComponent(): Component {
        return SendNotificationToClientOnlineChatOperatorBlockPreview;
    }

    get formComponent(): Component {
        return SendNotificationToClientOnlineChatOperatorForm;
    }
}

export class AbTestActionDescription extends ActionDescription<null> {

}

interface IOnlineChatMessageActionFrontendParams extends IActionDescriptionFrontendParams {
    isStandartContentAvailable: boolean
}

abstract class AbstractOnlineChatMessageActionDescription<Model, FrontendParams extends IOnlineChatMessageActionFrontendParams> extends ActionDescription<Model, FrontendParams> {
}

interface IOnlineChatMessageToOperatorActionFrontendParams extends IOnlineChatMessageActionFrontendParams {
    operators: IOption[],
    operatorsCount: IOption[],
    messageTypes: {
        id: string,
        descr: string;
        extraDescr: string;
    }[]
}

interface IOnlineChatMessageToOperatorFrontendParams extends IActionDescriptionFrontendParams {
    operators: IOption[];
    operatorTypes: IOption[];
}

interface SendNotificationToClientOnlineChatOperatorFrontendParamsInterface extends IActionDescriptionWithUserTypeFrontendParams {
    sites: {
        id: string,
        title: string
    }[]
}


interface IOnlineChatForwardClientToOperatorFrontendParams extends IActionDescriptionFrontendParams {
    operators: IOption[];
    operatorTypes: IOption[];
    defaultForwardTextForClient: string;
    forwardMessageContentVariables: ContentVariableInterface[];
}


export class OnlineChatMessageToOperatorActionDescription extends AbstractOnlineChatMessageActionDescription<IOnlineChatMessageToOperatorParam, IOnlineChatMessageToOperatorActionFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatMessageToOperatorBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatMessageToOperatorForm;
    }
}

export interface IOnlineChatMessageToClientFrontendParams extends IOnlineChatMessageToOperatorFrontendParams {
    fields: { name: string, descr: string }[];
    validationTypes: Dictionary<string>;
    actionsOnInvalidAnswer: Dictionary<string>;
    answerFilters: IFiltersData;
}

export class OnlineChatRequestRateActionDescription extends ActionDescription<IOnlineChatRequestRateParam> {
    get formComponent(): Component {
        return OnlineChatRequestRateForm;
    }
}


export class OnlineChatMessageToClientActionDescription extends ActionDescription<IOnlineChatMessageToClientParam, IOnlineChatMessageToClientFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatMessageToClientBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatMessageToClientForm;
    }

    get outputComponent(): Component {
        return OnlineChatMessageToClientBlockOutput;
    }

    isNeedOutputTimeByActionBlock(actionBlock: ActionBlock): boolean {
        let params: IOnlineChatMessageToClientParam = actionBlock.param as any;
        return params && !!params.waitingForClientAnswer;
    }

    isAnswerFiltersUsed(model: IOnlineChatMessageToClientParam): boolean {
        if (model.waitingForClientAnswer) {
            if (!model.useAnswerVariants) {
                if (model.answerFilters && ObjectHelper.hasKeys(model.answerFilters)) {
                    return true;
                }
            }
        }
        return false;
    }

    validateModel(model: IOnlineChatMessageToClientParam): string | null {
        if (typeof model.text == "string" && model.text.trim().length) {
            if (this.isAnswerFiltersUsed(model)) {
                if (model.actionOnValidateError == ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID) {
                    if (typeof model.messageOnValidateError != "string" || !model.messageOnValidateError.trim().length) {
                        return "Введите текст сообщения об ошибке проверки ответа";
                    }
                }
            }
        } else {
            return "Введите текст сообщения";
        }
        return null;

    }
}

export class OnlineChatForwardClientToOperatorActionDescription extends ActionDescription<IOnlineChatForwardClientToOperatorParam, IOnlineChatForwardClientToOperatorFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatForwardClientToOperatorBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatForwardClientToOperatorForm;
    }

    validateModel(model: IOnlineChatForwardClientToOperatorParam): string | null {
        if (!model.operators || !model.operators.length) {
            return "Не выбраны операторы";
        }
        return null;
    }
}


export class OnlineChatClientRedirectActionDescription extends ActionDescription<IOnlineChatRedirectParam> {
    get previewComponent(): Component {
        return OnlineChatRedirectBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatRedirectBlockForm;
    }
}

interface IOnlineChatClientOpenWidgetTabFrontendParams extends IActionDescriptionFrontendParams {
    tabs: {
        descr: string,
        id: string,
        type: string,
        form?: IForm
    }[];
    showTypes: {
        id: string,
        descr: string
    }[];
}

export class OnlineChatClientOpenWidgetTabActionDescription extends ActionDescription<IOnlineChatOpenWidgetTabParam, IOnlineChatClientOpenWidgetTabFrontendParams> {
    get previewComponent(): Component {
        return OnlineChatOpenWidgetTabBlockPreview;
    }

    get formComponent(): Component {
        return OnlineChatOpenWidgetTabBlockForm;
    }
}

interface IPhonebookActionFrontendParams extends IActionDescriptionFrontendParams {
    groups: IOption[];
}

export class PhonebookActionWithGroupActionDescription extends ActionDescription<IPhonebookWithGroupSelectParam, IPhonebookActionFrontendParams> {
    get previewComponent(): Component {
        return PhonebookWithGroupSelectBlockPreview;
    }

    get formComponent(): Component {
        return PhonebookWithGroupSelectForm;
    }
}

/*
export class CrmActionDescription extends ActionDescription {
    get formComponent(): Component {
        return CrmBlockForm;
    }
}
*/


interface IEventAsDescriptionFrontendParams extends IActionDescriptionFrontendParams {
    events: IEventAsDescriptionFrontendParamsEvent[]
}

export interface IEventAsDescriptionFrontendParamsEvent {
    eventDescriptionId: string;
    descr: string;
    platformId: string;
    authPrimaryId: string;
    eventIdentifiers: {
        id: string;
        descr: string;
    }[]
}

export class EventAsActionDescription extends ActionDescription<IEventAsActionParam, IEventAsDescriptionFrontendParams> {
    get previewComponent(): Component {
        return EventAsActionBlockPreview;
    }

    get formComponent(): Component {
        return EventAsActionBlockForm;
    }
}

export interface AbstractDataProviderActionDescriptionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    authDescr: string;
}

export abstract class AbstractDataProviderActionDescription<Model extends AbstractDataProviderActionParamInterface, FrontendParams extends AbstractDataProviderActionDescriptionFrontendParamsInterface = AbstractDataProviderActionDescriptionFrontendParamsInterface> extends ActionDescription<Model, FrontendParams> {
    get previewComponent(): Component {
        return AbtractDataProviderActionBlockPreview;
    }

    get formComponent(): Component {
        return AbtractDataProviderActionBlockForm;
    }

    get dataProviderActionFormComponent(): Component {
        return null;
    }

    get dataProviderActionPreviewComponent(): Component {
        return null;
    }

    protected getAbstractDefaultModelValues(): AbstractDataProviderActionParamInterface {
        return {
        }
    }

    public abstract getDefaultModelValues(): Model;
}


interface DataProviderEntitySaveActionDescriptionFrontendParamsInterface extends AbstractDataProviderActionDescriptionFrontendParamsInterface {
    entityClassId: string;
    actionModes: {
        found: DataProviderEntitySaveActionModeInterface[];
        notFound: DataProviderEntitySaveActionModeInterface[];
    },
    searchModes: DataProviderEntitySaveSearchModeInterface[];
    additionalConfigForm?: IForm,
    contentVariableMapperForAdditionalForm: ContentVariableInterface[];
    linkEntityTypes: DataProviderLinkEntityTypeInterface[],
    entityTypeDescr: string,
    currentDataProviderEntityFieldValueContentVariable: string
}

export interface DataProviderEntitySaveActionModeInterface {
    id: string;
    descr: string;
    isUpdate: boolean;

}

export interface DataProviderEntitySaveSearchModeInterface {
    id: string;
    descr: string;
    isDefault: boolean;
}

export interface DataProviderLinkEntityTypeInterface {
    id: string;
    descr: string;
    isLinkedByDefault: boolean;
    maximumLinksCount?: number;
    minimumLinksCount?: number;
    actionDescriptionId: string;
    additionalConfigForm?: IForm;
    searchModes: DataProviderEntitySaveSearchModeInterface[];
}

export class DataProviderEntitySaveActionDescription extends AbstractDataProviderActionDescription<DataProviderEntitySaveActionParamInterface, DataProviderEntitySaveActionDescriptionFrontendParamsInterface> {
    get dataProviderActionFormComponent(): Component {
        return DataProviderEntitySaveActionBlockForm;
    }

    get dataProviderActionPreviewComponent(): Component {
        return DataProviderEntitySaveActionBlockPreview;
    }

    getDefaultModelValues(linkType: DataProviderLinkEntityTypeInterface = null): DataProviderEntitySaveActionParamInterface {
        let searchModes = linkType ? linkType.searchModes : this.frontendParams.searchModes;
        return {
            ...super.getAbstractDefaultModelValues(),
            foundActionMode: this.frontendParams.actionModes.found[0].id,
            notFoundActionMode: this.frontendParams.actionModes.notFound[0].id,
            isAlwaysCreate: false,
            searchModes: searchModes
                .filter(item => item.isDefault)
                .map(item => item.id),
            fields: [],
            updateFilters: [],
            additionalConfigFormValues: {},
            links: this.frontendParams.linkEntityTypes
                .filter(type => type.isLinkedByDefault)
                .map(type => {
                    let result: DataProviderEntitySaveActionLinkInterface = {
                        id: Random.uid(),
                        entityTypeId: type.id,
                        value: undefined,
                        additionalConfigFormValues: {}
                    };
                    return result;
                })
        };
    }
}


interface CrmSendMessageActionDescriptionFrontendParamsInterface extends AbstractDataProviderActionDescriptionFrontendParamsInterface {
    isFromClient: boolean
}

export class CrmSendMessageActionDescription extends AbstractDataProviderActionDescription<CrmSendMessageActionParamInterface, CrmSendMessageActionDescriptionFrontendParamsInterface> {
    get dataProviderActionFormComponent(): Component {
        return CrmSendMessageActionBlockForm;
    }

    get dataProviderActionPreviewComponent(): Component {
        return null;
    }

    getDefaultModelValues(): CrmSendMessageActionParamInterface {
        return {
            ...super.getAbstractDefaultModelValues(),
            text: null,
            additionalParams: null
        };
    }
}


interface OrganizationTagActionDescriptionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    tagTypeId: string
}

export class ToggleTagActionDescription extends ActionDescription<ToggleTagActionParamInterface, OrganizationTagActionDescriptionFrontendParamsInterface> {
    get formComponent(): Component {
        return ToggleTagActionBlockForm;
    }

    get previewComponent(): Component {
        return ToggleTagActionBlockPreview;
    }
}


export interface RunExternalBotActionFrontendParamsInterface extends IActionDescriptionFrontendParams {
    platformTypeId: string;
    lastClientMessageVarName: string;
    customBotPlatformId: string;
    webhook?: {
        platformId: string;
        authPrimaryId: string;
        webhookId: string;
    };
}