import Vue from "vue";

import MyFilterValuesItem from "~/cabinet/vue/interface/filter/MyFilterValuesItem.vue";

export default class InitMyComponents
{
    public static make() {
        Vue.component("my-filter-values-item", MyFilterValuesItem);
        Vue.component("chart", async () => {
            return new Promise(resolve => {
                import(/* webpackChunkName: "highcharts" */ '~/cabinet/vue/interface/charts/Chart.vue').then(result => {
                    resolve((result as any).default);
                });
            })
        });
    }
}