import IPhonebookContactTableColumnFormatter
    from "~/cabinet/vue/client/phonebook/contacts/columnFormatters/IPhonebookContactTableColumnFormatter";
import {__} from "~/ts/library/Translate";

export default class SexFormatter implements IPhonebookContactTableColumnFormatter {
    format(value: any): any {
        if (value == 0) {
            value = null;
        } else if (value) {
            value = value == 1 ? __("М") : __("Ж");
        }
        return value;
    }
}