import {__} from "~/ts/library/Translate";
import {MessageBoxData} from "~/node_modules/element-ui/types/message-box";

export interface IShowMessageOptions {
    message?: string;
    type?: "success" | "warning" | "error";
    title?: string;
    dangerouslyUseHTMLString?: boolean;
}

let ShowMessagePlugin = {
    install: function (Vue: any) {
        Vue.prototype.$showMessage = (options: IShowMessageOptions) => {
            if (!options.title) {
                if (options.type == "success") {
                    options.title = __("Сообщение системы");
                } else if (options.type == "warning") {
                    options.title = __("Внимание");
                } else {
                    options.title = __("Ошибка");
                }
            }
            if (!options.type) {
                options.type = "success";
            }
            let message = options.message;

            return Vue.prototype.$alert(message, options.title, {
                confirmButtonText: __('Закрыть'),
                customClass: "show-message-" + options.type,
                closeOnClickModal: true,
                closeOnPressEscape: true,
                dangerouslyUseHTMLString: !!options.dangerouslyUseHTMLString
            }) as Promise<MessageBoxData>;
        };
    }
};


export default ShowMessagePlugin;
