import SenderMessageInterface from "~/cabinet/vue/client/sender/report/allMessages/SenderMessageInterface";
import {PropType} from "vue";

export function useSenderMessageContentProps<Message extends SenderMessageInterface>() {
    return {
        message: {
            type: Object as PropType<Message>,
            required: true
        },
        uslugaId: {
            type: String
        },
        inReports: {
            type: Boolean
        },
        dealer: {
            type: Boolean
        }
    }
}