import {Vue} from "~/node_modules/vue/types/vue";
import {ComponentOptions} from "~/node_modules/vue";
import Currency from "~/cabinet/ts/service/Currency";
import DealerRoutesBuilder from "~/cabinet/ts/routes/DealerRoutesBuilder";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";


export const CabinetUiHelpersMixin: ComponentOptions<Vue> = {
    computed: {

        $tableMaxHeight: function () {
            return this.$isMobileWindowSize ? null : 500;
        },
        $expandColumnWidth: function () {
            return this.$isMobileWindowSize ? "18px" : "48px";
        },
        $access: function () {
            return AccountStore.access.value;
        }
    },
    methods: {
        $formatPrice: (price: number, currencyId: string) => Currency.formatPrice(price, currencyId),
        $getOrgCardRoute: (ocode: string) => DealerRoutesBuilder.getOrgCardRoute(ocode),
    }
};