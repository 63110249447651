
import Vue from 'vue'

export default Vue.extend({
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            prefixCls: 'vel'
        }
    }
})
