export function makeFilteredList<T>(allItems: T[], visibleItemsList: T[], isVisible: (item: T) => boolean, sorted: boolean = false): T[] {
    let result = allItems.filter(item => {
        let result = isVisible(item);
        let indexOf = visibleItemsList.indexOf(item)
        if (result != (indexOf > -1)) {
            if (result) {
                visibleItemsList.push(item);
            } else {
                visibleItemsList.splice(indexOf, 1);
            }
        }

        return result;
    });
    for (let i = visibleItemsList.length - 1; i >= 0; i--) {
        if (!result.includes(visibleItemsList[i])) {
            visibleItemsList.splice(i, 1);
        }
    }
    if (sorted) {
        visibleItemsList.sort((a: any, b: any) => {
            return result.indexOf(a) > result.indexOf(b) ? 1 : -1;
        });
    }
    return result;
}

let FilteredListPlugin = {
    install: function (Vue: any) {
        //Vue.prototype.$lcab = LcabApiRequest.make;
        Vue.prototype.$makeFilteredList = makeFilteredList;
    }
};

export default FilteredListPlugin;
