export interface BlogArticleInterface {
    id: number;
    title: string;
    alias: string;
    dateTimeCreate: string;
    dealerNetTypeId: string;
    clientTypeId: string;
    isPopupEnabled: boolean;
    isOnlyInCabinet: boolean;
    isPublished: boolean;
    content: {
        typeId: string;
        html: string;
        constructor: any;
        plainText: string;
    };
    dealerDomains: string[];
    uslugi: string[];
    dateTimePublish: string;
    dateTimePublishEnabled: boolean;
    categoryId: string;
    fileManagerId: string;
    coverImage: string;
    seoTitle: string;
    seoDescription: string;
}

export interface BlogArticleCategoryInterface {
    id: string;
    descr: string;
}

export const ARTICLE_CONTENT_TYPE_HTML = "html";
export const ARTICLE_CONTENT_TYPE_CONSTRUCTOR = "constructor";

export type ArticleContentTypeId = typeof ARTICLE_CONTENT_TYPE_HTML | typeof ARTICLE_CONTENT_TYPE_CONSTRUCTOR;