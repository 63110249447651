import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import IImageBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/image/IImageBlockValues";
import ImageDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/image/ImageDisplay.vue";
import ImageForm from "~/cabinet/vue/interface/htmlConstructor/blocks/image/ImageForm.vue";
import {Component} from "vue";


export default class ImageBlockType extends AbstractBlockType<IImageBlockValues, any> {

    get displayComponent(): Component {
        return ImageDisplay;
    }

    get formComponent(): Component {
        return ImageForm;
    }


}
