import {PropType} from "vue";
import {IPricePart} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/Interface";
import {useTarifPriceProps} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/useTarifPrice";
import {AbstractTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";


export function usePagerPriceProps<Price extends AbstractTarifPrice>() {
    return {
        ...useTarifPriceProps(),
        value: {
            type: Object as PropType<Price>,
            required: true
        },
        priceParts: {
            type: Array as PropType<IPricePart[]>,
            required: true
        }
    }
}