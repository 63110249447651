import Dictionary from "~/ts/library/Dictionary";
import {CollectContactFields, IconStyle} from "~/chat/ts/data/ISiteParams";
import ChatFormButtonColorInterface from "~/chat/vue/widget/form/fields/ChatFormButtonColorInterface";

export const TAB_TYPE_CHAT = "chat";
export const TAB_TYPE_REVIEWS = "reviews";
export const TAB_TYPE_FORM = "form";
export const TAB_TYPE_HTML = "html";
export const TAB_TYPE_PAYMENT = "payment";



export interface IWidgetTabParams {
    content?: Dictionary<any>,
    hideWhenOffline?: 0 | 1,
    icon?: string,
    iconColor?: string,
    iconColor2?: string,
    iconStyle?: IconStyle,
    id: string,
    title: string,
    subTitle?: string,
    type: string,
    visible: 0 | 1,
    weight: number,
    virtual?: boolean
    stepped?: boolean;
    changeWidgetSize?: {
        enabled: boolean,
        width?: number,
        height?: number
    },
    formButtonColor?: ChatFormButtonColorInterface
}

export const SUM_TYPE_CLIENT = "client";
export const SUM_TYPE_FIXED = "fixed";
export const SUM_TYPE_LIST = "list";
export const SUM_TYPE_OPERATOR = "operator";

export interface IWidgetPaymentTabContent {
    html: string,
    form: CollectContactFields,
    button: string,
    sumType: "client" | "fixed" | "list" | "operator",
    sumFixed?: number,
    paymentSystems: string[],
    html_footer: string,
    currency: {
        id: string,
        descr: string,
        decimal_point: string
        short_descr: string,
        icon: string
    },
    sumList: [number, string][],
    paymentTypes: Dictionary<string[]>
}