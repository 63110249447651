var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"core-ui-avatar",style:({
        background: _vm.background ? _vm.background : '#bdc3c7',
        width: _vm.size,
        height: _vm.size,
        minWidth: _vm.size
    })},[(_setup.letters || _vm.icon || _vm.platform)?_c('span',{staticClass:"letters",style:({fontSize: _vm.size})},[_c('span',[(_vm.platform)?_c(_setup.OperatorApplicationPlatformIcon,{staticClass:"m-0",attrs:{"platform":_vm.platform,"with-color":false}}):(_vm.icon)?_c(_setup.FontAwesomeIcon,{staticClass:"m-0",attrs:{"icon":_vm.icon}}):[_vm._v("\n                 "+_vm._s(_setup.letters)+"\n            ")]],2)]):(_setup.preparedSrc)?_c('img',{attrs:{"src":_setup.preparedSrc}}):_vm._t("default"),_vm._v(" "),(_vm.$slots.badge)?_c('span',{staticClass:"badge status",on:{"mouseover":function($event){$event.stopPropagation();},"mouseenter":function($event){$event.stopPropagation();}}},[_c('el-tooltip',{attrs:{"content":_vm.badgeTooltip}},[_c('span',[_c('span',{staticClass:"badge-value"},[_vm._t("badge")],2)])])],1):_vm._e(),_vm._v(" "),(_vm.statusColor || _vm.statusColorClass)?_c('span',{class:{
            status: true,
            'custom-icon': !!_vm.statusIcon,
            [`text-${_vm.statusColorClass}`]: !!_vm.statusColorClass
        },style:({
            color: _vm.statusColor ? `${_vm.statusColor} !important` : null,
            fontSize: _vm.size
        })},[_c('span',[(_vm.statusIcon)?_c('i',{class:_vm.$icon(_vm.statusIcon)}):_c('span',{staticClass:"status-circle"})])]):_vm._e(),_vm._v(" "),_vm._t("append")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }