import AbstractAnalyticCounter from "~/cabinet/ts/service/analytics/AbstractAnalyticCounter";
import Dictionary from "~/ts/library/Dictionary";

export default class YandexMetrika extends AbstractAnalyticCounter<YandexInterface> {
    public getCounterObject(): YandexInterface | null {
        let yandex: YandexInterface = (window as any).Ya?._metrika?.counter;
        if (yandex?.reachGoal) {
            return yandex;
        }
        return null;
    }

    protected async reachGoalMake(counter: YandexInterface, eventId: string, sum: number | undefined, currencyId: string | undefined): Promise<void> {
        let params = sum && currencyId ? {
            order_price: sum,
            currency: currencyId
        } : null;
        counter.reachGoal(eventId, params);
    }

    protected async setUserParamsMake(counter: YandexInterface, userId: string, otherParams: Dictionary<any>): Promise<void> {
        counter.userParams({
            UserID: userId,
            ...otherParams
        });
    }
}

interface YandexInterface {
    reachGoal: (action: string, params?: any) => void;
    getClientID: () => any;
    userParams: (params: any) => void;
}