import IPhonebookContactTableColumnFormatter
    from "~/cabinet/vue/client/phonebook/contacts/columnFormatters/IPhonebookContactTableColumnFormatter";
import DateHelper from "~/ts/library/Date";

export default class DateFormatter implements IPhonebookContactTableColumnFormatter {
    format(value: any) {
        if (value == "0000-00-00") {
            value = null;
        } else {
            try {
                value = (new DateHelper(value)).dateObject.toLocaleDateString();
            } catch (e) {
                value = null;
            }
        }
        return value;
    }

}