import {PHONEBOOK_COLUMN_TYPE_DATE, PHONEBOOK_COLUMN_TYPE_SEX} from "~/cabinet/vue/client/phonebook/contacts/constants";
import SexFormatter from "~/cabinet/vue/client/phonebook/contacts/columnFormatters/SexFormatter";
import Dictionary from "~/ts/library/Dictionary";
import IPhonebookContactTableColumnFormatter
    from "~/cabinet/vue/client/phonebook/contacts/columnFormatters/IPhonebookContactTableColumnFormatter";
import IPhonebookContactColumn from "~/cabinet/vue/client/phonebook/contacts/interfaces";
import DateFormatter from "~/cabinet/vue/client/phonebook/contacts/columnFormatters/DateFormatter";

let formatters: Dictionary<IPhonebookContactTableColumnFormatter> = {
    [PHONEBOOK_COLUMN_TYPE_SEX]: new SexFormatter(),
    [PHONEBOOK_COLUMN_TYPE_DATE]: new DateFormatter()
};

export default class PhonebookContactTableColumnFormatter {
    public static format(column: IPhonebookContactColumn, value: any) {
        let formatter = formatters[column.type];
        if (formatter != null) {
            value = formatter.format(value);
        }
        return value;
    }
}