import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import ActionDescription from "~/cabinet/ts/data/events/ActionDescription";
import PlatformEvent from "~/cabinet/ts/data/events/PlatformEvent";
import EventPlatformAuth from "~/cabinet/ts/data/events/EventPlatformAuth";

export default class EventPlatform extends AbstractEntity {
    public id: string;
    public descr: string;
    public icon: string;
    public color: string;
    //public types: EventType[];
    //public events: PlatformEvent[];
    public authList: EventPlatformAuth[]

    protected getFields(): AbstractEntityFields {
        return {
            blockTypes: ActionDescription,
            events: PlatformEvent,
            authList: EventPlatformAuth
        };
    }

    protected isFreezeObject(): boolean {
        return true;
    }
}