import UslugaRouteBuilder from "~/cabinet/ts/routes/UslugaRouteBuilder";
import {RouteConfig} from "vue-router";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {ACCESS_AGENT} from "~/cabinet/ts/data/organization/IUserAccess";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";

export const AGENT_ROUTE = `AGENT`;

export default class AgentRouteBuilder extends UslugaRouteBuilder {
    protected makeRoute(): RouteConfig {
        const mainRoute = AGENT_ROUTE + "_main";
        const clientsRoute = AGENT_ROUTE + "_clients";
        const rewardReportRoute = AGENT_ROUTE + "_rewardReportRoute";
        const handlerTypeRoute = AGENT_ROUTE + "_types";

        let mainRouteConfig = {
            name: mainRoute,
            path: "main",
            component: () => import(/* webpackChunkName: "client-agent" */ '~/cabinet/vue/client/agent/AgentMain.vue')
        };

        return {
            name: AGENT_ROUTE,
            path: "/agent",
            children: [
                {
                    name: "agentJoin",
                    path: "join",
                    meta: {
                        access: () => !AccountStore.hasAccess(ACCESS_AGENT)
                    } as IRouteMeta,
                    component: () => import(/* webpackChunkName: "client-agent" */ '~/cabinet/vue/client/agent/JoinToAgent.vue')
                },
                {
                    name: "agentDashboard",
                    path: "dashboard",
                    meta: {
                        access: () => AccountStore.hasAccess(ACCESS_AGENT),
                        menu: [
                            new MenuItem(mainRoute, "Обзор", "tachometer"),
                            new MenuItem(clientsRoute, "Клиенты", "users"),
                            new MenuItem(rewardReportRoute, "Вывод вознаграждений", "rocket"),
                            () => {
                                let result: MenuItem[] = [];
                                for (let type of AccountStore.data.value.agentHandlerTypes) {
                                    result.push(
                                        (new MenuItem(handlerTypeRoute, type.descr, type.icon))
                                            .addRouteParam('typeId', type.id)
                                    );
                                }
                                return result;
                            }
                        ],
                        redirectToChildren: [
                            mainRouteConfig
                        ]
                    } as IRouteMeta,
                    component: () => import(/* webpackChunkName: "client-agent" */ '~/cabinet/vue/client/agent/AgentWrapper.vue'),
                    children: [
                        mainRouteConfig,
                        {
                            name: rewardReportRoute,
                            path: "rewardReport",
                            component: () => import(/* webpackChunkName: "client-agent" */ '~/cabinet/vue/client/agent/AgentRewardsReport.vue')
                        },
                        {
                            name: clientsRoute,
                            path: "clients",
                            component: () => import(/* webpackChunkName: "client-agent" */ '~/cabinet/vue/client/agent/AgentClients.vue')
                        },
                        {
                            name: handlerTypeRoute,
                            path: "tasks/:typeId",
                            component: () => import(/* webpackChunkName: "client-agent" */ '~/cabinet/vue/client/agent/AgentHandlerEntities.vue'),
                            props: route => ({
                                type: AccountStore.data.value.agentHandlerTypes.find(item => item.id == route.params.typeId)
                            })
                        }
                    ]
                }
            ]
        };
    }

}