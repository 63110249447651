import {useFilterComponentProps} from "~/cabinet/vue/interface/filter/types/useFilterComponent";
import {computed, ExtractPropTypes, onMounted, PropType, ref, watch} from "vue";
import {ICombinedFilterPart, IFilterOption, IValueComponent} from "~/cabinet/vue/interface/filter/Interfaces";
import CombinedFilterValue from "~/cabinet/vue/interface/filter/types/CombinedFilterValue";
import MyFilterHelper from "~/cabinet/vue/interface/filter/MyFilterHelper";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";

export function useFilterWithOptionsComponentProps() {
    return {
        ...useFilterComponentProps(),
        value: {type: [Array, Number, String] as PropType<any[] | number | string>}
    }
}

export function useFilterWithOptionsComponent(props: Readonly<ExtractPropTypes<ReturnType<typeof useFilterWithOptionsComponentProps>>>) {
    let optionsList = ref<IFilterOption[]>([]);

    let loading = ref(false);
    let loadingId = ref<string>();

    let combinedFilterValuePrev = ref<CombinedFilterValue>();

    async function prepareOptions() {
        let component: IValueComponent = props.component;
        let result = await MyFilterHelper.getFilterOptions(component.filter, props.combinedFilterValue);
        options.value = result.options;
        props.component.filter.placeholder = result.placeholder;
    }

    let optionGroups = computed(() => {
        let result: string[] = [];
        options.value.forEach(value => {
            if (result.indexOf(value.groupDescr) === -1) {
                result.push(value.groupDescr);
            }
        });
        return result;
    });

    let optionsByGroups = computed(() => {
        let result: Dictionary<IFilterOption[]> = {};
        optionGroups.value.forEach((groupDescr?: string) => {
            let _options: IFilterOption[] = [];
            options.value.forEach(option => {
                if (option.groupDescr == groupDescr) {
                    _options.push(option);
                }
            });

            if (!groupDescr) {
                groupDescr = "";
            }
            result[groupDescr] = _options;
        });
        return result;
    });

    let options = computed<IFilterOption[]>({
        get: () => {
            return optionsList.value;
        },
        set: (value: IFilterOption[]) => {
            optionsList.value = value;
        }
    });

    watch(computed(() => props.component), () => prepareOptions());

    onMounted(() => {
        rememberCombineFilterValue();
        prepareOptions();
    });

    let parentId = computed(() => {
        return (props.component.filter as ICombinedFilterPart).parentFilterId;
    });

    function rememberCombineFilterValue() {
        if (parentId.value) {
            combinedFilterValuePrev.value = ObjectHelper.jsonClone(props.combinedFilterValue);
        }
    }

    watch(computed(() => props.combinedFilterValue), (newValue: CombinedFilterValue) => {
        if (parentId.value) {
            let oldValue = combinedFilterValuePrev.value;
            if (!newValue || !oldValue || newValue[parentId.value] != oldValue[parentId.value]) {
                prepareOptions();
            }
            rememberCombineFilterValue();
        }
    }, {deep: true});

    return {
        loading,
        loadingId,
        options,
        optionGroups,
        optionsByGroups,
        optionsList
    }
}