import StringHelper from "~/ts/library/StringHelper";
import {__} from "~/ts/library/Translate";
import {MessageBoxData} from "element-ui/types/message-box";
import {AbstractApiResult, ShowApiResultMessageOptionsInterface} from "~/core-ui/ts/request/AbstractApiResult";

function makeLcabApiResult(result: any): LcabApiResult<any> {
    return new LcabApiResult(result);
}

interface ResponseInterface {
    code: number,
    descr: string,
    data: any
}

class LcabApiResult<T> extends AbstractApiResult<T, ResponseInterface> {
    protected getDataFromResponse(response: ResponseInterface): any {
        return response.data;
    }

    public get isSuccess(): boolean {
        return this.code == 1;
    }

    public get code(): number {
        return parseInt(this.getParam("code", 0));
    }

    public get descr(): string {
        let descr = this.getParam("descr", "");
        return descr ? descr.toString() : "";
    }

    protected get showMessageText(): string {
        let resultList = this.getData("resultList")
        let descr = this.descr;
        if (!this.isSuccess && resultList != null) {
            descr = `${__('Ошибки')}:
            ${LcabApiResult.getResultListLi(resultList, false)}`;

            if (this.getData("success")) {
                descr += `
${__('Успешно завершено')}:
                 ${LcabApiResult.getResultListLi(resultList, true)}`;
            }
        }
        return descr;
    }

    private static getResultListLi(resultList: any, success: boolean) {
        let html = "";
        for (let item of resultList) {
            let result = makeLcabApiResult(item.result);
            if (result.isSuccess == success) {
                html += `● ${item.descr}${!success ? ': ' + StringHelper.htmlEntities(result.descr) : ''}\n`;
            }
        }
        return html;
    }

    private showMessageOptions: ShowApiResultMessageOptionsInterface;

    public showMessage(options?: ShowApiResultMessageOptionsInterface): Promise<MessageBoxData> {
        if (!options) {
            options = {};
        }
        this.showMessageOptions = options;
        return super.showMessage(options);
    }
}

export default LcabApiResult;