import AbstractEntity, {AbstractEntityBuilder, AbstractEntityFields} from "~/ts/library/AbstractEntity";

export default abstract class AbstractReactiveListEntity<T> extends AbstractEntity implements ReactiveListEntityInterface<T> {
    protected abstract get itemClass(): AbstractEntityBuilder<T>;

    items: T[];
    page: number;
    onPage: number;
    onPageDisabled: boolean;
    count: number;

    protected getFields(): AbstractEntityFields {
        return {
            items: this.itemClass
        };
    }

    public static newClass<T>(itemClass: AbstractEntityBuilder<T>): AbstractEntityBuilder<AbstractReactiveListEntity<T>> {
        return class extends AbstractReactiveListEntity<T> {
            protected get itemClass(): AbstractEntityBuilder<T> {
                return itemClass;
            }
        }
    }
}

export interface ReactiveListEntityInterface<T, AdditionalResponseData = any> {
    items: T[];
    page: number;
    onPage: number;
    onPageDisabled: boolean;
    count: number;
}

export function getReactiveListEntityByItems<T>(items: T[], page: number, onPage: number): ReactiveListEntityInterface<T> {
    let itemsForResult: T[] = [];
    let start = page * onPage
    for (let i = start; i < start + onPage; i++) {
        let item = items[i];
        if (item) {
            itemsForResult.push(item);
        }
    }
    return {
        count: items.length,
        onPage: onPage,
        page: page,
        onPageDisabled: false,
        items: itemsForResult
    }
}