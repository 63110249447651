import {
    ContentVariableGroupInterface,
    ContentVariableInterface
} from "~/cabinet/vue/interface/ContentVariableInterface";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";

export default class ContentVariablesHelper {
    public static contentVariablesToGroups(variables: ContentVariableInterface[]): ContentVariableGroupInterface[] {
        let groupsDictionary: Dictionary<ContentVariableGroupInterface> = {};
        for (let variable of variables) {
            let groupDescr = variable.groupDescr ? variable.groupDescr : "Поля";
            if (!groupsDictionary[groupDescr]) {
                groupsDictionary[groupDescr] = {
                    descr: groupDescr,
                    variables: []
                };
            }
            groupsDictionary[groupDescr].variables.push(variable);
        }
        return ObjectHelper.arrayFromDictionary(groupsDictionary);
    }

    public static getVariableNameFromPlaceholder(string: string) {
        return string.substring(1, string.length - 1);
    }
}