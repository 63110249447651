import Vue from "vue";


import Button from "element-ui/lib/button.js";
import ButtonGroup from "element-ui/lib/button-group.js";
import Input from "element-ui/lib/input.js";
import InputNumber from "element-ui/lib/input-number.js";
import Form from "element-ui/lib/form.js";
import FormItem from "element-ui/lib/form-item.js";
import Row from "element-ui/lib/row.js";
import Col from "element-ui/lib/col.js";
import Card from "element-ui/lib/card.js";
import MessageBox from 'element-ui/lib/message-box.js';
import Message from 'element-ui/lib/message.js';
import Loading from 'element-ui/lib/loading.js';
import Notification from 'element-ui/lib/notification.js';
import Select from 'element-ui/lib/select.js';
import AutoComplete from 'element-ui/lib/autocomplete.js';
import Option from 'element-ui/lib/option.js';
import OptionGroup from 'element-ui/lib/option-group.js';
import Tag from 'element-ui/lib/tag.js';
import Dropdown from "element-ui/lib/dropdown.js";
import DropdownItem from "element-ui/lib/dropdown-item.js";
import DropdownMenu from "element-ui/lib/dropdown-menu.js";
import Table from "element-ui/lib/table.js";
import TableColumn from "element-ui/lib/table-column.js";
import Collapse from "element-ui/lib/collapse.js";
import CollapseItem from "element-ui/lib/collapse-item.js";
import Tabs from "element-ui/lib/tabs.js";
import TabPane from "element-ui/lib/tab-pane.js";
import DatePicker from "element-ui/lib/date-picker.js";
import Pagination from "element-ui/lib/pagination.js";
import Badge from "element-ui/lib/badge.js";
import Switch from "element-ui/lib/switch.js";
import Dialog from "element-ui/lib/dialog.js";
import Tooltip from "element-ui/lib/tooltip.js";
import Upload from "element-ui/lib/upload.js";
import Alert from "element-ui/lib/alert.js";
import Popover from "element-ui/lib/popover.js";
import Slider from "element-ui/lib/slider.js";
import Radio from "element-ui/lib/radio.js";
import RadioButton from "element-ui/lib/radio-button.js";
import RadioGroup from "element-ui/lib/radio-group.js";
import Checkbox from "element-ui/lib/checkbox.js";
import TimePicker from "element-ui/lib/time-picker.js";
import TimeLine from "element-ui/lib/timeline.js";
import TimeLineItem from "element-ui/lib/timeline-item.js";
import ColorPicker from "element-ui/lib/color-picker.js";
import Progress from "element-ui/lib/progress.js";
import BackTop from "element-ui/lib/backtop.js";
import Steps from "element-ui/lib/steps.js";
import Step from "element-ui/lib/step.js";
import Tree from "element-ui/lib/tree.js";
import Breadcrumb from "element-ui/lib/breadcrumb.js";
import BreadcrumbItem from "element-ui/lib/breadcrumb-item.js";
import Carousel from "element-ui/lib/carousel.js";
import CarouselItem from "element-ui/lib/carousel-item.js";
import Cascader from "element-ui/lib/cascader.js";
import CascaderPanel from "element-ui/lib/cascader-panel.js";

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition.js';


import lang from 'build-locale';
import locale from 'element-ui/lib/locale/index.js'
import LcabWindowHelper from "~/ts/library/LcabWindowHelper";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import {CheckboxGroup} from "element-ui";
import ElConfirm from "~/core-ui/vue/ui/ElConfirm";
import ElPrompt from "~/core-ui/vue/ui/ElPrompt";

import Verte from '~/core-ui/vue/ui/verte/components/Verte.vue';
// register component globally
Vue.component(Verte.name, Verte);


// configure language
locale.use(lang);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = ElConfirm;
Vue.prototype.$prompt = ElPrompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

export const LoadingService = Loading.service;

let MyForm = Vue.extend(Form).extend({
    props: {
        labelPosition: {
            type: String,
            default: () => {
                return LcabWindowHelper.isMobile() ? "top" : "left";
            }
        }
    }
});

let MyDatePicker = Vue.extend(DatePicker).extend({
    props: {
        type: {
            type: String,
            default: "date"
        },
        format: {
            type: String,
            default: "dd.MM.yyyy"
        },
        valueFormat: {
            type: String,
            default: "yyyy-MM-dd"
        },
        onlyFuture: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        pickerOptions: {
            type: Object,
            default: () => ({
                firstDayOfWeek: 1
            })
        }
    }
});

let MyTimePicker = Vue.extend(TimePicker).extend({
    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: "HH:mm:ss"
        },
        valueFormat: {
            type: String,
            default: "HH:mm:ss"
        },
    }
});

let MyColorPicker = Vue.extend(ColorPicker).extend({
    name: "MyColorPicker",
    created(): void {
        this.$on("active-change", (color: string) => this.$emit("input", color));
    }
});

let MyDateTimePicker = MyDatePicker.extend({
    props: {
        type: {
            type: String,
            default: "datetime"
        },
        format: {
            type: String,
            default: "dd.MM.yyyy HH:mm:ss"
        },
        valueFormat: {
            type: String,
            default: "yyyy-MM-dd HH:mm:ss"
        },
    }
});

let ExtendedDialog = Vue.extend(Dialog).extend({
    props: {
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        closeOnPressEscape: {
            type: Boolean,
            default: false
        },
        modalAppendToBody: {
            type: Boolean,
            default: true
        },
        appendToBody: {
            type: Boolean,
            default: true
        }
    }
} as any);

let MyUpload = Vue.extend(Upload).extend({
    props: {
        headers: {
            type: Object,
            default: () => ({
                'X-Csrf-Token': CsrfToken.get()
            })
        }
    },
    /*
    methods: {
        handleClick: () => {
            console.log("FOOOOO");
        }
    },
    mounted() {
        if (Device.ios()) {
            let $el = this.$el;
            $el.addEventListener("click", function (e: MouseEvent) {
                if (!(e.target as HTMLElement).matches("input[type=file]")) {
                    let input: HTMLElement = $el.querySelector("input[type=file]");
                    if (input) {
                        input.focus();
                        input.click();
                    }
                }
            });
        }
    }*/
});

let MyInput = Vue.extend(Input).extend({
    methods: {

        resizeTextarea() {
            if (this.autosize) {
                if (this.autosize.maxRows) {
                    if (!this.autosizeRunOnce) {
                        this.constructor.super.options.methods.resizeTextarea.call(this);
                        this.autosizeRunOnce = true;
                    }
                    if (this.$refs.textarea) {
                        this.$nextTick(() => {
                            let textarea = this.$refs.textarea;
                            if (textarea) {
                                let parentElement = textarea.parentElement;
                                if (parentElement) {
                                    parentElement.style.height = textarea.style.height - 2;
                                }
                                textarea.style.height = '0px';
                                textarea.style.height = `${textarea.scrollHeight + 3}px`;
                                textarea.style.maxHeight = `${this.autosize.maxRows * 1.7}em`;
                                if (parentElement) {
                                    parentElement.style.height = '';
                                }
                            }
                        })

                    }
                }
            }
        },
    }
})

export default class InitElemeUI {
    public static make() {
        Vue.component("el-cascader", Cascader);
        Vue.component("el-cascader-panel", CascaderPanel);
        Vue.component("el-button", Button);
        Vue.component("el-button-group", ButtonGroup);
        Vue.component("el-input", MyInput);
        Vue.component("el-input-number", InputNumber);
        Vue.component('el-autocomplete', AutoComplete);
        Vue.component("el-form", MyForm);
        Vue.component("el-form-item", FormItem);
        Vue.component("el-row", Row);
        Vue.component("el-col", Col);
        Vue.component("el-card", Card);
        Vue.component("el-tree", Tree);
        Vue.component("el-select", Select);
        Vue.component("el-option", Option);
        Vue.component("el-option-group", OptionGroup);
        Vue.component("el-tag", Tag);
        Vue.component("el-dropdown", Dropdown);
        Vue.component("el-dropdown-menu", DropdownMenu);
        Vue.component("el-dropdown-item", DropdownItem);
        Vue.component("el-table", Table);
        Vue.component("el-table-column", TableColumn);
        Vue.component("el-collapse", Collapse);
        Vue.component("el-collapse-item", CollapseItem);
        Vue.component("el-tabs", Tabs);
        Vue.component("el-tab-pane", TabPane);
        Vue.component("el-date-picker", DatePicker);
        Vue.component("el-pagination", Pagination);
        Vue.component("el-badge", Badge);
        Vue.component("el-dialog", ExtendedDialog);
        Vue.component("el-switch", Switch);
        Vue.component("el-upload", MyUpload);
        Vue.component("el-tooltip", Tooltip);
        Vue.component("el-alert", Alert);
        Vue.component("el-slider", Slider);
        Vue.component("el-popover", Popover);
        Vue.component("el-radio", Radio);
        Vue.component("el-radio-button", RadioButton);
        Vue.component("el-radio-group", RadioGroup);
        Vue.component("el-checkbox", Checkbox);
        Vue.component("el-checkbox-group", CheckboxGroup);

        Vue.component("el-time-picker", TimePicker);
        Vue.component("el-timeline", TimeLine);
        Vue.component("el-timeline-item", TimeLineItem);
        Vue.component("el-color-picker", ColorPicker);
        Vue.component("el-progress", Progress);
        Vue.component("el-backtop", BackTop);
        Vue.component("el-steps", Steps);
        Vue.component("el-step", Step);
        Vue.component("el-breadcrumb", Breadcrumb);
        Vue.component("el-breadcrumb-item", BreadcrumbItem);
        Vue.component("el-carousel", Carousel);
        Vue.component("el-carousel-item", CarouselItem);


        Vue.component("my-datepicker", MyDatePicker);
        Vue.component("my-date-time-picker", MyDateTimePicker);
        Vue.component("my-time-picker", MyTimePicker);
        Vue.component("my-color-picker", MyColorPicker);
        Vue.component(CollapseTransition.name, CollapseTransition)
        Vue.use(Loading);


    }

}


document.addEventListener("submit", e => {
    let target = e.target as HTMLElement;
    if (target.classList && target.classList.contains("el-form")) {
        e.preventDefault();
    }
});