export interface BlogArticleInterface {
    id: number;
    title: string;
    text: string;
    textPreview: string;
    dateTime: string;
    isForAll: boolean;
    coverImageSrc: string;
    category?: {
        alias: string;
        descr: string;
    }
}

export const CATEGORIES_ALL_ALIAS = "all";