import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import {PropType} from "vue";
import MyFilterValueComponent from "~/cabinet/vue/interface/filter/MyFilterValueComponent";

export function useFilterComponentProps() {
    return {
        filtersData: {
            type: Object as PropType<IFiltersData>
        },
        component: {
            type: Object as PropType<MyFilterValueComponent>
        },
        components: {
            type: Array as PropType<MyFilterValueComponent[]>
        },
        combinedFilterValue: {
            type: Object
        }
    }
}

export interface FilterComponentExpose {
    focus?: () => void
}