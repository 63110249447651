import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import Auth from "~/cabinet/ts/data/integrations/Auth";

export default class AuthListByPlatformTypeResponse extends AbstractEntity {
    platforms: Platform[];
    authList: Auth[];

    protected getFields(): AbstractEntityFields {
        return {
            platforms: Platform,
            authList: Auth
        }
    }
}