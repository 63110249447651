import {SenderSourceInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import IMyTimeSelectorValue from "~/cabinet/vue/interface/form/IMyTimeSelectorValue";
import {IPrice} from "~/cabinet/ts/data/usluga/IPrice";
import {
    ContentFormParamsInterface,
    SenderContentFormModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";

export interface IVoiceContentParams extends ContentFormParamsInterface {
    orderAvailable: boolean;
    synthesisVoices: ISynthesisVoice[],
    voicePremiumPrice?: IPrice
}

export interface ISynthesisVoice {
    id: string;
    descr: string;
    examples: string[],
    isPremium: boolean
}


export interface IVoiceSource extends SenderSourceInterface {
    detailsId: string;
    files: string[];
}

export interface IVoiceSourceForDealer extends IVoiceSource {

    details?: {
        descr: string;
    }
}

export const VOICE_CONTENT_TYPE_TEXT = "text";
export const VOICE_CONTENT_TYPE_FILE = "file";
export const VOICE_CONTENT_TYPE_ORDER = "order";


export interface IVoiceContentFormValue extends SenderContentFormModelInterface {
    type: "text" | "file" | "order",
    data: IVoiceTextContentFormValue | IVoiceFileContentFormValue | IVoiceOrderContentFormValue,
    ivr?: IVRMenuItem[],
    sourceId?: string,
    ivrDelay: number,
    ivrEnabled: boolean,
    retryEnabled: false,
    retry: {
        count: number,
        delay: IMyTimeSelectorValue
    }
}

export interface IVoiceTextContentFormValue {
    text: string;
    synthesis: {
        voice?: string,
        speed: number
    }
}

export interface IVoiceFileContentFormValue {
    fileId?: string,
    fileName?: string
}

export interface IVoiceOrderContentFormValue {
    orderId?: string,
}


interface IVRMenuItem {
    number: number;
    type: string;
    params: Dictionary<any>;
}