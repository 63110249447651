import {RawLocation, RouteConfig} from "vue-router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import Vue from "vue";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import MenuBadge from "~/cabinet/vue/interface/menu/MenuBadge";
import GetPlatformListResult from "~/cabinet/ts/data/events/GetPlatformListResult";
import EventPlatform from "~/cabinet/ts/data/events/EventPlatform";
import EventPlatformAuth from "~/cabinet/ts/data/events/EventPlatformAuth";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {ONLINE_CHAT_PLATFORM_ID} from "~/cabinet/ts/Constant";
import MenuItemDelimiter from "~/cabinet/vue/interface/menu/MenuItemDelimiter";

const COMMON_TYPE = "common";
export const PARAM_PLATFORM = "platformId";
export const PARAM_AUTH = "authId";
const PARAM_TYPE = "typeId";
const PARAM_EVENT = "eventId";


export default class EventsRouteBuilder {
    private routeName: string;
    private includedPlatform: string[] = [];
    private excludedPlatform: string[] = [];
    private menu: MenuItem[];
    private dropdownMenu: MenuItem[];
    private showDropdownMenu: boolean = true;

    constructor(routeName: string) {
        this.routeName = routeName;
        this.menu = Vue.observable([]);
        this.dropdownMenu = Vue.observable([]);
    }

    setShowDropdownMenu(show: boolean) {
        this.showDropdownMenu = show;
        return this;
    }

    addIncludedPlatformId(platformId: string) {
        this.includedPlatform.push(platformId);
        return this;
    }

    addExcludedPlatformId(platformId: string) {
        this.excludedPlatform.push(platformId);
        return this;
    }

    get platformRouteName() {
        return `${this.routeName}_platform`;
    }

    get authRouteName() {
        return `${this.routeName}_auth`;
    }


    get typeRouteName() {
        return `${this.platformRouteName}_category`;
    }

    get typesRouteName() {
        return `${this.platformRouteName}_categories`;
    }

    get customOnlineChatConfigRouteName() {
        return `${this.platformRouteName}_customOnlineChatEventConfig`;
    }

    get eventRouteName() {
        return `${this.typeRouteName}_event`;
    }

    platformsInitialized = false;
    private platforms: EventPlatform[] = [];

    private async fetchPlatforms() {
        if (!this.platformsInitialized) {
            this.platformsInitialized = true;
            let result = await (new LcabApiRequest({
                url: `/api/events/getPlatformList`,
                p: {
                    excluded: this.excludedPlatform,
                    included: this.includedPlatform
                }
            }, GetPlatformListResult)).send();
            if (!result.showMessageOnError()) {
                this.platforms = result.getData("platforms");
            } else {
                this.platformsInitialized = false;
            }
        }
        return this.platforms;
    }

    private initDropdownMenu(platform: EventPlatform) {
        this.dropdownMenu.splice(0, this.dropdownMenu.length);
        for (let auth of platform.authList) {
            this.dropdownMenu.push(
                (new MenuItem(this.authRouteName, auth.descr))
                    .addRouteParam(PARAM_PLATFORM, platform.id)
                    .addRouteParam(PARAM_AUTH, auth.id)
            );
        }
    }

    private getPlatformById(platformId: string) {
        return this.platforms.find(platform => platform.id == platformId);
    }

    private async prepareMenuForPlatform(platformId: string, authId: string) {
        await this.fetchPlatforms();
        let platform = this.getPlatformById(platformId);

        this.menu.splice(0, this.menu.length);
        if (platform) {
            let auth = this.getAuthById(platformId, authId);
            if (auth) {
                this.initDropdownMenu(platform);
                if (auth.types.length) {
                    for (let type of auth.types) {

                        let eventsOfType = this.getEventsOfAuth(platformId, authId, type.id);
                        if (eventsOfType.length) {
                            let typeMenuItem = (new MenuItem(this.typeRouteName, type.descr))
                                .addRouteParam(PARAM_TYPE, type.id);

                            for (let event of eventsOfType) {
                                typeMenuItem.addChildrenItem(
                                    (new MenuItem(this.eventRouteName, event.descr))
                                        .addRouteParam(PARAM_TYPE, type.id)
                                        .addRouteParam(PARAM_EVENT, event.id)
                                        .setBadge(
                                            (new MenuBadge(`events_${platformId}_${event.id}`))
                                                .setType("primary")
                                                .setIsHidden(true)
                                        )
                                        .setMutedOnBadgeValue(0)
                                );
                            }
                            this.menu.push(typeMenuItem);
                        }
                    }
                } else {
                    for (let event of auth.events) {
                        this.menu.push(
                            (new MenuItem(this.eventRouteName, event.descr))
                                .addRouteParam(PARAM_TYPE, COMMON_TYPE)
                                .addRouteParam(PARAM_EVENT, event.id)
                        );
                    }
                }

                if (platform.id == ONLINE_CHAT_PLATFORM_ID) {
                    this.menu.push(
                        new MenuItemDelimiter(),
                        new MenuItem(this.customOnlineChatConfigRouteName, "Настройка своих событий", 'cog')
                    )
                }
            }
        }
    }

    private getAuthById(platformId: string, authId: string): EventPlatformAuth | null {
        let platform = this.getPlatformById(platformId);
        if (platform) {
            return platform.authList.find(auth => auth.id == authId);
        }
        return null;
    }

    private getTypesOfPlatform(platformId: string, authId: string) {
        let auth = this.getAuthById(platformId, authId);
        return auth ? auth.types : [];
    }

    private getEventsOfAuth(platformId: string, authId: string, typeId: string = null) {
        if (typeId == COMMON_TYPE) {
            typeId = null;
        }
        let auth = this.getAuthById(platformId, authId);

        return (auth ? auth.events : []).filter(event => typeId ? (typeId == event.typeId) : true);
    }


    public makeRoute(): RouteConfig {
        return {
            name: this.routeName,
            path: "events",
            component: () => import(/* webpackChunkName: "client-events" */ '~/cabinet/vue/client/events/PlatformList.vue'),
            props: () => ({
                platforms: this.platforms,
                platformRouteName: this.platformRouteName,
                routeName: this.routeName
            }),
            meta: {
                beforeEach: async (to) => {
                    let platforms = await this.fetchPlatforms();
                    if (to.name == this.routeName && platforms.length == 1) {
                        return {
                            name: this.platformRouteName,
                            params: {
                                [PARAM_PLATFORM]: platforms[0].id
                            }
                        };
                    }
                    return null;
                },
                access: [
                    () => AccountStore.access.value.checkAccessForEventsConfig()
                ]
            } as IRouteMeta,

            children: [
                this.platformRoute
            ]
        }
    }

    private get platformRoute(): RouteConfig {
        return {
            path: `:${PARAM_PLATFORM}`,
            name: this.platformRouteName,
            meta: {
                beforeEach: async (to) => {
                    await this.fetchPlatforms();
                    let nextLocation: RawLocation;
                    let platformId = to.params[PARAM_PLATFORM];
                    if (to.name == this.platformRouteName) {
                        let platform = this.platforms.find(platform => platform.id == platformId);
                        if (platform) {
                            if (platform.authList.length == 1) {
                                nextLocation = {
                                    name: this.authRouteName,
                                    params: {
                                        [PARAM_PLATFORM]: platformId,
                                        [PARAM_AUTH]: platform.authList[0].id,
                                    }
                                }
                            }
                        } else {
                            nextLocation = {
                                name: this.routeName
                            };
                        }
                    }
                    return nextLocation;
                }
            } as IRouteMeta,
            component: () => import(/* webpackChunkName: "client-events" */ '~/cabinet/vue/client/events/AuthList.vue'),
            props: (route) => ({
                platform: this.platforms.find(platform => platform.id == route.params[PARAM_PLATFORM]),
                authRouteName: this.authRouteName,
                routeName: this.platformRouteName,
                selectPlatformsRouteName: this.routeName
            }),
            children: [
                this.authRoute
            ]
        };
    }

    private get authRoute(): RouteConfig {
        return {
            path: `:${PARAM_AUTH}`,
            name: this.authRouteName,
            meta: {
                menu: this.menu,

                beforeEach: async (to) => {
                    await this.fetchPlatforms();
                    let platformId = to.params[PARAM_PLATFORM];
                    let authId = to.params[PARAM_AUTH];
                    await this.prepareMenuForPlatform(platformId, authId);
                    return undefined;
                    /*
                    let nextLocation: RawLocation;
                    if (to.name == this.authRouteName) {
                        nextLocation = {
                            name: this.typesRouteName,
                            params: {
                                [PARAM_PLATFORM]: platformId,
                                [PARAM_AUTH]: authId
                            }
                        };
                    }
                    return nextLocation;*/
                },
                beforeLeftMenuDropdown: this.showDropdownMenu ? {
                    items: this.dropdownMenu,
                    back: {
                        name: this.routeName
                    }
                } : null
            } as IRouteMeta,
            children: [
                this.typeRoute,
                {
                    name: this.customOnlineChatConfigRouteName,
                    path: "customOnlineChatEventConfig",
                    component: () => import(/* webpackChunkName: "client-events" */ '~/cabinet/vue/client/events/customOnlineChatEvent/CustomOnlineChatEventConfig.vue'),
                    meta: {
                        beforeEach: async (to) => {
                            await this.fetchPlatforms();
                            let platformId = to.params[PARAM_PLATFORM];
                            let authId = to.params[PARAM_AUTH];
                            let nextLocation: RawLocation;
                            if (platformId != ONLINE_CHAT_PLATFORM_ID) {
                                nextLocation = {
                                    name: this.typesRouteName,
                                    params: {
                                        [PARAM_PLATFORM]: platformId,
                                        [PARAM_AUTH]: authId
                                    }
                                };
                            }
                            return nextLocation;
                        }
                    } as IRouteMeta,
                }
            ]
        };
    }

    private get typeRoute(): RouteConfig {
        return {
            path: `types`,
            name: this.typesRouteName,
            meta: {
                beforeEach: async (to) => {
                    await this.fetchPlatforms();
                    let nextLocation: RawLocation;
                    if (to.name == this.typesRouteName) {
                        let platformId = to.params[PARAM_PLATFORM];
                        let authId = to.params[PARAM_AUTH];
                        let types = this.getTypesOfPlatform(platformId, authId);
                        nextLocation = {
                            name: this.typeRouteName,
                            params: {
                                [PARAM_PLATFORM]: platformId,
                                [PARAM_AUTH]: authId,
                                [PARAM_TYPE]: types.length ? types[0].id : COMMON_TYPE
                            }
                        };
                    }
                    return nextLocation;
                }
            } as IRouteMeta,
            children: [
                {
                    path: `:${PARAM_TYPE}`,
                    name: this.typeRouteName,
                    meta: {
                        beforeEach: async (to) => {
                            await this.fetchPlatforms();
                            let nextLocation: RawLocation;
                            if (to.name == this.typeRouteName) {
                                let platformId = to.params[PARAM_PLATFORM];
                                let typeId = to.params[PARAM_TYPE];
                                let authId = to.params[PARAM_AUTH];
                                let events = this.getEventsOfAuth(platformId, authId, typeId);
                                if (events.length) {
                                    nextLocation = {
                                        name: this.eventRouteName,
                                        params: {
                                            [PARAM_PLATFORM]: platformId,
                                            [PARAM_AUTH]: authId,
                                            [PARAM_TYPE]: typeId,
                                            [PARAM_EVENT]: events[0].id
                                        }
                                    };
                                }
                            }
                            return nextLocation;
                        }
                    } as IRouteMeta,
                    children: [
                        this.eventRoute
                    ]
                }
            ]
        };
    }

    private get eventRoute(): RouteConfig {
        return {
            path: `:${PARAM_EVENT}`,
            name: this.eventRouteName,
            component: () => import(/* webpackChunkName: "client-events" */ '~/cabinet/vue/client/events/EventRegisters.vue'),
            props: (route) => {
                let platformId = route.params[PARAM_PLATFORM];
                return {
                    platformId: platformId,
                    authId: route.params[PARAM_AUTH],
                    eventId: route.params[PARAM_EVENT]
                };
            },
            meta: {
                beforeEach: async () => {
                    await this.fetchPlatforms();
                }
            } as IRouteMeta,
        };
    }


}