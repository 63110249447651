import Dictionary from "~/ts/library/Dictionary";
import {IFilter, IOperation, IValueComponent} from "~/cabinet/vue/interface/filter/Interfaces";
import {
    FILTER_TYPE_CHECKBOX,
    FILTER_TYPE_COMBINED,
    FILTER_TYPE_DATE,
    FILTER_TYPE_DATETIME,
    FILTER_TYPE_FLOAT,
    FILTER_TYPE_HIDDEN,
    FILTER_TYPE_INTEGER,
    FILTER_TYPE_LOCATION,
    FILTER_TYPE_SECONDS,
    FILTER_TYPE_SELECT,
    FILTER_TYPE_TIME,
    FILTER_TYPE_TIME_RANGE
} from "~/cabinet/vue/interface/filter/MyFilterTypes";

let types: Dictionary<IValueComponent> = {
    [FILTER_TYPE_SECONDS]: {
        component: "MyFilterSeconds"
    },
    [FILTER_TYPE_TIME_RANGE]: {
        component: "MyFilterTimeRange"
    },
    [FILTER_TYPE_SELECT]: {
        component: "MyFilterSelect",
        isMultipleRealizedByComponent: true
    },
    [FILTER_TYPE_CHECKBOX]: {
        component: "MyFilterSelect",
        isMultipleRealizedByComponent: true
    },
    [FILTER_TYPE_DATE]: {component: "MyFilterDate"},
    [FILTER_TYPE_TIME]: {component: "MyFilterTime"},
    [FILTER_TYPE_DATETIME]: {component: "MyFilterDate"},
    [FILTER_TYPE_LOCATION]: {
        component: "MyFilterOrganizationLocation"
    },
    [FILTER_TYPE_COMBINED]: {
        component: "MyFilterCombined"
    },
    [FILTER_TYPE_HIDDEN]: {
        component: "MyFilterHidden"
    },
    [FILTER_TYPE_INTEGER]: {
        component: "MyFilterText"//"MyFilterInteger",
    },
    [FILTER_TYPE_FLOAT]: {
        component: "MyFilterText"//"MyFilterFloat",
    },
    default: {
        component: "MyFilterText"
    }
};

export default class MyFilterValueComponent implements IValueComponent {
    public filter: IFilter;
    public rootFilter: IFilter;
    public operation: IOperation;
    public component: string;
    public isMultipleRealizedByComponent: boolean;

    constructor(filter: IFilter | null, operation: IOperation, rootFilter: IFilter) {
        if (!filter) {
            filter = rootFilter;
        }
        this.filter = filter;
        this.rootFilter = rootFilter;
        this.operation = operation;
        let type = filter.type;
        let result = types[type] ? types[type] : types.default;

        if (filter.type == "checkbox") {
            filter.multiple = true;
        }
        this.component = result.component;
        this.isMultipleRealizedByComponent = result.isMultipleRealizedByComponent;
    }
}