import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";

let cache: any = {
    country: {},
    district: {},
    city: {},
    byValue: {}
};


export default class MyFilterOrganizationLocationHelper {
    public static fetchList(type: string, parentId?: string): Promise<IMyFilterOrganizationLocationItem[]> {
        if (parentId == "" || (type != "country" && !parentId)) {
            return new Promise(resolve => resolve([]));
        }

        let cacheKey = "fetchList_" + type + "_" + parentId;
        let result = MyFilterOrganizationLocationHelper.getByCache(cacheKey);
        if (result == null) {
            result = new Promise(resolve => {
                let method = "getCountryList";
                let params: any = {
                    parentId: parentId
                };
                if (type == "district") {
                    method = "getDistrictList";
                } else if (type == "city") {
                    method = "getCityList";
                }
                let url = "/api/dealer/organization/filters/" + method;
                (new LcabApiRequest({
                    url: url,
                    p: params,
                    silent: true
                })).send().then(result => {
                    resolve(result.getData());
                });
            });
            cache[cacheKey] = result;
        }
        return result;
    }

    private static getByCache(cacheKey: string) {
        if (cacheKey != null) {
            if (Array.isArray(cache[cacheKey])) {
                return new Promise(resolve => resolve(cache[cacheKey]));
            } else {
                return cache[cacheKey];
            }
        } else {
            return null;
        }
    }



    public static fetchByValue(value: IMyFilterOrganizationLocationValue): Promise<IMyFilterOrganizationLocationByValueResult> {
        let cacheKey = "byValue_" + value.essenceType + "_" + value.essenceID;
        let result = MyFilterOrganizationLocationHelper.getByCache(cacheKey);
        if (result == null) {
            result = new Promise((resolve, reject) => {
                (new LcabApiRequest({
                    url: "/api/dealer/organization/filters/getInitByValue",
                    p: value,
                    silent: true
                })).send().then(result => {
                    if (result.isSuccess) {
                        resolve(result.getData());
                    } else {
                        reject();
                    }
                }, reject);
            });
            cache[cacheKey] = result;
        }
        return result;
    }

}

export interface IMyFilterOrganizationLocationValue {
    essenceID: string;
    essenceType: string;
}

export interface IMyFilterOrganizationLocationByValueResult {
    country: string;
    district?: string;
    city?: string;
    descr: string;
}

export interface IMyFilterOrganizationLocationItem {
    id: string;
    descr: string;
}