import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import Dictionary from "~/ts/library/Dictionary";
import {AttachmentsConfigModelInterface} from "~/cabinet/vue/interface/AttachmentsConfigInterface";
import {ComputedRef, InjectionKey} from "vue";
import {
    SenderContentFormModelInterface,
    SenderFormModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {
    DiscountCouponFormInterface
} from "~/cabinet/vue/dealer/config/marketing/discountCoupon/DiscountCouponFormInterface";
import {FiltersModel} from "~/cabinet/vue/interface/filter/Interfaces";
import {WebhookConfigInterface} from "~/cabinet/vue/interface/webhook/WebhookConfigInterface";

export interface ICascadeContentFormEvent {
    eventDescriptionId: string,
    authId: string,
    platformId: string
}

export interface IFilterCascadeBlockParam {
    filters: {
        filterValues: any,
        outputId: string,
        descr: string
    }[]
}

export interface IDelayCascadeBlockParam {
    delay: number
}

export interface INoteCascadeBlockParam {
    text: string;
}

export interface ISenderCascadeBlockParam {
    senderFormParam: SenderFormModelInterface<any>
}

export interface IGlobalVarsBlockParam {
    vars: IGlobalVarsBlockParamVar[]
}

export interface IGlobalVarsBlockParamVar {
    key: string;
    value: string;
    type?: "math";
}

export interface IWebhookCascadeBlockParam {
    globalVariables: {
        name: string,
        path: string[]
    }[],
    logId?: string;
    webhook: WebhookConfigInterface;
    descr?: string;
}

export interface DiscountCouponBlockParam extends DiscountCouponFormInterface {

}

export interface ICallAlgoBlockParam {
    form: IForm
}

export interface ICabinetNotificationParam {
    subject: string;
    text: string;
}

export interface ISendNotificationToClientOnlineChatOperatorParam {
    text: string;
    dealerSiteId: string;
}

export interface ICascadeContentFormValue extends SenderContentFormModelInterface {
    schemeId: string;
}

export const ONLINE_CHAT_BOT_EVENT_DESCRIPTION_ID = "onlineChatBot";

export const CascadeContentFormEventInject = Symbol() as InjectionKey<ComputedRef<ICascadeContentFormEvent>>;

export interface IAbstractOnlineChatMessagePinParam {
    pinMessage: boolean;
    unpinOtherMessages: boolean;
    pinMessageForClient: boolean;
    unpinOtherMessagesForClient: boolean;
}

interface IAbstractOnlineChatMessageParam extends IAbstractOnlineChatMessagePinParam {
    text: string;
    isUseStandartContent: boolean;
    messageTypeId?: string;
}

export interface IOnlineChatMessageToOperatorParam extends IAbstractOnlineChatMessageParam {
    operators: (string | number)[],
    operatorsCount: string
}

export interface IOnlineChatForwardClientToOperatorParam {
    operators: (string | number)[],
    operatorsType: string,
    notifyToOperatorText?: string,
    notifyToClientText?: string
    notifyToClientEnabled: boolean
}

export interface IOnlineChatRequestRateParam {
    customAnswerVariants: string[]
}

export const ONLINE_CHAT_MESSAGE_TO_CLIENT_SEND_ERROR_MESSAGE_AND_WAIT_FOR_VALID_ANSWER_ACTION_ID = "sendErrorMessageAndWaitForValidAnswer";

export interface IOnlineChatMessageToClientParam extends IAbstractOnlineChatMessagePinParam {
    text: string;
    operatorType: string;
    virtualOperatorLogin: string;
    operatorLogin: string[];
    countInDialogStatistic: boolean;
    markPreviousMessagesAsReaded: boolean;
    answerVariants: string[];
    waitingForClientAnswer: boolean;
    answerFilters: FiltersModel;
    messageOnValidateError?: string;
    saveAnswerAsField?: string;
    useAnswerVariants: boolean;
    actionOnValidateError: string;
    attachments: AttachmentsConfigModelInterface[];
    withFilters: boolean;
    disableTextInput: boolean;

}

export interface IOnlineChatRedirectParam {
    url: string,
}

export interface IOnlineChatOpenWidgetTabParam {
    tabId: string,
    tabShowTypeId: string,
    formValues: Dictionary<any>
}


export interface IPhonebookWithGroupSelectParam {
    groups: number[];
    groupIds: number[];
    isAllGroups: boolean;
}

export interface IEventAsActionParam {
    authPrimaryId: string,
    platformId: string,
    eventDescriptionId: string,
    eventIdentifiers: string[],
    eventFilters: Dictionary<any>
}

