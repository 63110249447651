import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {
    HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM,
    HtmlConstructorBlockToolbarPosition
} from "~/cabinet/vue/interface/htmlConstructor/blocks/HtmlConstructorBlockToolbarPosition";
import CodeDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/code/CodeDisplay.vue";
import CodeForm from "~/cabinet/vue/interface/htmlConstructor/blocks/code/CodeForm.vue";
import ICodeBlockValues, {
    ICodeBlockParamsForEditor
} from "~/cabinet/vue/interface/htmlConstructor/blocks/code/ICodeBlockValues";
import {Component} from "vue";


export default class CodeBlockType extends AbstractBlockType<ICodeBlockValues, ICodeBlockParamsForEditor> {
    get displayComponent(): Component {
        return CodeDisplay;
    }

    get formComponent(): Component {
        return CodeForm;
    }

    get isBlockWithoutPadding(): boolean {
        return true;
    }

    get toolbarPosition(): HtmlConstructorBlockToolbarPosition {
        return HTML_CONSTRUCTOR_BLOCK_TOOLBAR_POSITION_BOTTOM;
    }
}
