import ButtonDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/button/ButtonDisplay.vue";
import ButtonForm from "~/cabinet/vue/interface/htmlConstructor/blocks/button/ButtonForm.vue";
import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import IButtonBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/button/IButtonBlockValues";
import {Component} from "vue";


export default class ButtonBlockType extends AbstractBlockType<IButtonBlockValues, any> {

    get displayComponent(): Component {
        return ButtonDisplay;
    }

    get formComponent(): Component {
        return ButtonForm;
    }




}
