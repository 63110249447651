import EventManager from "~/ts/library/EventManager";
import {__} from "~/ts/library/Translate";

export const NOT_FOUND_ROUTE_NAME = "404";
export const NOT_AUTHORIZED_SECTION = "NOT_AUTHORIZED_SECTION";
export const AUTHORIZED_SECTION = "AUTHORIZED_SECTION";
export const AUTH_FORM_ROUTE = "AUTH_FORM_ROUTE";

export const EVENT_COMPUTE_LAYOUT = "EVENT_COMPUTE_LAYOUT";

export const USLUGA_ID_VOICE = "voice";
export const USLUGA_ID_PAGER = "pager";
export const USLUGA_ID_VIBER = "viber";
export const USLUGA_ID_EMAIL = "email";
export const USLUGA_ID_ONLINE = "online";
export const USLUGA_ID_AGENT = "agent";
export const USLUGA_ID_PCOUNT = "pcount";
export const USLUGA_ID_RECEIVESMS = "receive";
export const USLUGA_ID_CASCADE = "cascade";
export const USLUGA_ID_MAILRU = "mailru";
export const USLUGA_ID_CALL_PASSWORD = "callpassword";
export const USLUGA_ID_CALL_GATE = "callgate";

export const USLUGA_ID_WHATSAPP = "whatsapp";
export const USLUGA_ID_MESSENGER = "messenger";

export const LICENSER_ID_SMS_SOURCE_REPLACE = "smsSourceReplace";
export const LICENSER_ID_VIBER_SOURCE = "viberSource";


export const OPERATION_EQUAL_ALL = "equalAll";
export const EventManagerInstance = new EventManager();

export const NOT_ENOUGH_PARAMS = __("Недостаточно переданных параметров");

export const SUPER_DEALER_ID = "smsu";
/* Роутинг */


export const CASCADE_TYPE_CASCADE = "cascade";
export const CASCADE_EVENT_ID = "smsuCascadeBlockRun";
export const SMS_USLUGI_PLATFORM_ID = "smsUslugi";
export const ONLINE_CHAT_PLATFORM_ID = "onlineChat";