import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import Integration from "~/cabinet/ts/data/online/config/integration/Integration";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import Dictionary from "~/ts/library/Dictionary";

export default class IntegrationListResponse extends AbstractEntity {
    integrations: Integration[];
    unusedAuthList: Auth[];
    platforms: Dictionary<Platform>;
    showOldCrmWarning: boolean;

    protected getFields(): AbstractEntityFields {
        return {
            integrations: Integration,
            unusedAuthList: Auth,
            platforms: Platform.dictionary()
        };
    }
}