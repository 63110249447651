import MyRouterView from "~/core-ui/vue/router/MyRouterView.vue";

let MyRouterViewPlugin = {
    install: function (Vue: any) {
        Vue.mixin({
            created: function () {
                if (this.$parent) {
                    if (this.$parent.$options.name == MyRouterView.name) {
                        if (this.$options.name) {
                            this.$emit('my-router-view-name', this.$options.name);
                        }
                    }
                }
            }
        });

    }
};

export default MyRouterViewPlugin;