let DisposableEventsPlugin = {
    install: function (Vue: any) {
        Vue.mixin({
            data: function () {
                return {
                    $disposableEventsList: [] as Function[],
                    $isActivated: true
                }
            },
            beforeDestroy: function () {
                let eventsList = this.$data.$disposableEventsList;
                if (eventsList.length) {
                    for (let cb of eventsList) {
                        cb();
                    }
                    eventsList.splice(0, eventsList.length);
                }
            },
            activated: function () {
                this.$data.$isActivated = true;
            },
            deactivated: function () {
                this.$data.$isActivated = false;
            }
        });

        Vue.prototype.$addDisposableEvent = function (dispose: Function) {
            this.$data.$disposableEventsList.push(dispose);
            return dispose;
        };

        Vue.prototype.$isActivated = function () {
            return !!this.$data.$isActivated;
        };
    }
};

export default DisposableEventsPlugin;