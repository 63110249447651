const linkRegexp = /(https?:\/\/([-\w.]+)+(:\d+)?(\/?([\w/_%+\-.#|]*(\?\S+)?)?)?)/;


export default class SmsLength {
    public static calc(v: string, isMagicUrlUsed: boolean = false): {
        symbols: number,
        messages: number,
        left: number,
        maxSymbols: number,
        part: number,
        symbolsInThisPart: number
    } {
        if (isMagicUrlUsed) {
            v = v.replace(linkRegexp, "smsu.ru/123456");
        }
        let unicodeFlag = 0;
        //if (wapPushEnabled && url.length) v += url;
        let extraChars = 0;
        for (let i = 0; i < v.length; i++) {
            if (v.charAt(i) == '`') {
                extraChars++
            } else if ((v.charAt(i) >= '0') && (v.charAt(i) <= '9')) {
            } else if ((v.charAt(i) >= 'A') && (v.charAt(i) <= 'Z')) {
            } else if ((v.charAt(i) >= 'a') && (v.charAt(i) <= 'z')) {
            } else if (v.charAt(i) == '@') {
            } else if (v.charCodeAt(i) == 0xA3) {
            } else if (v.charAt(i) == '$') {
            } else if (v.charCodeAt(i) == 0xA5) {
            } else if (v.charCodeAt(i) == 0xE8) {
            } else if (v.charCodeAt(i) == 0xE9) {
            } else if (v.charCodeAt(i) == 0xF9) {
            } else if (v.charCodeAt(i) == 0xEC) {
            } else if (v.charCodeAt(i) == 0xF2) {
            } else if (v.charCodeAt(i) == 0xC7) {
            } else if (v.charAt(i) == '\r') {
            } else if (v.charAt(i) == '\n') {
            } else if (v.charCodeAt(i) == 0xD8) {
            } else if (v.charCodeAt(i) == 0xF8) {
            } else if (v.charCodeAt(i) == 0xC5) {
            } else if (v.charCodeAt(i) == 0xE5) {
            } else if (v.charCodeAt(i) == 0x394) {
            } else if (v.charAt(i) == '_') {
            } else if (v.charCodeAt(i) == 0x3A6) {
            } else if (v.charCodeAt(i) == 0x393) {
            } else if (v.charCodeAt(i) == 0x39B) {
            } else if (v.charCodeAt(i) == 0x3A9) {
            } else if (v.charCodeAt(i) == 0x3A0) {
            } else if (v.charCodeAt(i) == 0x3A8) {
            } else if (v.charCodeAt(i) == 0x3A3) {
            } else if (v.charCodeAt(i) == 0x398) {
            } else if (v.charCodeAt(i) == 0x39E) {
            } else if (v.charCodeAt(i) == 0xC6) {
            } else if (v.charCodeAt(i) == 0xE6) {
            } else if (v.charCodeAt(i) == 0xDF) {
            } else if (v.charCodeAt(i) == 0xC9) {
            } else if (v.charAt(i) == ' ') {
            } else if (v.charAt(i) == '!') {
            } else if (v.charAt(i) == '\"') {
            } else if (v.charAt(i) == '#') {
            } else if (v.charCodeAt(i) == 0xA4) {
            } else if (v.charAt(i) == '%') {
            } else if (v.charAt(i) == '&') {
            } else if (v.charAt(i) == '\'') {
            } else if (v.charAt(i) == '(') {
            } else if (v.charAt(i) == ')') {
            } else if (v.charAt(i) == '*') {
            } else if (v.charAt(i) == '+') {
            } else if (v.charAt(i) == ',') {
            } else if (v.charAt(i) == '-') {
            } else if (v.charAt(i) == '.') {
            } else if (v.charAt(i) == '/') {
            } else if (v.charAt(i) == ':') {
            } else if (v.charAt(i) == ';') {
            } else if (v.charAt(i) == '<') {
            } else if (v.charAt(i) == '=') {
            } else if (v.charAt(i) == '>') {
            } else if (v.charAt(i) == '?') {
            } else if (v.charCodeAt(i) == 0xA1) {
            } else if (v.charCodeAt(i) == 0xC4) {
            } else if (v.charCodeAt(i) == 0xD6) {
            } else if (v.charCodeAt(i) == 0xD1) {
            } else if (v.charCodeAt(i) == 0xDC) {
            } else if (v.charCodeAt(i) == 0xA7) {
            } else if (v.charCodeAt(i) == 0xBF) {
            } else if (v.charCodeAt(i) == 0xE4) {
            } else if (v.charCodeAt(i) == 0xF6) {
            } else if (v.charCodeAt(i) == 0xF1) {
            } else if (v.charCodeAt(i) == 0xFC) {
            } else if (v.charCodeAt(i) == 0xE0) {
            } else if (v.charCodeAt(i) == 0x391) {
            } else if (v.charCodeAt(i) == 0x392) {
            } else if (v.charCodeAt(i) == 0x395) {
            } else if (v.charCodeAt(i) == 0x396) {
            } else if (v.charCodeAt(i) == 0x397) {
            } else if (v.charCodeAt(i) == 0x399) {
            } else if (v.charCodeAt(i) == 0x39A) {
            } else if (v.charCodeAt(i) == 0x39C) {
            } else if (v.charCodeAt(i) == 0x39D) {
            } else if (v.charCodeAt(i) == 0x39F) {
            } else if (v.charCodeAt(i) == 0x3A1) {
            } else if (v.charCodeAt(i) == 0x3A4) {
            } else if (v.charCodeAt(i) == 0x3A5) {
            } else if (v.charCodeAt(i) == 0x3A7) {
            } else if (v.charAt(i) == '^') {
                extraChars++
            } else if (v.charAt(i) == '{') {
                extraChars++
            } else if (v.charAt(i) == '}') {
                extraChars++
            } else if (v.charAt(i) == '\\') {
                extraChars++
            } else if (v.charAt(i) == '[') {
                extraChars++
            } else if (v.charAt(i) == '~') {
                extraChars++
            } else if (v.charAt(i) == ']') {
                extraChars++
            } else if (v.charAt(i) == '|') {
                extraChars++
            } else if (v.charCodeAt(i) == 0x20AC) {
                extraChars++
            } else {
                unicodeFlag++;
            }
        }
        let length = v.length;
        if (!length) {
            unicodeFlag = 1;//было true
        }
        let singlePart, multyPart;
        if (unicodeFlag) {
            singlePart = 70;
            multyPart = 67;
        } else {
            singlePart = 160;
            multyPart = 153;
        }
        /*
        if (wapPushEnabled && url.length) {
            if (unicodeFlag) {
                length += unicodeFlag;
            }
            length += 35;
            var singlePart = 126;
            var multyPart = 115;
        }
         */
        let part, maxSymbols;
        var msgCount = length;
        if (length <= singlePart) {
            msgCount = 1;
            part = singlePart;
            maxSymbols = part;
        } else {
            msgCount += (multyPart - 1);
            msgCount -= (msgCount % multyPart);
            msgCount /= multyPart;
            part = multyPart;
            maxSymbols = multyPart * msgCount;
        }

        var left = (msgCount * part - length);
        return {
            symbols: length,
            messages: msgCount,
            left: left,
            maxSymbols: maxSymbols,
            part: part,
            symbolsInThisPart: part - left
        };
    }


}