import H1Form from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1Form.vue";
import H1BlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1BlockType";
import H3Display from "~/cabinet/vue/interface/htmlConstructor/blocks/h3/H3Display.vue";
import {Component} from "vue";


export default class H3BlockType extends H1BlockType {

    get displayComponent(): Component {
        return H3Display;
    }

    get formComponent(): Component {
        return H1Form;
    }

}
