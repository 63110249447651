import ICurrency from "~/cabinet/ts/data/ICurrency";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";

let sprintf = require("sprintf-js").sprintf;


export function getCurrency(currencyId: string): ICurrency {
    let currency = AccountStore.currencyList.value[currencyId];
    if (!currency) {
        currency = {
            id: null,
            icon: "warning",
            descr: "unknown",
            shortDescr: "unknown",
            decimalPoint: 4,
            exchangeRate: 1,
            graph: "unknown"
        };
    }
    return currency;
}

export default class Currency {
    static getById(currencyId: string) {
        let currency = AccountStore.currencyList.value[currencyId];
        if (!currency) {
            currency = {
                id: null,
                icon: "warning",
                descr: "unknown",
                shortDescr: "unknown",
                decimalPoint: 4,
                exchangeRate: 1,
                graph: "unknown"
            }
        }
        return currency;
    }

    static getShortDescrById(currencyId: string): string {
        return this.getById(currencyId).shortDescr;
    }

    static formatPrice(price: number, currencyId: string): number {
        let currency = this.getById(currencyId);
        return parseFloat(sprintf("%." + currency.decimalPoint + "f", price));
    }

    static convert(sum: number, fromCurrencyId: string, toCurrencyId: string): number {
        let from = Currency.getById(fromCurrencyId);
        let to = Currency.getById(toCurrencyId);
        if (from && to) {
            return Currency.formatPrice(sum * (to.exchangeRate / from.exchangeRate), toCurrencyId);
        } else {
            throw new Error("Не найдены валюты");
        }
    }
}