var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.TextInputWithVariables,{style:({
        textAlign: _setup.model.align,
        fontSize: `${_vm.styles.bodyH3Size}px`,
        fontFamily: _vm.styles.bodyH3Font,
        color: _vm.styles.bodyH3Color,
        fontWeight: _vm.styles.bodyH3Weight
    }),attrs:{"placeholder":"Подзаголовок","inline":"","editor-class-list":['pt-1', 'pb-1'],"content-variables":_setup.htmlConstructorEditor.params.contentVariables},model:{value:(_setup.model.title),callback:function ($$v) {_vm.$set(_setup.model, "title", $$v)},expression:"model.title"}})
}
var staticRenderFns = []

export { render, staticRenderFns }