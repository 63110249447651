export const PHONEBOOK_COLUMN_TYPE_SEX = "sex";
export const PHONEBOOK_COLUMN_TYPE_DATE = "date";
export const PHONEBOOK_COLUMN_TYPE_DATETIME = "dateTime";
export const PHONEBOOK_COLUMN_TYPE_EMAIL = "email";
export const PHONEBOOK_COLUMN_TYPE_VARCHAR = "varchar";
export const PHONEBOOK_COLUMN_TYPE_TEXT = "text";
export const PHONEBOOK_COLUMN_TYPE_INTEGER = "integer";
export const PHONEBOOK_COLUMN_TYPE_FLOAT = "float";

export const PHONEBOOK_COLUMN_NAME_PHONE = "phone";
export const PHONEBOOK_COLUMN_NAME_EMAIL = "email";
export const PHONEBOOK_COLUMN_NAME_MESSENGERS = "messengers";
export const PHONEBOOK_COLUMN_NAME_LASTNAME = "f";
export const PHONEBOOK_COLUMN_NAME_FIRSTNAME = "i";
export const PHONEBOOK_COLUMN_NAME_MIDDLENAME = "o";