var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"root"},[_c(_setup.ImageDisplay,{class:['div', 'float', _setup.floatClass],style:({
            margin: _setup.margin
        }),attrs:{"styles":_vm.styles},on:{"click":function($event){return _setup.emits('click')}},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}}),_vm._v(" "),_c(_setup.TextDisplay,{staticClass:"div block-sibling",style:({
            minHeight: `${_setup.model.height}px`
        }),attrs:{"styles":_vm.styles,"input-style":{
            minHeight: `${_setup.model.height}px`,
            overflow: 'visible',
            paddingTop: `0 !important`,
            paddingBottom: `0 !important`
        }},model:{value:(_setup.model),callback:function ($$v) {_setup.model=$$v},expression:"model"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }