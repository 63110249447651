import AbstractHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/AbstractHtmlConstructorType";
import EmailContentDisplay from "~/cabinet/vue/interface/htmlConstructor/constructorType/email/EmailContentDisplay.vue";
import {HTML_BLOCK_TYPE_HEADER, HTML_BLOCK_TYPE_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {Component} from "vue";

export default class EmailConstructorType extends AbstractHtmlConstructorType {
    get contentDisplayComponent(): Component {
        return EmailContentDisplay;
    }

    getDefaultBlockLists(): IHtmlConstructorBlock<any>[] {
        return [
            {
                type: HTML_BLOCK_TYPE_HEADER,
                values: {}
            },
            {
                type: HTML_BLOCK_TYPE_TEXT,
                values: {}
            }
        ];
    }
}