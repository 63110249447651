import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";

export default class MenuItemDelimiter extends MenuItem {
    constructor() {
        super("", "");
        this.delimiter = true;
    }

    get isSelectable(): boolean {
        return false;
    }
}