import ConsoleWrapper from "~/ts/library/Console";

export default function onHtmlifyClick(e: MouseEvent, p: { onCopySuccess: () => void, onCopyError: () => void }) {
    let target = e.target as HTMLElement;
    if (target.getAttribute("data-copyblock")) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(target.innerText).then(() => {
                p.onCopySuccess();
            }, (e) => {
                p.onCopyError();
                ConsoleWrapper.warn(e);
            });
        } else {
            p.onCopyError();
            ConsoleWrapper.warn("navigator.clipboard is undefined");
        }
    }
}