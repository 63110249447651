import {IUsluga} from "~/cabinet/ts/data/usluga/IUsluga";
import AbstractEntity from "~/ts/library/AbstractEntity";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import {Component} from "vue";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import {
    AbstractTarifPrice,
    NumericTarifPrice
} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";


export default abstract class Usluga extends AbstractEntity {
    id: string;
    badgeId?: string;
    badgeValue?: string | number;
    dealer: {
        config: {
            tarifComponent?: Component,
            commonUslugaSettingsComponent?: Component
        }
    };

    client: {
        tarifComponent?: Component
    };

    rawTarifPriceToAbstractTarifPrice: (tarifCurrencyId: string, price: any, path: any[]) => AbstractTarifPrice = function (tarifCurrencyId, price) {
        let result = NumericTarifPrice.new(typeof price == "number" ? {sum: price} : price);
        result.currencyId = tarifCurrencyId;
        return result;
    };


    apiTokenTypeId?: string

    get uslugaFromBackend(): IUsluga | null {
        return AccountStore.uslugiDictionary.value[this.id];
    }

    get weight(): number {
        let usluga = this.uslugaFromBackend;
        return usluga ? usluga.weight : 9999999;
    }

    get icon(): string {
        let usluga = this.uslugaFromBackend;
        return usluga ? usluga.icon : "coffee";
    }

    get iconColor(): string {
        let usluga = this.uslugaFromBackend;
        return usluga ? usluga.color : null;
    }

    get descr(): string {
        let usluga = this.uslugaFromBackend;
        return usluga ? usluga.descr : 'Неизвестная услуга';
    }

    get activeForSelfAccessId(): string {
        return "usluga_activeForSelf_" + this.id;
    }

    get selfAccess(): boolean {
        return RouteAccessChecker.checkAccess([this.activeForSelfAccessId]);
    }
}