let prefix = "OnlineChat";

function callConsole(method: string, args: any[]) {
    args.splice(0, 0, `[${prefix}]`);
    try {
        (window as any).console ? (console as any)[method].apply(window, args) : null;
    } catch (e) {

    }
}

export default class ConsoleWrapper {
    public static warn(...args: any[]) {
        callConsole("warn", args);
    }

    public static error(...args: any[]) {
        callConsole("error", args);
    }

    public static log(...args: any[]) {
        callConsole("log", args);
    }

    public static setPrefix(newPrefix: string) {
        prefix = newPrefix;
    }
}