
import Vue, {PropType} from 'vue'
import SvgIcon from './svg-icon.vue'
import {prefixCls} from '../constant'

const voidFn = () => {
}

export default Vue.extend({
    components: {
        SvgIcon
    },
    props: {
        zoomIn: {
            type: Function,
            default: voidFn
        },
        zoomOut: {
            type: Function,
            default: voidFn
        },
        rotateLeft: {
            type: Function,
            default: voidFn
        },
        rotateRight: {
            type: Function,
            default: voidFn
        },
        src: {
            type: String,
            required: true
        },
        resize: {
            type: Function,
            default: voidFn
        },
        mirror: {
            type: Function,
            default: voidFn
        },
        download: {
            type: Function as PropType<(url: string) => void>
        }
    },
    data() {
        return {
            prefixCls
        }
    }
})
