import Dictionary from "~/ts/library/Dictionary";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {InjectionKey} from "vue";

export default class FormHelper {
    static getDuplicateElementNames(form?: IForm): string[] {
        let result = [];
        if (form && form.sections) {
            let used: Dictionary<boolean> = {};
            for (let section of form.sections) {
                if (section && section.elements) {
                    for (let element of section.elements) {
                        if (element) {
                            let name = element.name;
                            if (used[name] && result.indexOf(name) === -1) {
                                result.push(name);
                            }
                            used[name] = true;
                        }
                    }
                }
            }
        }
        return result;
    }

}

export const MyFormGetValuesInjection = Symbol() as InjectionKey<() => Dictionary<any>>;