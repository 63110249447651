
import Vue from 'vue'
import SvgIcon from './svg-icon.vue'
import {prefixCls} from '../constant'

export default Vue.extend({
    components: {
        SvgIcon
    },
    data() {
        return {
            prefixCls
        }
    }
})
