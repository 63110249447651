import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";

export default class Platform extends AbstractEntity {
    id: string;
    descr: string;
    isOAuth: boolean;
    parentId?: string;
    isAuthDescrManual: boolean;
    hasChildPlatform: boolean;
    isReadyToUse: boolean;
    notGuaranteedText?: string[];
    icon: string;
    color: string;
    chatConfigForm?: IForm;
    isAuthEditable: boolean;
    isNeedPrepareAuthSave: boolean;
    knowledgeBaseRegisterId?: string;
    knowledgeBaseArticleHistoryId?: string;
    emptyChildErrorDescr?: string;
    isCrmPlatformWithSendMessages: boolean;
    isPlatformWithUsers: boolean;
    isCustomExternalBotPlatform: boolean;
}

export class GetPlatformListResponse extends AbstractEntity {
    platforms: Platform[];

    protected getFields(): AbstractEntityFields {
        return {
            platforms: Platform
        }
    }
}

export const INTEGRATION_PLATFORM_TYPE_MESSENGER = "messenger";
export const INTEGRATION_PLATFORM_TYPE_CRM = "crm";
export const INTEGRATION_PLATFORM_TYPE_ANALYTIC = "analytic";
export const INTEGRATION_PLATFORM_TYPE_COPILOT = "copilot";
export const INTEGRATION_PLATFORM_TYPE_TELEPHONY = "telephony";

