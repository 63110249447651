import AbstractHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/AbstractHtmlConstructorType";
import OnlineChatHtmlContentDisplay
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/onlineChatHtml/OnlineChatHtmlContentDisplay.vue";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {HTML_BLOCK_TYPE_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import {Component} from "vue";

export default class OnlineChatHtmlConstructorType extends AbstractHtmlConstructorType {
    get contentDisplayComponent(): Component {
        return OnlineChatHtmlContentDisplay;
    }

    getDefaultBlockLists(): IHtmlConstructorBlock<any>[] {
        return [
            {
                type: HTML_BLOCK_TYPE_TEXT,
                values: {}
            }
        ];
    }
}