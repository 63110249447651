let newLineTags = ["br", "div"];


export default class ContentEditableHelper {
    public static getElementText(el: HTMLElement, getNewLine: boolean = false): string {
        var text = '';
        // Text node (3) or CDATA node (4) - return its text
        if ((el.nodeType === 3) || (el.nodeType === 4)) {
            text = el.nodeValue;
            // If node is an element (1) and an img, input[type=image], or area element, return its alt text
        } else if ((el.nodeType === 1) && el.tagName.toLowerCase() == 'img') {
            text = el.getAttribute('alt') || '';
            // Traverse children unless this is a script or style element
        } else if ((el.nodeType === 1) && !el.tagName.match(/^(script|style)$/i)) {
            var children = el.childNodes;
            if (getNewLine) {
                let tagName = el.tagName.toLowerCase();
                if (newLineTags.indexOf(tagName) > -1) {
                    if (text.length || tagName == "br") {
                        text += "\n";
                    }
                }
            }
            for (var i = 0, l = children.length; i < l; i++) {
                text += this.getElementText(children[i] as HTMLElement, true);
            }
        }
        return text;
    };

}