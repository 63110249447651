import {ISenderFormContact, ISenderFormGroup} from "~/cabinet/vue/client/sender/send/Interfaces";
import LcabApiFetchListResult from "~/cabinet/ts/api/LcabApiFetchListResult";
import IPhonebookContactColumn from "~/cabinet/vue/client/phonebook/contacts/interfaces";
import PhonebookContactTableColumnFormatter
    from "~/cabinet/vue/client/phonebook/contacts/PhonebookContactTableColumnFormatter";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";

export default class SenderReceiverSelectHelper {
    static getFullNameByContact(contact: ISenderFormContact): string | null {
        let result: string[] = [];

        if (contact.f && contact.f.length) {
            result.push(contact.f);
        }
        if (contact.i && contact.i.length) {
            result.push(contact.i);
        }
        if (contact.o && contact.o.length) {
            result.push(contact.o);
        }

        return result.length ? result.join(" ") : null;
    }

    static getReceiverIdFieldByUslugaId(uslugaId: string): string | null {
        let usluga = SenderUslugi[uslugaId];
        return usluga ? usluga.recipientIdPhonebookField : null;
    }

    static isContactAvailableToSelect(uslugaId: string, contact: ISenderFormContact): boolean {
        let result = false;
        let contactAsAny = contact as any;
        let field = SenderReceiverSelectHelper.getReceiverIdFieldByUslugaId(uslugaId);
        if (field) {
            if (contactAsAny[field]) {
                if (typeof contactAsAny[field] != "string" || (contactAsAny[field] as string).length) {
                    result = true;
                }
            }
        } else {
            result = true;
        }
        return result;
    }

    static getSortedContactColumns(result: LcabApiFetchListResult<any>) {
        let sortedColumns: IPhonebookContactColumn[] = [];
        let emptyColumns: IPhonebookContactColumn[] = [];
        let items = result.items;
        let columns: IPhonebookContactColumn[] = result.originalResult.getData("columns", []);
        for (let column of columns) {
            let columnName = column.name;
            let flag = false;
            for (let item of items) {
                if (PhonebookContactTableColumnFormatter.format(column, item[columnName]) != null) {
                    flag = true;
                    break;
                }
            }
            (flag ? sortedColumns : emptyColumns).push(column);
        }
        sortedColumns.splice(sortedColumns.length, 0, ...emptyColumns);
        return sortedColumns;
    }

    static groupSortMethod(a: ISenderFormGroup, b: ISenderFormGroup) {
        if (a.count != b.count) {
            if (!a.count) {
                return 1;
            } else if (!b.count) {
                return -1;
            }
        }
        let aColor = a.color ?? "";
        let bColor = b.color ?? "";
        if (aColor != bColor) {
            return aColor > bColor ? -1 : 1;
        }
        return a.descr.toLowerCase() > b.descr.toLowerCase() ? 1 : -1;
    }
}