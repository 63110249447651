import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {Component} from "vue";

export default abstract class AbstractHtmlConstructorType {
    abstract get contentDisplayComponent(): Component;

    abstract getDefaultBlockLists(): IHtmlConstructorBlock<any>[];

    get isStyleEditableInContentEditor(): boolean {
        return true;
    }
}