import AbstractEntity from "~/ts/library/AbstractEntity";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {OrgDetailsForDisplayInterface} from "~/cabinet/vue/interface/documents/Interface";

export const AGENT_ACT_DOCUMENT_TYPE_ID = "agentAct";
export const CONTRACT_DOCUMENT_TYPE_ID = "contract";
export const ACT_DOCUMENT_TYPE_ID = "act";
export const BILL_DOCUMENT_TYPE_ID = "bill";

export const DOCUMENT_LIST_MASS_ACTION_DOWNLOAD = "download";
export const DOCUMENT_LIST_MASS_ACTION_CHANGE_STATUS = "changeStatus";
export const DOCUMENT_LIST_MASS_ACTION_PREPARE_FOR_LETTER = "prepareForLetter";


export class AbstractDocument extends AbstractEntity {
    id: string;
    numberDescr: string;
    documentDate: string;
    organization: {
        id: string
        ocode?: string;
        descr?: string;
    };
    details?: OrgDetailsForDisplayInterface;
    dealerDetails?: OrgDetailsForDisplayInterface;
    edo?: {
        isUseEdo: boolean,
        status?: {
            id: string,
            descr: string,
            color: string,
            rejectStatusDescr?: string,
            isCanResend: boolean
        },
        pdfUrl?: string
    };
    status: {
        descr: string;
        dateTime: string;
        isEditable: boolean;
        color: string;
        isReject: boolean;
        errorDescr?: string;
    };
    nextStatus?: {
        id: string,
        descr: string,
        dateTime: string
    };
    additionalSelection: any;
    scan?: string;
    scanInfo?: AbstractDocumentScanInterface;
    statusHistory: {
        color: string;
        dateTime: string;
        descr: string;
        manager?: string;
    }[];
    url?: string;
    inlineUrl?: string;
}

export interface AbstractDocumentScanInterface {
    dateTimeCreate: string;
    status?: {
        descr: string;
        errorDescr: string;
        isNeedErrorDescr: boolean;
        isSuccess: boolean;
        isModerate: boolean;
        isReject: boolean
    }
}

class AbstractFinanceDocument extends AbstractDocument {
    sum: number;
    currencyId: string;
    isWhite: boolean;
}

export class Bill extends AbstractFinanceDocument {
    paymentStatus?: {
        descr: string;
        color: string;
        isPaid: boolean;
        isPartPaid: boolean;
    };
    isTarifLineCredit: boolean;
    acts: Act[];
    paidSum: number;
    unpaidSum: number;
}

export class AgentAct extends AbstractFinanceDocument {

}

export class Act extends AbstractFinanceDocument {
    descr: string;
    buhHeldStatus?: {
        color: string;
        descr: string;
    };
    spentSumByThisPayment?: number;
}

export interface ReceiptInterface {
    id: string;
    descr: string;
    url?: string;
    status?: {
        id: string;
        descr: string;
        color: string;
    }
}


export class RecAct extends AbstractFinanceDocument {
    dateStart: string;
}


export function getDocumentDownloadUrl(documentTypeId: string, documentId: string): string {
    return LcabApiRequest.getUrl(`/api/dealer/documents/${documentTypeId}/list/${documentId}/downloadDocument`);
}

export interface DocumentListExposeInterface {
    reload: () => void,
    selectMassAction: (actionId: string, parent?: AbstractDocument) => void,
    deleteDocument: (document: AbstractDocument) => Promise<void>
}