import {ISenderMessageRecipient} from "~/cabinet/vue/client/sender/report/allMessages/SenderMessageInterface";
import {PropType} from "vue";

export function useSenderMessageRecipientProps() {
    return {
        recipient: {
            type: Object as PropType<ISenderMessageRecipient>,
            required: true
        },
        clickable: {
            type: Boolean
        }
    };
}