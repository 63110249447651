import Dictionary from "./Dictionary";

class Url {
    public hash: string;        // #asd
    public host: string;        // localhost:257
    public hostname: string;    // localhost
    public href: string;        // http://localhost:257/deploy/?asd=asd#asd
    public origin: string;      // http://localhost:257
    public pathname: string;    // /deploy/
    public port: string;        // 257
    public protocol: string;    // http:
    public search: string;      // ?asd=asd

    public constructor(url: string) {
        let m = url.match(/^(([^:\/?#]+:)?(?:\/\/(([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/);
        this.hash = m[8] || "";
        this.host = m[3] || "";
        this.hostname = m[4] || "";
        this.href = m[0] || "";
        this.origin = m[1] || "";
        this.pathname = m[6] || (m[1] ? "/" : "");
        this.port = m[5] || "";
        this.protocol = m[2] || "";
        this.search = m[7] || "";

        if (this.protocol.length === 2) {
            this.protocol = "file:///" + this.protocol.toUpperCase();
            this.origin = this.protocol + "//" + this.host;
        }
        this.href = this.origin + this.path;
    }

    get path() {
        return this.pathname + this.search + this.hash;
    }

    public getQueryStringParam(key: string): string | null {
        let result = this.parseQueryString() as any;
        return result[key] as string | null;
    }

    public parseQueryString(): Dictionary<string> {
        let query: any = {};
        let pairs = (this.search[0] === '?' ? this.search.substr(1) : this.search).split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            query[Url.safeDecodeURIComponent(pair[0])] = Url.safeDecodeURIComponent(pair[1] || '');
        }
        return query;
    }

    public static safeDecodeURIComponent(str: string) {
        try {
            str = decodeURIComponent(str);
        } catch (e) {

        }
        return str;
    }

    public static safeDecodeURI(str: string) {
        try {
            str = decodeURI(str);
        } catch (e) {

        }
        return str;
    }

    get pathnameArray() {
        return this.pathname.substr(1).split("/");
    }

    public static toQueryString(dictionary: Dictionary<string>): string {
        return Object
            .keys(dictionary)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(dictionary[key])}`)
            .join('&');
    }

    public static getDecodedCurrentUrl() {
        //TODOL допилить пуникод
        return window.location.href;
    }
}

export default Url;