import {IField} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {ExtractPropTypes, getCurrentInstance, PropType} from "vue";

export function useFormElementProps<Field extends IField>() {
    return {
        value: {},
        field: {
            type: Object as PropType<Field>,
            required: true
        },
        contentVariables: {
            type: Array as PropType<ContentVariableInterface[]>
        },
        readonly: {
            type: Boolean
        },
        autoEmitValueWhenEmpty: {
            type: Boolean,
            default: true
        },
        disallowOptionIcon: {
            type: Boolean
        },
        textReadonly: {
            type: Boolean
        },
        formModel: {
            type: Object
        },
        showIconInPrefix: {
            type: Boolean
        },
        clearable: {
            type: Boolean
        }
    }
}

export function useFormElement(props: Readonly<ExtractPropTypes<ReturnType<typeof useFormElementProps>>>, p?: {
    focus?: () => void
}) {
    if (!p) {
        p = {};
    }

    if (props.value == null && props.field.defaultValue != null) {
        let instance = getCurrentInstance().proxy;
        instance.$emit("input", props.field.defaultValue);
    }

    return {
        expose: {
            focus: p.focus ? p.focus : () => {
            }
        }
    }
}

export interface FormElementExposeInterface {
    focus: () => void
}