import {__, __sprintf} from "~/ts/library/Translate";

let TranslatePlugin = {
    install: function (Vue: any) {
        Vue.prototype.__ = __;
        Vue.prototype.$sprintf = __sprintf;
    }
};

export default TranslatePlugin;
