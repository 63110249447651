import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import ISavedBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/ISavedBlockValues";
import ContentsBlockDisplay
    from "~/cabinet/vue/interface/htmlConstructor/blocks/contentsBlock/ContentsBlockDisplay.vue";
import ContentsBlockForm from "~/cabinet/vue/interface/htmlConstructor/blocks/contentsBlock/ContentsBlockForm.vue";
import {Component} from "vue";


export default class ContentsBlockType extends AbstractBlockType<ISavedBlockValues, any> {

    get displayComponent(): Component {
        return ContentsBlockDisplay;
    }

    get formComponent(): Component {
        return ContentsBlockForm;
    }

}
