import AbstractDelay from "./AbstractDelay";

class Timeout extends AbstractDelay {
    protected clearMethod: (id: number) => void = clearTimeout;
    protected setMethod: (callback: () => void, delay: number) => number = (callback: () => void, delay: number): number => {
        let id = setTimeout(() => {
            if (id) {
                this.clear(id);
            }
            callback();
        }, delay) as any as number;
        return id;
    };


    set(callback: () => void, delay: number): number {
        let that = this;
        let id: number;
        let newCallback = function () {
            that.clear(id);
            callback();
        };
        return super.set(newCallback, delay);
    }
}


export const Timeouts = new Timeout();