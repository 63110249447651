import CoreUI from "~/core-ui/ts/CoreUI";
import App from "~/cabinet/vue/layout/Cabinet.vue";
import router from "../routes/Router";
import {IInitialAppData} from "~/cabinet/ts/store/AccountStore";
//import {Event} from '@sentry/types/dist';
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import SetInitialAppData from "~/cabinet/ts/initialize/SetInitialAppData";
import CsrfToken from "~/core-ui/ts/request/CsrfToken";
import "~/cabinet/scss/index.scss";
import Vue from "vue";
import LoadingPlugin from "~/ts/vuePlugins/LoadingPlugin";
import LcabApiPlugin from "~/ts/vuePlugins/LcabApiPlugin";
import {CabinetUiHelpersMixin} from "~/ts/vuePlugins/CabinetUiHelpersMixin";
import Container from "element-ui/lib/container.js";
import Main from "element-ui/lib/main.js";
import Aside from 'element-ui/lib/aside.js';
import Header from 'element-ui/lib/header.js';
import Footer from 'element-ui/lib/footer.js';
import {setInitialUrl} from "~/cabinet/ts/InitialUrl";

setInitialUrl();

let meta: HTMLMetaElement = document.querySelector("meta[name='csrf-token']");
if (meta) {
    let csrfTokenFromMeta = meta.content;
    if (csrfTokenFromMeta && csrfTokenFromMeta.length) {
        CsrfToken.set(csrfTokenFromMeta);
    }
}

let container = document.getElementById("app").getElementsByTagName("App")[0];
if (container) {
    let initAttribute = container.getAttribute("data");
    if (initAttribute) {
        let initData: IInitialAppData = JSON.parse(initAttribute);
        SetInitialAppData(initData);
    }
}
let $router = router();

LcabLayoutStore.router.value = $router;
let coreUI = new CoreUI(App, $router);
/*
if (window.location.port != "8088") {
    coreUI.setupSentry("https://8b657de41b0c4f77bcb8a047276fa396@o384149.ingest.sentry.io/6168798", (event: Event) => {
        let organization = AccountStore.organization.value;
        if (organization) {
            event.user = {
                ...(event.user ? event.user : {}),
                username: organization.descr,
                id: organization.ocode,
            };

            event.tags = {
                ...(event.tags ? event.tags : {}),
                dealerId: organization.dealerId,
                isDealer: organization.isDealer ? "true" : "false"
            };
        }


        return event;
    });
}
*/

Vue.mixin(CabinetUiHelpersMixin);
//Vue.use(TranslatePlugin);
Vue.use(LoadingPlugin);
Vue.use(LcabApiPlugin);

Vue.component("el-container", Container);
Vue.component("el-main", Main);
Vue.component("el-header", Header);
Vue.component("el-footer", Footer);
Vue.component("el-aside", Aside);


coreUI.make(() => {

});

export default () => {
};