import IOrganization from "~/cabinet/ts/data/organization/IOrganization";
import AbstractAnalyticCounter from "~/cabinet/ts/service/analytics/AbstractAnalyticCounter";
import YandexMetrika from "~/cabinet/ts/service/analytics/YandexMetrika";
import MailRuPixel from "~/cabinet/ts/service/analytics/MailRuPixel";

class CabinetAnalyticCountersSender {
    private counters: AbstractAnalyticCounter<any>[] = [];

    constructor() {
        this.counters.push(...[
            new YandexMetrika(),
            new MailRuPixel()
        ]);
    }

    private organization: IOrganization = null;


    public isAvailable(): boolean {
        for (let counter of this.counters) {
            if (counter.getCounterObject()) {
                return true;
            }
        }
        return false;
    }

    public setUserParams(organization?: IOrganization) {
        if (organization) {
            this.organization = organization;
        }
        if (this.organization) {
            for (let counter of this.counters) {
                counter.setUserParams(this.organization.ocode, {
                    descr: this.organization.descr
                })
            }
        }
    }

    public async reachGoal(eventId: string, sum?: number, currencyId?: string) {
        for (let counter of this.counters) {
            counter.reachGoal(eventId, sum, currencyId);
        }
    }
}

let CabinetAnalyticCountersSenderInstance = new CabinetAnalyticCountersSender();
export default CabinetAnalyticCountersSenderInstance;