import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import StructureDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureDisplay.vue";
import StructureForm from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureForm.vue";
import IStructureBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import {Component} from "vue";


export default class StructureBlockType extends AbstractBlockType<IStructureBlockValues, any> {


    get displayComponent(): Component {
        return StructureDisplay;
    }

    get formComponent(): Component {
        return StructureForm;
    }



    hasLeftRightPadding(value: IStructureBlockValues): boolean {
        return false;
    }

}
