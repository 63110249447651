import { render, staticRenderFns } from "./SenderReceiversBlock.vue?vue&type=template&id=6a5adc05&scoped=true&"
import script from "./SenderReceiversBlock.vue?vue&type=script&lang=ts&setup=true&"
export * from "./SenderReceiversBlock.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./SenderReceiversBlock.vue?vue&type=style&index=0&id=6a5adc05&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5adc05",
  null
  
)

export default component.exports