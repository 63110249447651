import {SenderSourceInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {SenderContentFormModelInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";

export interface IViberSource extends SenderSourceInterface {
    avatarImg: string,
    sourceType?: { id: string, descr: string },
    mobileForTest: string[],
    isChat: boolean;
}

export const VIBER_CONTENT_TYPE_TEXT = "text";
export const VIBER_CONTENT_TYPE_IMAGE = "image";
export const VIBER_CONTENT_TYPE_TEXT_BUTTON = "textWithButton";
export const VIBER_CONTENT_TYPE_TEXT_IMAGE_BUTTON = "textWithImageAndButton";

export interface IViberContentFormValue extends SenderContentFormModelInterface {
    type: "text" | "image" | "textWithButton" | "textWithImageAndButton",
    data: IViberTextContentFormValue | IViberImageContentFormValue | IViberTextWithButtonContentFormValue | IViberTextImageButtonContentFormValue;
}

export interface IViberTextContentFormValue {
    text: IViberTextValue;
}


export interface IViberTextValue {
    text: string;
}

export interface IViberTextWithButtonContentFormValue extends IViberTextContentFormValue {
    button: IViberButtonValue
}

export interface IViberButtonValue {
    label: string,
    link: string
}

export interface IViberImageValue {
    fileId?: string;
    fileName?: string;
}


export interface IViberImageContentFormValue {
    image: IViberImageValue
}

export interface IViberTextImageButtonContentFormValue extends IViberTextWithButtonContentFormValue, IViberImageContentFormValue {
}