import Dictionary from "~/ts/library/Dictionary";
import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import Online from "~/cabinet/ts/data/usluga/Online";
import {
    USLUGA_ID_AGENT,
    USLUGA_ID_CALL_GATE,
    USLUGA_ID_CALL_PASSWORD,
    USLUGA_ID_ONLINE,
    USLUGA_ID_PCOUNT,
    USLUGA_ID_RECEIVESMS
} from "~/cabinet/ts/Constant";
import Agent from "~/cabinet/ts/data/usluga/Agent";
import ReceiveSms from "~/cabinet/ts/data/usluga/ReceiveSms";
import Pcount from "~/cabinet/ts/data/usluga/Pcount";
import ObjectHelper from "~/ts/library/ObjectHelper";
import SenderUsluga from "~/cabinet/ts/data/usluga/SenderUsluga";
import CallPassword from "~/cabinet/ts/data/usluga/CallPassword";
import CallGate from "~/cabinet/ts/data/usluga/CallGate";

const Uslugi: Dictionary<Usluga> = {
    ...SenderUslugi,
    [USLUGA_ID_ONLINE]: Online,
    [USLUGA_ID_AGENT]: Agent,
    [USLUGA_ID_RECEIVESMS]: ReceiveSms,
    [USLUGA_ID_PCOUNT]: Pcount,
    [USLUGA_ID_CALL_PASSWORD]: CallPassword,
    [USLUGA_ID_CALL_GATE]: CallGate
};

export default class UslugaMapper {
    public static find(uslugaId: string): Usluga | null {
        return Uslugi[uslugaId];
    }

    public static byWeight(): Usluga[] {
        return ObjectHelper.arrayFromDictionary(Uslugi).sort((a, b) => {
            return a.weight > b.weight ? 1 : -1;
        })
    }

    public static senderByWeight(): SenderUsluga[] {
        return this.byWeight().filter(usluga => usluga instanceof SenderUsluga) as SenderUsluga[];
    }
};