import Vue, {Component} from "vue";
import VueRouter from "vue-router";
import IconPlugin from "~/ts/vuePlugins/IconPlugin";
import "~/ts/library/Date";
import DateTimePlugin from "~/ts/vuePlugins/DateTimePlugin";
import * as moment from "~/node_modules/moment";
//import SentryLogger from "~/ts/library/SentryLogger";
//import {Event} from '@sentry/types/dist';
import NumbersPlugin from "~/ts/vuePlugins/NumbersPlugin";
import StringPlugin from "~/ts/vuePlugins/StringPlugin";
import InitElemeUI from "./InitElemeUI";
import InitMyComponents from "./InitMyComponents";
import TranslatePlugin from "~/ts/vuePlugins/TranslatePlugin";
import ShowMessagePlugin from "~/ts/vuePlugins/ShowMessagePlugin";
import MyRouterViewPlugin from "~/core-ui/ts/router/MyRouterViewPlugin";
import DisposableEventsPlugin from "~/ts/vuePlugins/DisposableEventsPlugin";
import {CoreUiHelpersMixin} from "~/core-ui/ts/CoreUiHelpersMixin";
//import * as SentryIntegrations from "@sentry/integrations";
import FilteredListPlugin from "~/ts/vuePlugins/FilteredList";


require('moment/locale/ru');


moment.locale("ru");

/*
if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function () {
        attachFastClick.attach(document.body, {
            tapDelay: 50
        });
    }, false);
}
*/

/*
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);*/


InitElemeUI.make();
InitMyComponents.make();

Vue.use(VueRouter);
Vue.use(MyRouterViewPlugin);
Vue.use(ShowMessagePlugin);
Vue.use(DateTimePlugin);
Vue.use(FilteredListPlugin);
Vue.use(IconPlugin);
Vue.use(NumbersPlugin);
Vue.use(StringPlugin);
Vue.use(TranslatePlugin);
Vue.use(DisposableEventsPlugin);
Vue.mixin(CoreUiHelpersMixin);


export default class CoreUI {
    private component: Component;
    private router: VueRouter;

    constructor(component: Component, router: VueRouter) {
        this.component = component;
        this.router = router;
    }


    /*
    setupSentry(dsn: string, beforeSend?: (event: Event) => Event) {
        new SentryLogger({
            dsn,
            beforeSend,
            integrations: [
                new SentryIntegrations.Vue({
                    Vue,
                    attachProps: true,
                    logErrors: true
                })
            ],
        });
    }
    */

    make(beforeHook?: () => void) {
        if (beforeHook) {
            beforeHook();
        }

        new Vue({
            components: {
                App: this.component
            },
            router: this.router
        }).$mount('#app');

    }


}