import StringHelper from "~/ts/library/StringHelper";

let StringPlugin = {
    install: function (Vue: any) {


        Vue.filter('ucfirst', function (value: string) {
            return StringHelper.capitalize(value);
        });

        Vue.filter('getSclon', function (value: number, i: string, r: string, rm: string) {
            return StringHelper.getSclon(value, i, r, rm);
        });
    }
};

export default StringPlugin;

