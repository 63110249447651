import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";


let LcabApiPlugin = {
    install: function (Vue: any) {
        //Vue.prototype.$lcab = LcabApiRequest.make;
        Vue.prototype.$fetch = LcabApiRequest.fetch;
        /*
        <T extends AbstractEntity>(options: LcabApiRequestConfig, dataType?: AbstractEntityBuilder<T>): Promise<LcabApiResult<T>> => {
            options.loading = true;
            return (new LcabApiRequest(options, dataType)).send();
        };
        */

        Vue.prototype.$fetchList = LcabApiRequest.fetchList;

        Vue.prototype.$save = LcabApiRequest.save;
    }
};


export default LcabApiPlugin;
