import LcabApiResult from "./LcabApiResult";
import {LcabLayoutStore} from "~/cabinet/ts/store/LcabLayoutStore";
import AbstractEntity, {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import AbstractApiRequest, {ApiRequestConfig} from "~/core-ui/ts/request/AbstractApiRequest";
import LcabApiFetchListResult from "~/cabinet/ts/api/LcabApiFetchListResult";

interface LcabApiRequestConfig extends ApiRequestConfig {
}

export {LcabApiRequestConfig};

export default class LcabApiRequest<DataType> extends AbstractApiRequest<LcabApiResult<DataType>, DataType> {
    public static getUrl(path: string) {
        if (path.indexOf("/cmd/") !== 0 && path.indexOf("/json/") !== 0) {
            path = LcabLayoutStore.appUrlPrefix.value + path;
        }
        return path;
    }

    protected getUrl(path: string): string {
        return LcabApiRequest.getUrl(path);
    }

    protected createApiResultByResponse(response: any, dataType?: AbstractEntityBuilder<DataType>): LcabApiResult<DataType> {
        return new LcabApiResult(response, dataType);
    }

    static async save<T>(options: LcabApiRequestConfig) {
        options.saving = true;
        let result = await (new this<T>(options)).send();
        result.showMessage();
        return result;
    }

    static async fetchList<T extends AbstractEntity>(options: LcabApiRequestConfig, dataType?: AbstractEntityBuilder<T>): Promise<LcabApiFetchListResult<T>> {
        if (options.loading == null) {
            options.loading = true;
        }
        let result = await (new this(options)).send();
        return new LcabApiFetchListResult<T>(result, dataType);
    }

    async send<T>(): Promise<LcabApiResult<DataType>> {
        this.addHeader("X-filters-without-operations", "1");
        return super.send();
    }

    static async fetch<T>(options: LcabApiRequestConfig, dataType?: AbstractEntityBuilder<T>): Promise<LcabApiResult<T>> {
        options.loading = true;
        return (new this<T>(options, dataType)).send();
    }

    protected logError(options: ApiRequestConfig, result: any, message: string = null) {
        (new LcabApiRequest({
            url: "/json/anonymous/cabinet/jsonLog/log",
            p: {
                url: options.url,
                params: options.p,
                info: result
            },
            silentError: true,
            silent: true
        })).send();
    }
}

