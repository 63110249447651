export function toggleArrayElement(array: any[], element: any, value: boolean, pushElement?: any) {
    let changed = false;
    let index = array.indexOf(element);
    if (value && index == -1) {
        array.push(pushElement ? pushElement : element);
        changed = true;
    } else if (index > -1) {
        if (!value) {
            array.splice(index, 1);
            changed = true;
        } else if (element != pushElement && pushElement != undefined) {
            array.splice(index, 1, pushElement);
            changed = true;
        }
    }
    return changed;
}


export function removeArrayElement(array: any[], element: any) {
    toggleArrayElement(array, element, false);
}


export function addArrayElement(array: any[], element: any) {
    toggleArrayElement(array, element, true);
}