import { render, staticRenderFns } from "./ButtonForm.vue?vue&type=template&id=654c7c96&scoped=true&"
import script from "./ButtonForm.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ButtonForm.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "654c7c96",
  null
  
)

export default component.exports