function getNow() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
}

function getYesterday() {
    let now = getNow();
    now.setDate(now.getDate() - 1);
    return now;
}

let DateTimePlugin = {
    install: function (Vue: any) {
        //Vue.prototype.$lcab = LcabApiRequest.make;
        Vue.prototype.$dateTimeFuture = (date: Date) => {
            return date <= getNow();
        };

        Vue.prototype.$dateTimeFromToday = (date: Date) => date <= getYesterday();

        Vue.prototype.$dateTimePast = (date: Date) => {
            return date >= getNow();
        };
    }
};

export default DateTimePlugin;
