import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {USLUGA_ID_ONLINE} from "~/cabinet/ts/Constant";

const Online: Usluga = Usluga.new({
    id: USLUGA_ID_ONLINE,
    badgeId: "onlineUslugaSection",
    dealer: {
        config: {
            tarifComponent: () => import(/* webpackChunkName: "dealer-online" */ '~/cabinet/vue/dealer/config/uslugi/tarif/online/OnlineTarifList.vue')
        }
    },
    client: {
        tarifComponent: () => import(/* webpackChunkName: "client-online" */ '~/cabinet/vue/client/tarif/OnlineTarifs.vue')
    }
});

export default Online;

export const ONLINE_LICENSE_TYPE_PACK = "pack";
export const ONLINE_LICENSE_TYPE_PERIOD = "period";