import {PropType} from "vue";
import {TypeDataInterface} from "~/cabinet/ts/data/online/config/tabs/TabConfigData";

export function useOnlineTabFormProps<Value, TypeData extends TypeDataInterface = TypeDataInterface>() {
    return {
        value: {
            type: Object as PropType<Value>,
            required: true
        },
        data: {
            type: Object as PropType<TypeData>,
            required: true
        },
        siteId: {
            type: String,
            required: true
        }
    }
}

export const EDIT_ONLINE_TAB_ID = "online";
export const EDIT_OFFLINE_TAB_ID = "offline";
export const EDIT_REVIEWS_TAB_ID = "reviews";