import ReactiveFilterableRequest from "~/core-ui/ts/request/ReactiveFilterableRequest";
import AbstractReactiveListEntity from "~/core-ui/ts/request/AbstractReactiveListEntity";
import AbstractApiRequest from "~/core-ui/ts/request/AbstractApiRequest";

export default class ReactiveFilterableListRequest<Item, AdditionalFiltersResponseData = {}, AdditionalResponseData = {}> extends ReactiveFilterableRequest<AbstractReactiveListEntity<Item>, AdditionalFiltersResponseData, AdditionalResponseData> {
    private page: number;
    private onPage: number;

    private defaultLimit: number;

    constructor(dataRequest: AbstractApiRequest<any, Item>, filtersRequest?: AbstractApiRequest<any, AdditionalFiltersResponseData>) {
        dataRequest.dataType = AbstractReactiveListEntity.newClass(dataRequest.dataType) as any;
        super(dataRequest as any, filtersRequest as any);
        this.defaultLimit = 30;
    }

    setDefaultLimit(limit: number) {
        this.defaultLimit = limit;
        return this;
    }

    getCurrentPage() {
        return this.page;
    }

    async make(page?: number, onPage?: number) {

        if (typeof page != "number") {
            page = this.page ? this.page : 0;
        }
        if (typeof onPage != "number") {
            onPage = this.onPage ? this.onPage : this.defaultLimit;
        }


        if (!this.requestConfig.p) {
            this.requestConfig.p = {};
        }
        let p = this.requestConfig.p;
        p.limit = onPage;
        p.page = page;


        return super.make();
    }

    data: AbstractReactiveListEntity<Item> & AdditionalResponseData;

    protected processData(data: AbstractReactiveListEntity<Item> & AdditionalResponseData) {
        super.processData(data);
        this.onPage = data.onPage;
        this.page = data.page;
    }

}