import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import FiltersResponse from "~/cabinet/ts/data/system/api/FiltersResponse";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import AbstractApiRequest from "~/core-ui/ts/request/AbstractApiRequest";

export default class ReactiveFilterableRequest<DataClass, ParamsClass = {}, AdditionalData = {}> extends ReactiveRequest<DataClass & AdditionalData> {
    private _filtersRequest: ReactiveRequest<FiltersResponse<ParamsClass>>;

    constructor(dataRequest: AbstractApiRequest<any, DataClass & AdditionalData>, filtersRequest?: AbstractApiRequest<any, ParamsClass>) {
        super(dataRequest, false);
        if (filtersRequest) {
            let filtersResultType: any = FiltersResponse;
            let paramsType = filtersRequest.dataType;
            if (paramsType != null) {
                filtersResultType = class extends FiltersResponse<ParamsClass> {
                    protected get paramsClass(): AbstractEntityBuilder<ParamsClass> {
                        return paramsType;
                    }
                }
                filtersRequest.dataType = filtersResultType;
            }
            this._filtersRequest = (new ReactiveRequest(filtersRequest as any, true));
        }
    }

    public get filters() {
        return this.filtersRequest.data ? this.filtersRequest.data.filters : null;
    }

    public get params(): ParamsClass | null {
        return this.filtersRequest.data ? this.filtersRequest.data.params : null;
    }

    public get disabled() {
        return this.filtersRequest && !this.filtersRequest.data;
    }

    public get filtersRequest() {
        return this._filtersRequest;
    }

    get isLoading(): boolean {
        return this.loading || (this.filtersRequest && this.filtersRequest.isLoading);
    }

}