import {ExtractPropTypes} from "vue";
import {SenderFormReceiversInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import useObjectModel from "~/ts/vuePlugins/useObjectModel";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";

export function useSenderReceiversComponent(
    props: Readonly<ExtractPropTypes<{
        value?: SenderFormReceiversInterface
    }>>
) {
    return useObjectModel(props, () => ({
        contactbookDataSourceId: AccountStore.defaultContactbookDataSourceId.value,
        fromExcelFile: null,
        groups: [],
        contacts: [],
        filters: {},
        stopListEnabled: true,
        organizationFilters: {},
        otherRecipientsId: "",
        stopListGroupIds: [],
        limit: {
            enabled: false,
            first: 1,
            last: 50,
            random: false
        }
    }))
}