var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'with-border': _vm.withBorder
    }},_vm._l((_setup.computedTags),function(tag,key){return _c('el-tooltip',{key:key,attrs:{"disabled":!_setup.isFilterable,"content":"Кликните, чтобы отфильтровать по этому тэгу"}},[(_vm.maximum && key == _vm.maximum)?[_c('el-tag',{class:{
                    'mr-1': true,
                    tag: true
                },attrs:{"type":_vm.type,"effect":_vm.effect,"size":"small"}},[_vm._v("\n                +"+_vm._s(_setup.computedTags.length - _vm.maximum)+"\n            ")])]:(!_vm.maximum || key < _vm.maximum)?[_c('el-tag',{class:{
                    'mr-1': true,
                    clickable: _setup.isFilterable || _vm.clickable,
                    'tag': true
                },style:(_setup.getTagStyle(tag)),attrs:{"type":_vm.type,"effect":_vm.effect,"size":"small"},nativeOn:{"click":function($event){return _setup.onClick($event, tag.tag)}}},[_vm._v("\n                "+_vm._s(tag.tag)+"\n            ")])]:_vm._e()],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }