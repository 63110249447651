import { render, staticRenderFns } from "./FilterBlockForm.vue?vue&type=template&id=296f84e4&scoped=true&"
import script from "./FilterBlockForm.vue?vue&type=script&lang=ts&setup=true&"
export * from "./FilterBlockForm.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296f84e4",
  null
  
)

export default component.exports