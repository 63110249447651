
import DealerRoutesBuilder from "~/cabinet/ts/routes/DealerRoutesBuilder";
import VueRouter from "vue-router";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import Dictionary from "~/ts/library/Dictionary";
import Random from "~/ts/library/Random";
import {useCoreUiHtmlify, useCoreUiHtmlifyProps} from "~/core-ui/vue/ui/useCoreUiHtmlify";
import {useRouter} from "vue-router/composables";
import {ExtractPropTypes} from "vue";

let router: VueRouter;
let ocodeRegexp = /8000\d{7}/gim
const orgCardClassName = "organization-card-router-link";
const orgCardOcodeAttribute = "data-ocode";


export default {
    name: "CabinetHtmlify",
    props: {
        ...useCoreUiHtmlifyProps()
    },
    setup(props: Readonly<ExtractPropTypes<ReturnType<typeof useCoreUiHtmlifyProps>>>) {
        router = useRouter();

        let htmlify = useCoreUiHtmlify(props, {
            additionalLinkify: (html: string) => {
                if (AccountStore.isDealer.value) {
                    let tags: Dictionary<string> = {};
                    html = html.replace(/(<.*>.*<\/.*>)/gim, tag => {
                        let key = '%' + Random.uid() + '%';
                        tags[key] = tag;
                        return key;
                    });

                    html = html.replace(ocodeRegexp, ocode => {
                        let a = document.createElement("a");
                        a.innerText = ocode;
                        a.className = `cursor-pointer`;
                        a.setAttribute(orgCardOcodeAttribute, ocode);
                        a.setAttribute("tabindex", "");
                        a.href = `/cabinet/dealer/orgs/card/${ocode}/main`;
                        a.setAttribute("onclick", `window.openOrgCardByOcode('${ocode}'); return false`);
                        return a.outerHTML;
                    });
                    for (let key in tags) {
                        if (tags.hasOwnProperty(key)) {
                            html = html.split(key).join(tags[key]);
                        }
                    }
                }
                return html;
            }
        });

        return htmlify.render;
    }
};

(window as any).openOrgCardByOcode = function (ocode: string) {
    router.push(DealerRoutesBuilder.getOrgCardRoute(ocode));
};


document.addEventListener("click", function (e: MouseEvent) {
    if (e.button === 0) {
        let element = e.target as HTMLElement;
        if (element.classList.contains(orgCardClassName)) {
            let ocode = element.getAttribute(orgCardOcodeAttribute);
            if (ocode && ocode.length) {
                router.push(DealerRoutesBuilder.getOrgCardRoute(ocode));
            }
        }
    }
})
