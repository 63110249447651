import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import {IHeaderBlockValues} from "~/cabinet/vue/interface/htmlConstructor/blocks/header/IHeaderBlockValues";
import HeaderDisplay from "~/cabinet/vue/interface/htmlConstructor/blocks/header/HeaderDisplay.vue";
import HeaderForm from "~/cabinet/vue/interface/htmlConstructor/blocks/header/HeaderForm.vue";
import {Component} from "vue";


export default class HeaderBlockType extends AbstractBlockType<IHeaderBlockValues, any> {


    get displayComponent(): Component {
        return HeaderDisplay;
    }

    get formComponent(): Component {
        return HeaderForm;
    }




    get isBlockWithoutPadding(): boolean {
        return true;
    }

    hasLeftRightPadding(value: IHeaderBlockValues): boolean {
        return false;
    }
}