import {removeArrayElement} from "~/ts/library/ToggleArrayElement";

abstract class AbstractDelay {
    private list: number[] = [];
    protected abstract setMethod: (callback: () => void, delay: number) => number;

    protected abstract clearMethod: (id: number) => void;

    public set(callback: () => void, delay: number): number {
        let id = this.setMethod.apply(window, [callback, delay]);
        this.list.push(id);
        return id;
    }

    public setDisposable(callback: () => void, delay: number): () => void {
        let id = this.set(callback, delay);
        return () => this.clear(id);
    }

    public clear(id: number) {
        removeArrayElement(this.list, id);
        /*
        let index = this.list.indexOf(id);
        if (index > -1) {
            this.list.splice(index, 1);
        }
         */
        return this.clearMethod.apply(window, [id]);
    }

    public clearAll() {
        for (let i = 0; i < this.list.length; i++) {
            this.clear(this.list[i]);
        }
    }
}

export default AbstractDelay;