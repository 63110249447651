class Events {
    public static ready() {
        return new Promise((resolve) => {
            if (/*document.attachEvent ? document.readyState === "complete" : */document.readyState !== "loading") {
                resolve(null);
            } else {
                document.addEventListener('DOMContentLoaded', resolve);
            }
        });
    }

    private static unlistenArray: (() => void)[] = [];

    public static addEventListener(event: string, element: HTMLElement | Window | Document | Element, cb: EventListener, options?: boolean | AddEventListenerOptions) {
        if (options == null) options = false;

        let events = this.getEvents(event);
        for (let event of events) {
            element.addEventListener(event, cb, options);
        }

        var unlisten = () => {
            this.removeEventListener(event, element, cb);
            let index = this.unlistenArray.indexOf(unlisten);
            if (index > -1) {
                this.unlistenArray.splice(index, 1);
            }
        };
        this.unlistenArray.push(unlisten);
        return unlisten;
    }

    public static once(event: string, element: HTMLElement | Window | Document, cb: EventListener, f?: any)
    {
        let unlisten = this.addEventListener(event, element, function(e: any) {
            unlisten();
            cb.apply(this, [e]);
        }, f);
        return unlisten;
    }

    private static getEvents(event: string): string[] {
        let result = [];
        for (let item of event.split(" ")) {
            if (item.length) {
                result.push(item);
            }
        }
        return result;
    }

    public static removeEventListener(event: string, element: HTMLElement | Window | Document | Element, cb: EventListener) {
        let events = this.getEvents(event);
        for (let event of events) {
            element.removeEventListener(event, cb);
        }
    }

    public static removeAllEventListeners() {
        for (let unlisten of this.unlistenArray) {
            unlisten();
        }
        this.unlistenArray = [];
    }
}

export default Events;