import {
    PHONEBOOK_COLUMN_NAME_EMAIL,
    PHONEBOOK_COLUMN_NAME_FIRSTNAME,
    PHONEBOOK_COLUMN_NAME_LASTNAME,
    PHONEBOOK_COLUMN_NAME_MIDDLENAME,
    PHONEBOOK_COLUMN_NAME_PHONE
} from "~/cabinet/vue/client/phonebook/contacts/constants";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";
import {AsyncVueComponent} from "~/ts/vuePlugins/Interface";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {IForm} from "~/cabinet/vue/interface/form/elements/Interfaces";
import {InjectionKey, Ref} from "vue";
import Dictionary from "~/ts/library/Dictionary";

export const DEFAULT_SENDER_FORM_ID = "cabinetSend";


export interface ISenderFormGroup {
    id: number;
    descr: string;
    color: string;
    count: number;
    isHidden: boolean;
    isStop: boolean;
}

export interface LicenserEntityInterface {
    isLicenseActive: boolean;
    licenseList: ILicense[];
    lastActiveLicense?: ILicense
}

export interface SenderSourceInterface extends LicenserEntityInterface {
    id: string;
    descr: string;
    isDefault: boolean;
    isLicenseUsed: boolean;
    licenseEntityId?: string;
    prepaymentPrice?: {
        sum: number,
        currencyId: string
    };
    prepaymentExpensePrice?: {
        sum: number,
        currencyId: string
    };
    cc: number;
    status?: {
        id: string,
        descr: string,
        isActive: boolean,
        isWaitingActivate: boolean,
        comment?: string,
        color: string
    };
    isOrgDefault: boolean;
    validationError?: {
        descr: string,
        html: string
    };
    commonForm?: {
        form: IForm;
        model: any;
    };
    avatarImg?: string;
    siteId?: string;
}


export interface SenderSourceForListInterface extends SenderSourceInterface {

}

export interface ILicense {
    id: string,
    uslugaId: string,
    dateTimeStart?: string,
    dateTimeStop?: string,
    dateTimeProlongStart?: string,
    dateTimeProlongStop?: string,
    prolongStatus?: {
        descr: string,
        id: string,
        isEditable: boolean,
        isWaiting: boolean,
        color: string
    }
    price?: {
        sum: number,
        currencyId: string
    }
}

/*
interface IPagerSource {
        cc: number;
        id: string;
        avatarImg: string;
        isTest: boolean;
        isDefault: boolean;
        licenseList: {
            dateTimeStart: string,
            dateTimeStop: string,
            prolongStatus: {
                isWaiting: boolean,
                isEditable: boolean
            }
        }[],
        status?: {
            id: number,
            descr: string,
            isActive: boolean
        },
        mobileForTest: string[]
    }
 */

export interface IUslugaAccess {
    success: boolean,
    isBanned: boolean,
    isTarifNull: boolean,
    isDisable: boolean
}


export interface ISenderFormSite {
    id: string;
    descr: string;
    isBotAllowed: boolean;
    changeClientInfoForm: IForm;
    defaultChangeClientInfoFormValues?: Dictionary<any>;
}

export interface ISenderFormContact {
    id: number;
    groupId: number | string;
    [PHONEBOOK_COLUMN_NAME_LASTNAME]: string;
    [PHONEBOOK_COLUMN_NAME_FIRSTNAME]: string;
    [PHONEBOOK_COLUMN_NAME_MIDDLENAME]: string;
    [PHONEBOOK_COLUMN_NAME_PHONE]: string;
    [PHONEBOOK_COLUMN_NAME_EMAIL]: string;
}

export interface ISenderFormRouteMeta extends IRouteMeta {
    contentForm: ISenderContentForm;
    routes: {
        index: string;
        groups: string;
        contacts: string;
    };
    routeForSenderFormPage: true;
    uslugaId: string;
}

export interface ISenderContentForm {
    component: AsyncVueComponent,
    padding: boolean
}

export interface ISenderFormCreateRouteMeta extends ISenderFormRouteMeta {
    routeForCreatePage: true;
}

export interface ISenderFormRoutes {
    index: string;
    groups: string;
    contacts: string;
    confirm: string;
}

const KEY_DATE_TIME_SEND = "dateTimeSend";
const KEY_MAGIC_URL = "magicUrl";
const KEY_ORG_DETAIL_ID = "orgDetailId";
const KEY_REPLY_TO_ONLINE_CHAT = "replyToOnlineChat";
const KEY_SMOOTH = "smooth";
const KEY_TAGS = "tags";
const KEY_TIME_RANGE = "timeRange";
const KEY_COUPON = "coupon";

export const SENDER_FORM_SETTINGS = [
    KEY_DATE_TIME_SEND,
    KEY_MAGIC_URL,
    KEY_ORG_DETAIL_ID,
    KEY_REPLY_TO_ONLINE_CHAT,
    KEY_SMOOTH,
    KEY_TAGS,
    KEY_TIME_RANGE,
    KEY_COUPON

];

export interface SenderRecipientTypeInterface {
    id: string,
    descr: string,
    contentVariables: ContentVariableInterface[]
}


export interface SenderFormParamsInterface {
    id: string;
    dateTimeSend: boolean;
    magicUrl: boolean;
    orgDetailId: boolean;
    replyToOnlineChat: boolean;
    smooth: boolean;
    showCascadeSchemeApiButton: boolean;
    tags: boolean;
    messageTimeout: boolean;
    timeRange: boolean;
    timeRangeSaveable: boolean;
    receiversFilters: boolean;
    contentVariables: ContentVariableInterface[],
    receiversGroups: boolean;
    receiversContacts: boolean;
    receiversOtherFromString: boolean;
    receiversStop: boolean;
    receiversLimit: boolean;
    fixedTags: string[];
    rightColumnSpan: number;
    clearForm: boolean;
    destsSelect: boolean;
    showTopInfoBlocks: boolean;
    sourceSelect: boolean;
    additionalContentOptions: boolean;
    standartButtons: boolean;
    selectChannel: boolean;
    availableContentTypes?: string[];
    coupon: boolean;
    allowedContactbookDataSourceIds: string[];
}


export const senderFormEditableInject = Symbol() as InjectionKey<Ref<boolean>>;