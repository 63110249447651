import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import EventPlatform from "~/cabinet/ts/data/events/EventPlatform";

export default class GetPlatformListResult extends AbstractEntity {
    platforms: EventPlatform[];

    protected getFields(): AbstractEntityFields {
        return {
            platforms: EventPlatform
        };
    }
}