import twemoji from "twemoji";
import {internationalUrlIfNeeded, STATIC_SERVER} from "~/core-ui/ts/Constants";

export default function emojify(html: string | HTMLElement): string {
    if (html == null) {
        return "";
    }
    let count = 0;
    twemoji.parse(html, {
        callback: function () {
            count++;
        }
    });
    if (count < 20) {
        return twemoji.parse(html, {
            base: internationalUrlIfNeeded(STATIC_SERVER + "/cabinet/assets/images/twemoji/")
        });
    } else {
        return typeof html == "string" ? html : html.innerHTML;
    }
}