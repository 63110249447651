import Dictionary from "~/ts/library/Dictionary";
import {Ref, ref, watch} from "vue";

//const objectAssignDeep = require('object-assign-deep');


export default class ObjectHelper {
    static jsonClone<T>(value: T): T {
        if (typeof value == "undefined") {
            return undefined;
        }
        return JSON.parse(JSON.stringify(value));
    }


    static getParam(params: Dictionary<any>, keys: (string | number)[], returnLast?: boolean): any | undefined {
        let result = params;
        for (let key of keys) {
            if (result && result.hasOwnProperty(key)) {
                result = result[key];
            } else {
                if (returnLast) {
                    break;
                }
                result = undefined;
            }
        }

        return result;
    }

    static hasKeys(params: Dictionary<any>): boolean {
        let result = false;
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                result = true;
            }
        }
        return result;
    }

    static getKeys(params: Dictionary<any>): string[] {
        let result = [];
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                result.push(key);
            }
        }
        return result;
    }

    static getAllValuesWithPath(params: Dictionary<any>, onlyScalar: boolean = false): IValueWithPath[] {
        let result = [];
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                let isObject = Array.isArray(params[key]) || (typeof params[key] == "object" && params[key]);
                if (isObject) {
                    for (let item of ObjectHelper.getAllValuesWithPath(params[key], onlyScalar)) {
                        item.path.splice(0, 0, key);
                        result.push(item);
                    }
                }
                if (!onlyScalar || !isObject) {
                    result.push({
                        value: params[key],
                        path: [key]
                    });
                }

            }
        }
        return result;
    }


    static getObjectByPathAndValue(path: any[], value: any, params?: any): any {
        if (params == null) {
            params = {};
        }
        let cursor: any = params;
        for (let i = 0; i < path.length; i++) {
            let key = path[i];
            if (i == path.length - 1) {
                cursor[key] = value;
            } else {
                if (cursor[key] == null) {
                    cursor[key] = {};
                }
                cursor = cursor[key];
            }
        }
        return params;
    }

    static setObjectValueByPath(object: any, path: string[], value: any) {
        ObjectHelper.getObjectByPathAndValue(path, value, object);
    }

    static dictionaryFromArray<T>(array: T[], key: string = "id"): Dictionary<T> {
        let result: Dictionary<any> = {};
        for (let item of array) {
            let itemKey = (item as any)[key];
            if (itemKey != null) {
                itemKey = itemKey.toString();
            }
            result[itemKey] = item;
        }
        return result;
    }

    static createCachedComputedRef<T>(computeCallback: () => T): Ref<T> {
        let result = ref(computeCallback()) as Ref<T>;
        watch(computeCallback, newValue => {
            if (JSON.stringify(newValue) != JSON.stringify(result.value)) {
                result.value = newValue;
            }
        });
        return result;
    }

    /*
        static dictionaryRefFromArray<T>(array: T[], key: string = "id"): Ref<Dictionary<T>> {
            let dictionary = ref(this.dictionaryFromArray(array, key));
            let push = array.push;
            let splice = array.splice;
    
            function refresh() {
                let keys: Dictionary<boolean> = {};
                for (let item of array) {
                    let itemKey = (item as any)[key];
                    if (!dictionary.value[itemKey]) {
                        set(dictionary.value, itemKey, item);
                        console.log("add key", itemKey);
                    }
                    keys[itemKey] = true;
                }
                for (let itemKey in dictionary.value) {
                    if (dictionary.value.hasOwnProperty(itemKey)) {
                        if (!keys[itemKey]) {
                            del(dictionary.value, itemKey);
                            console.log("remove key", itemKey);
                        }
                    }
                }
            }
    
            array.push = function () {
                let result = push.call(array, arguments);
                refresh();
                return result;
            };
            array.splice = function () {
                let result = splice.call(array, arguments);
                refresh();
                return result;
            };
            return dictionary;
        }
    */
    static arrayFromDictionary<T>(dictionary: Dictionary<T>): T[] {
        let result = [];
        for (let key in dictionary) {
            if (dictionary.hasOwnProperty(key)) {
                result.push(dictionary[key]);
            }
        }
        return result;
    }
}


export interface IValueWithPath {
    value: any,
    path: string[]
}