import IMyIconSelectorModel from "~/cabinet/vue/interface/form/IMyIconSelectorModel";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {ICON_REGULAR} from "~/chat/ts/data/ISiteParams";


export default class MyIconSelectorHelper {
    public static async getIconSelectModelByClass(className: string, style = ICON_REGULAR): Promise<IMyIconSelectorModel | null> {
        let result = await (new LcabApiRequest({
            url: `/api/ui/iconsSelector/getSvg/${className}/${style}`
        })).send();
        if (!result.showMessageOnError()) {
            return {
                iconName: className,
                svg: result.getData("svg"),
                style: result.getData("style")
            } as IMyIconSelectorModel;
        }
        return null;
    }
}