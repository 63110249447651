import AbstractBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/AbstractBlockType";
import Dictionary from "~/ts/library/Dictionary";
import H1BlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1BlockType";
import H2BlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/h2/H2BlockType";
import H3BlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/h3/H3BlockType";
import TextBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/text/TextBlockType";
import ImageBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/image/ImageBlockType";
import TextFloatImageBlockType
    from "~/cabinet/vue/interface/htmlConstructor/blocks/textFloatImage/TextFloatImageBlockType";
import TextUnderImageBlockType
    from "~/cabinet/vue/interface/htmlConstructor/blocks/TextUnderImage/TextUnderImageBlockType";
import HrBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/hr/HrBlockType";
import StructureBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/StructureBlockType";
import ButtonBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/button/ButtonBlockType";
import HeaderBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/header/HeaderBlockType";
import {IHtmlConstructorServerBlockType} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {
    HTML_BLOCK_CODE,
    HTML_BLOCK_CONTENTS,
    HTML_BLOCK_SAVED,
    HTML_BLOCK_TYPE_ATTENTION_TEXT,
    HTML_BLOCK_TYPE_BUTTON,
    HTML_BLOCK_TYPE_H1,
    HTML_BLOCK_TYPE_H2,
    HTML_BLOCK_TYPE_H3,
    HTML_BLOCK_TYPE_HEADER,
    HTML_BLOCK_TYPE_HR,
    HTML_BLOCK_TYPE_IMAGE,
    HTML_BLOCK_TYPE_IMAGE_UNDER_TEXT,
    HTML_BLOCK_TYPE_STRUCTURE,
    HTML_BLOCK_TYPE_TEXT,
    HTML_BLOCK_TYPE_TEXT_FLOAT_IMG
} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import SavedBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/savedBlock/SavedBlockType";
import AttentionTextBlockType
    from "~/cabinet/vue/interface/htmlConstructor/blocks/attentionText/AttentionTextBlockType";
import CodeBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/code/CodeBlockType";
import ContentsBlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/contentsBlock/ContentsBlockType";


export const HTML_CONSTRUCTOR_BLOCK_TYPES_DICTIONARY: Dictionary<new(serverBlockType: IHtmlConstructorServerBlockType<any>) => AbstractBlockType<any, any>> = {
    [HTML_BLOCK_TYPE_H1]: H1BlockType,
    [HTML_BLOCK_TYPE_H2]: H2BlockType,
    [HTML_BLOCK_TYPE_H3]: H3BlockType,
    [HTML_BLOCK_TYPE_TEXT]: TextBlockType,
    [HTML_BLOCK_TYPE_ATTENTION_TEXT]: AttentionTextBlockType,
    [HTML_BLOCK_TYPE_IMAGE]: ImageBlockType,
    [HTML_BLOCK_TYPE_TEXT_FLOAT_IMG]: TextFloatImageBlockType,
    [HTML_BLOCK_TYPE_IMAGE_UNDER_TEXT]: TextUnderImageBlockType,
    [HTML_BLOCK_TYPE_HR]: HrBlockType,
    [HTML_BLOCK_TYPE_BUTTON]: ButtonBlockType,
    [HTML_BLOCK_TYPE_STRUCTURE]: StructureBlockType,
    [HTML_BLOCK_TYPE_HEADER]: HeaderBlockType,
    [HTML_BLOCK_CONTENTS]: ContentsBlockType,
    [HTML_BLOCK_SAVED]: SavedBlockType,
    [HTML_BLOCK_CODE]: CodeBlockType
} as any;//ObjectHelper.dictionaryFromArray(KNOWN_BLOCK_TYPES, "id");
