import ActionDescription from "~/cabinet/ts/data/events/ActionDescription";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import {PropType} from "vue";
import ActionOutput from "~/cabinet/ts/data/events/ActionOutput";

export function useActionBlockOutputProps<ActionDescriptionType extends ActionDescription<any>>() {
    type getType<T> = T extends ActionDescription<infer U> ? U : never;
    type Model = getType<ActionDescriptionType>;

    return {
        params: {
            type: Object as PropType<Model>,
            required: true
        },
        output: {
            type: Object as PropType<ActionOutput>,
            required: true
        },
        actionDescription: {
            type: Object as PropType<ActionDescriptionType>,
            required: true
        },
        contentVariables: {
            type: Array as PropType<ContentVariableInterface[]>
        }
    }
}