import H1Form from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1Form.vue";
import H1BlockType from "~/cabinet/vue/interface/htmlConstructor/blocks/h1/H1BlockType";
import H2Display from "~/cabinet/vue/interface/htmlConstructor/blocks/h2/H2Display.vue";
import {Component} from "vue";


export default class H2BlockType extends H1BlockType {

    get displayComponent(): Component {
        return H2Display;
    }

    get formComponent(): Component {
        return H1Form;
    }

}
