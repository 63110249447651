var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.PointerEventContainer,{class:{
        draggable: _vm.draggable,
        'drag-active' : !!_setup.dragActive,
        'scale-when-drag': _vm.scaleWhenDrag,
        'fixed': !!_vm.fixed,
        'stick-to-bottom': _setup.stickToBottom,
        'stick-to-top': _setup.stickToTop
    },style:(_setup.style),on:{"drag-start":_setup.onDragStart,"drag":_setup.onDrag,"drag-stop":_setup.onDragStop,"click":function($event){return _setup.emits('click', $event)}},nativeOn:{"mouseenter":function($event){return _setup.emits('mouseenter', $event)},"mouseleave":function($event){return _setup.emits('mouseleave', $event)}}},[_vm._t("default"),_vm._v(" "),(_vm.resizable)?_vm._l((_setup.resizeHandlers),function(props,index){return _c(_setup.ResizeHelper,_vm._b({key:index,on:{"drag":_setup.onResize}},'resize-helper',props,false))}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }