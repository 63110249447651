import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import Platform from "~/cabinet/ts/data/integrations/Platform";

export default async function getAuthById(authId: string) {
    return await (new LcabApiRequest({
        url: `/api/client/integration/getAuthById/${authId}`
    }, GetAuthByIdResponse)).send()
}

export async function getAuthByPrimaryId(platformId: string, authPrimaryId: string) {
    return await getAuthByPrimaryIdRequest(platformId, authPrimaryId).send();
}

export function getAuthByPrimaryIdRequest(platformId: string, authPrimaryId: string) {
    return new LcabApiRequest({
        url: `/api/client/integration/getAuthByPrimaryId/${platformId}/${authPrimaryId}`
    }, GetAuthByIdResponse);
}

class GetAuthByIdResponse extends AbstractEntity {
    auth: Auth;
    platform: Platform;

    protected getFields(): AbstractEntityFields {
        return {
            auth: Auth,
            platform: Platform
        };
    }
}