import {Vue} from "~/node_modules/vue/types/vue";
import {ComponentOptions} from "~/node_modules/vue";
import DateHelper from "~/ts/library/Date";
import {toggleArrayElement} from "~/ts/library/ToggleArrayElement";
import {__} from "~/ts/library/Translate";
import WindowHelper from "~/ts/library/WindowHelper";
import {LCAB_LAYOUT_BREAKPOINT} from "~/ts/library/LcabWindowHelper";
import {computed} from "vue";

export const isMobileWindowSize = computed(() => {
    return WindowHelper.getSize().width < LCAB_LAYOUT_BREAKPOINT;
})

export const requiredRule = [{required: true, message: __("Обязательное поле")}];

export const $color = (color: string | null) => color ? "text-" + color : "";
export const $background = (color: string | null) => color ? "bg-" + color : "";
export const $mobileFullWidthButtonClass = "d-block d-md-inline-block w-100 w-md-auto m-md-0 mt-1 mb-1 ml-0 mr-0 ";

export function getFaviconUrl(host: string) {
    return `https://www.google.com/s2/favicons?sz=64&domain=${host}`;
}

export const CoreUiHelpersMixin: ComponentOptions<Vue> = {
    computed: {
        $isMobileWindowSize: function () {
            return isMobileWindowSize.value;
        }
    },
    data: () => ({
        mobileFullWidthButtonClass: $mobileFullWidthButtonClass,
        requiredRule,
        predefineColors: [
            "#c4183c",
            "#007bff",
            "#F8B347",
            "#8175c7",
            "#17c671",
            "#f8d347",
            "#bfc2cd",
            "#41cac0",
            "#00a8b3",
            "#7087A3"
        ]
    }),
    methods: {
        $roundCountryImage(countryId: string) {
            return `/cabinet/assets/images/flags/${countryId}.png`;
        },
        $localeDateTimeString: function (date: string | Date | number, withSeconds: boolean = false) {
            return (new DateHelper(date)).toLocaleDateTimeString(withSeconds);
        },
        $localeTimeString: function (date: string | Date) {
            return (new DateHelper(date)).toLocaleTimeString();
        },
        $mysqlTimeString: function (date: string | Date) {
            return (new DateHelper(date)).toMysqlFormatTime();
        },
        $localeDateString: function (date: string | Date, wordMonth: boolean = false) {
            return (new DateHelper(date)).toLocaleDateString(wordMonth);
        },
        $secondsFormat: function (seconds: number) {
            return DateHelper.secondsFormat(seconds);
        },
        $secondsFormatToClosestFloor: function (seconds) {
            return DateHelper.secondsFormatToClosestFloor(seconds);
        },
        $color: $color,
        $background: $background,

        $getFaviconUrl: getFaviconUrl,
        $substrLong: (text: string, length: number, end: string = "...") => {
            text = text.trim();
            if (text.length > length) {
                text = text.substr(0, length) + end;
            }
            return text;
        },
        $toggleArrayElement: function (array: any[], element: any, value: boolean) {
            return toggleArrayElement(array, element, value);
        },
        $isElementInArray: function (array: any[], element: any) {
            return array.indexOf(element) > -1;
        }
    }
};