import {RawLocation} from "vue-router/types/router";
import {RouteConfig} from "vue-router";
import Section from "~/cabinet/vue/layout/lcab/Aside/Section";

export const CLIENT_PAY = "CLIENT_PAY";

export class ClientPayRoutesBuilder {
    public static getPayLocation(sum?: number): RawLocation {
        return {
            name: CLIENT_PAY,
            params: {
                sum: sum ? sum.toString() : undefined
            }
        };
    }

    public static getPay(): RouteConfig {
        let result: RouteConfig = {
            name: CLIENT_PAY,
            path: "pay/:sum?",
            component: () => import(/* webpackChunkName: "client-pay" */ '~/cabinet/vue/client/pay/Pay.vue'),
            meta: {},
            props: (p) => ({
                sum: p.params.sum ? parseFloat(p.params.sum) : undefined
            })
        };
        Section.createForRoute(result, "Оплатить", "rub-sign")
            .setVisibleInMenu(false);
        return result;
    }
}