import {computed} from "vue";
import ReactiveRequest from "~/core-ui/ts/request/ReactiveRequest";
import {getAuthByPrimaryIdRequest} from "~/cabinet/vue/client/online/config/integrations/getAuthById";
import {
    RunExternalBotActionModelInterface
} from "~/cabinet/vue/client/events/action/types/onlineChat/runExternalBot/Interface";

export function useOnlineChatRunExternalBot(model: RunExternalBotActionModelInterface) {

    let request = computed(() => {
        if (model.authPrimaryId && model.platformId) {
            let request = getAuthByPrimaryIdRequest(model.platformId, model.authPrimaryId);
            return (new ReactiveRequest(request))
                .setShowErrorMessage(false);
        }
        return null;
    });

    let selectedAuth = computed<SelectedAuthInterface>(() => {
        if (request.value) {
            let descr = "Идёт загрузка...";
            let platformId: string;
            let customBotUrl: string;
            if (!request.value.isLoading) {
                if (request.value.isSuccess) {
                    let auth = request.value.data.auth;
                    descr = auth.descr ?? auth.primaryId;
                    platformId = auth.platformId;
                    customBotUrl = auth.params.webhookUrl;
                } else {
                    descr = null;
                }
            }

            return {
                id: model.authPrimaryId,
                descr,
                platformDescr: request.value?.data?.platform.descr,
                platformId,
                customBotUrl,
                isCustomBot: platformId == "customExternalBot"
            }
        } else {
            return null;
        }
    });

    return {
        selectedAuth
    }
}


interface SelectedAuthInterface {
    id: string;
    descr: string;
    platformDescr: string;
    platformId: string;
    customBotUrl?: string;
    isCustomBot: boolean;
}