// Save the previous value of the device variable.

// The client user agent string.
// Lowercase, so we can use the more efficient indexOf(), instead of Regex

export const DEVICE_DESKTOP = "desktop";
export const DEVICE_MOBILE = "mobile";
export const DEVICE_TABLET = "tablet";
export type DeviceId = "desktop" | "mobile" | "tablet";
export const DEVICES = [DEVICE_DESKTOP, DEVICE_MOBILE, DEVICE_TABLET];
export const MOBILE_DEVICES = [DEVICE_MOBILE, DEVICE_TABLET];

const userAgent = window.navigator.userAgent.toLowerCase();
let myWindow = window as any;

class Device {
    static macos() {
        return find('mac')
    }

    static ios() {
        return Device.iphone() || Device.ipod() || Device.ipad()
    }

    static iphone() {
        return !Device.windows() && find('iphone')
    }

    static ipod() {
        return find('ipod')
    }

    static ipad() {
        return find('ipad')
    }

    static android() {
        return !Device.windows() && find('android')
    }

    static androidPhone() {
        return Device.android() && find('mobile')
    }

    static xiaomi() {
        return find('xiaomi');
    }

    static androidTablet() {
        return Device.android() && !find('mobile')
    }

    static blackberry() {
        return find('blackberry') || find('bb10') || find('rim')
    }

    static blackberryPhone() {
        return Device.blackberry() && !find('tablet')
    }

    static blackberryTablet() {
        return Device.blackberry() && find('tablet')
    }

    static windows() {
        return find('windows')
    }

    static windowsPhone() {
        return Device.windows() && find('phone')
    }

    static windowsTablet() {
        return Device.windows() && (find('touch') && !Device.windowsPhone())
    }

    static fxos() {
        return (find('(mobile') || find('(tablet')) && find(' rv:')
    }

    static fxosPhone() {
        return Device.fxos() && find('mobile')
    }

    static fxosTablet() {
        return Device.fxos() && find('tablet')
    }

    static meego() {
        return find('meego')
    }

    static cordova() {
        return myWindow.cordova && location.protocol === 'file:'
    }

    static nodeWebkit() {
        return typeof myWindow.process === 'object'
    }

    static mobile() {
        return (
            Device.androidPhone() ||
            Device.iphone() ||
            Device.ipod() ||
            Device.windowsPhone() ||
            Device.blackberryPhone() ||
            Device.fxosPhone() ||
            Device.meego() ||
            Device.xiaomi()
        )
    }

    static tablet() {
        return (
            Device.ipad() ||
            Device.androidTablet() ||
            Device.blackberryTablet() ||
            Device.windowsTablet() ||
            Device.fxosTablet()
        )
    }

    static desktop() {
        return !Device.tablet() && !Device.mobile()
    }

    static portrait() {
        if (
            screen.orientation &&
            Object.prototype.hasOwnProperty.call(window, 'onorientationchange')
        ) {
            return screen.orientation.type.includes('portrait')
        }
        return window.innerHeight / window.innerWidth > 1
    }

    static landscape() {
        if (
            screen.orientation &&
            Object.prototype.hasOwnProperty.call(window, 'onorientationchange')
        ) {
            return screen.orientation.type.includes('landscape')
        }
        return window.innerHeight / window.innerWidth < 1
    }

    static type: string;

    static os: string;
}

// Simple UA string search
function find(needle: string) {
    return userAgent.indexOf(needle) !== -1
}

// Public functions to get the current value of type, os, or orientation
// ---------------------------------------------------------------------

function findMatch(arr: any[]) {
    for (let i = 0; i < arr.length; i++) {
        if ((Device as any)[arr[i]]()) {
            return arr[i]
        }
    }
    return 'unknown'
}

Device.type = findMatch([DEVICE_MOBILE, DEVICE_TABLET, DEVICE_DESKTOP])
Device.os = findMatch([
    'ios',
    'iphone',
    'ipad',
    'ipod',
    'android',
    'blackberry',
    'windows',
    'fxos',
    'meego'
]);

export default Device