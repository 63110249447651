export const RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION = "askQuestion";
export const RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE = "sendFixedMessage";

export type RunExternalBotActionStartMode =
    typeof RUN_EXTERNAL_BOT_ACTION_START_MODE_ASK_QUESTION
    | typeof RUN_EXTERNAL_BOT_ACTION_START_MODE_SEND_FIXED_MESSAGE;

export interface RunExternalBotActionModelInterface {
    authPrimaryId: string;
    platformId: string;
    initialTextMessageForBot: string;
    askQuestionTextMessageForBot: string;
    startMode: RunExternalBotActionStartMode;
    responseTime: number;
}