import AbstractEntity, {AbstractEntityBuilder, AbstractEntityFields} from "~/ts/library/AbstractEntity";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";

export default class FiltersResponse<T> extends AbstractEntity {
    filters: IFiltersData;
    params?: T;

    protected get paramsClass(): AbstractEntityBuilder<T> {
        return null;
    }

    protected getFields(): AbstractEntityFields {
        let result: AbstractEntityFields = {};
        if (this.paramsClass) {
            result.params = this.paramsClass;
        }
        return result;
    }
}