import Section from "~/cabinet/vue/layout/lcab/Aside/Section";
import RouteHelper from "~/core-ui/ts/router/RouteHelper";
import {AccountStore} from "~/cabinet/ts/store/AccountStore";
import DealerRoutesBuilder, {DEALER_ROOT_NAME} from "~/cabinet/ts/routes/DealerRoutesBuilder";
import {__} from "~/ts/library/Translate";
import {RouteConfig} from "vue-router";
import RouteAccessChecker from "~/cabinet/ts/routes/RouteAccessChecker";
import ClientRoutesBuilder from "~/cabinet/ts/routes/ClientRoutesBuilder";
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router/composables";

export function useSectionSelector(onSelect: (section: Section) => void) {
    let route = useRoute();
    let router = useRouter();

    let sectionList = computed<Section[]>(() => {
        let result: Section[];
        if (RouteHelper.isRouteForDealer(route)) {
            result = dealerSectionsList.value;
        } else {
            result = [...clientSectionsList.value];

            if (AccountStore.isDealer.value) {
                result.push(new Section(DEALER_ROOT_NAME, __("Управление"), "cubes"));
            }
        }
        return [...result].filter(section => section.isVisibleInMenu);
    });

    function getSectionsListByRoutes(routes: RouteConfig[]): Section[] {
        return routes
            .filter(route => RouteAccessChecker.checkAccessByMeta(route.meta))
            .map((route: RouteConfig) => route.meta ? route.meta.section : null)
            .filter(section => section != null);
    }

    let dealerSectionsList = computed<Section[]>(() => {
        return getSectionsListByRoutes((new DealerRoutesBuilder()).make()[0].children);

    });

    function getClientSectionsList(collapseSenders?: boolean) {
        let result = getSectionsListByRoutes((new ClientRoutesBuilder()).make(collapseSenders)[0].children);
        if (result.length > 1) {
            result = result.filter(section => /*section.routeName != CLIENT_START_ROUTE_NAME &&*/ section.isVisibleInMenu);
        }
        return result;
    }

    let clientSectionsList = computed<Section[]>(() => {
        let result = getClientSectionsList();

        let sectionsInConfig = AccountStore.dealerCabinetConfig.value.menu.sections;

        result.sort((a, b) => {
            let weight1 = sectionsInConfig[a.routeName] ? sectionsInConfig[a.routeName].weight : -1;
            let weight2 = sectionsInConfig[b.routeName] ? sectionsInConfig[b.routeName].weight : -1;
            if (weight1 == -1) {
                weight1 = result.indexOf(a);
            }
            if (weight2 == -1) {
                weight2 = result.indexOf(b);
            }
            if (weight1 > weight2) {
                return 1;
            }
            if (weight2 > weight1) {
                return -1;
            }
            return 0;
        });
        for (let section of result) {
            let sectionConfig = sectionsInConfig[section.routeName];
            if (sectionConfig) {
                if (sectionConfig.icon) {
                    section.replaceIcon(sectionConfig.icon);
                }
                if (sectionConfig.descr) {
                    section.replaceDescr(sectionConfig.descr);
                }
            }
        }

        return result;
    });


    let currentSection = computed<Section>(() => {
        let routeRecord = route.matched.find(route => !!route.meta.section);
        return routeRecord ? routeRecord.meta.section : new Section(route.path, route.path);
    });

    async function select(section: Section) {
        let element = document.getElementById("lcab-aside");
        if (element && element.scroll) {
            element.scroll(0, 0);
        }
        try {
            await router.push(getPathForSection(section));
        } catch (e) {

        }
        onSelect(section);
    }

    function getPathForSection(section: Section) {
        return {name: section.routeName};
    }

    return {
        get sectionList() {
            return sectionList.value;
        },
        getClientSectionsList,
        currentSection,
        select,
        getPathForSection
    }
}