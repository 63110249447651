import Dictionary from "~/ts/library/Dictionary";
import SenderUsluga from "~/cabinet/ts/data/usluga/SenderUsluga";
import {useSenderStore} from "~/cabinet/ts/store/Sender";
import {
    USLUGA_ID_CASCADE,
    USLUGA_ID_EMAIL,
    USLUGA_ID_MAILRU,
    USLUGA_ID_MESSENGER,
    USLUGA_ID_PAGER,
    USLUGA_ID_VIBER,
    USLUGA_ID_VOICE,
    USLUGA_ID_WHATSAPP
} from "~/cabinet/ts/Constant";
import {RouteConfig} from "~/node_modules/vue-router";
import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {__} from "~/ts/library/Translate";
import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {IPagerSourceForList} from "~/cabinet/vue/client/sender/pager/Interfaces";
import {IViberSourceForDealer} from "~/cabinet/vue/dealer/tasks/sender/viber/Interfaces";
import {
    PHONEBOOK_COLUMN_NAME_EMAIL,
    PHONEBOOK_COLUMN_NAME_MESSENGERS
} from "~/cabinet/vue/client/phonebook/contacts/constants";
import {AbstractPagerTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/PagerTarifPrice";
import {SingleTarifPrice} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/price/AbstractTarifPrice";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {PAGER_DEALER_OWNER_PRICE_KEYS} from "~/cabinet/vue/dealer/config/uslugi/tarif/pager/PagerTarifHelper";

let sourceTitle = __("Имена отправителя");
let voiceSourceTitle = __("Номера отправителя");

let commonUsluga: Usluga | any = {
    isRegionalTimeAvailable: false,
    report: {
        recipientColumnWidth: 155
    },
    dealer: {
        config: {}
    },
    client: {},
    apiTokenTypeId: "sender"
};

const SOURCE_ICON = "tags";
const SOURCE_PATH = "source";
const PAGER_SMPP_ROUTE_NAME = "pager_smpp_api";

function getSourceRouteName(uslugaId: string) {
    return "SOURCE_" + uslugaId;
}

function getSenderSourceRouteComponent(uslugaId: string) {
    return {
        props: {
            uslugaId: uslugaId,
        },
        component: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/source/SourceList.vue')
    };
}

export const VIBER_SOURCE_ROUTE_NAME = getSourceRouteName(USLUGA_ID_VIBER);
export const WHATSAPP_SOURCE_ROUTE_NAME = getSourceRouteName(USLUGA_ID_WHATSAPP);

export const PAGER_SOURCE_ROUTE_NAME = getSourceRouteName(USLUGA_ID_PAGER);
const PAGER_SOURCE_LIST_ROUTE_NAME = PAGER_SOURCE_ROUTE_NAME + "_LIST";
const PAGER_DEALER_OPSOS_SOURCE_ROUTE_NAME = PAGER_SOURCE_ROUTE_NAME + "_DEALER_OPSOS_SOURCE";
export const VOICE_SOURCE_ROUTE_NAME = getSourceRouteName(USLUGA_ID_VOICE);
export const EMAIL_SOURCE_ROUTE_NAME = getSourceRouteName(USLUGA_ID_EMAIL);


export const MAILRU_SOURCE_ROUTE_NAME = getSourceRouteName(USLUGA_ID_MAILRU);

let mobileRecipientComponent = () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/mobile/MobileMessageRecipient.vue');


const SenderUslugi: Dictionary<SenderUsluga> = {
    [USLUGA_ID_VOICE]: SenderUsluga.new({
        ...commonUsluga,
        id: USLUGA_ID_VOICE,
        isRegionalTimeAvailable: true,
        contentFormComponent: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/sender/voice/ContentForm.vue'),
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/sender/voice/VoiceTaskPreview.vue'),
                lg: 7,
                md: 8
            }
        },
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-voice" */ '~/cabinet/vue/dealer/config/uslugi/tarif/voice/VoiceTarifList.vue')
            }
        },
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/tarif/VoiceTarifs.vue'),
        },
        report: {
            tasksReportExpandedInfo: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/sender/voice/report/VoiceTaskExpandedInfo.vue'),
            recipientColumnWidth: 155,
            contentComponent: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/sender/voice/report/parts/VoiceMessageContent.vue'),
            recipientComponent: mobileRecipientComponent,
            sourceComponent: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/sender/voice/report/parts/VoiceMessageSource.vue'),

        },
        source: {
            title: voiceSourceTitle,
            formComponent: () => import(/* webpackChunkName: "client-voice" */ '~/cabinet/vue/client/sender/voice/source/VoiceSourceForm.vue'),
            routing: {
                getRoutes: () => {
                    return {
                        name: VOICE_SOURCE_ROUTE_NAME,
                        path: SOURCE_PATH,
                        ...getSenderSourceRouteComponent(USLUGA_ID_VOICE)
                    } as RouteConfig;

                },
                getMenu: () => {
                    return (new MenuItem(VOICE_SOURCE_ROUTE_NAME, voiceSourceTitle)).setIcon(SOURCE_ICON)
                }
            },
            dealersApproveComponent: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/voice/VoiceSources.vue')

        }
    } as SenderUsluga),
    [USLUGA_ID_VIBER]: SenderUsluga.new({
        ...commonUsluga,
        hideContentFormWhenHasNoSources: true,
        id: USLUGA_ID_VIBER,
        rules: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/Rules.vue'),
        isRegionalTimeAvailable: true,
        contentFormComponent: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/ContentForm.vue'),
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/ViberTaskPreview.vue'),
                lg: 7,
                md: 8
            }
        },
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-viber" */ '~/cabinet/vue/dealer/config/uslugi/tarif/viber/ViberTarifList.vue')
            }
        },
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/tarif/ViberTarifs.vue')
        },
        report: {
            recipientColumnWidth: 155,
            contentComponent: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/report/ViberMessageContent.vue')
        },
        source: {
            title: sourceTitle,
            cardLicenseForm: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/source/ViberSourceLicenseForm.vue'),
            cardHeader: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/source/ViberSourceHeader.vue'),
            formComponent: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/viber/source/ViberSourceForm.vue'),
            formModelGetter: (model: IViberSourceForDealer) => {
                return model && model.sourceTypeId ? (model as any)[model.sourceTypeId] : null;
            },
            routing: {
                getRoutes: () => {
                    return {
                        name: VIBER_SOURCE_ROUTE_NAME,
                        path: SOURCE_PATH,
                        ...getSenderSourceRouteComponent(USLUGA_ID_VIBER)
                    } as RouteConfig;

                },
                getMenu: () => {
                    return (new MenuItem(VIBER_SOURCE_ROUTE_NAME, sourceTitle)).setIcon(SOURCE_ICON)
                }
            },
            dealersApproveComponent: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/viber/ViberSources.vue')
        }
    } as SenderUsluga),
    [USLUGA_ID_EMAIL]: SenderUsluga.new({
        ...commonUsluga,
        id: USLUGA_ID_EMAIL,
        recipientIdPhonebookField: PHONEBOOK_COLUMN_NAME_EMAIL,
        contentFormComponent: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/sender/email/ContentForm.vue'),
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/sender/email/EmailTaskPreview.vue'),
                lg: 16,
                md: 16
            },
            otherRecipientPlaceholder: "Введите почту через Enter"
        },
        report: {
            recipientColumnWidth: 190,
            sourceComponent: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/sender/email/report/parts/EmailMessageSource.vue'),
            contentComponent: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/sender/email/report/parts/EmailMessageContent.vue')
        },
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-email" */ '~/cabinet/vue/dealer/config/uslugi/tarif/email/EmailTarifList.vue')
            }
        },
        source: {
            editable: true,
            title: sourceTitle,
            formComponent: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/sender/email/source/EmailSourceForm.vue'),
            cardHeader: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/sender/email/source/EmailSourceHeader.vue'),

            routing: {
                getRoutes: () => {
                    return {
                        name: EMAIL_SOURCE_ROUTE_NAME,
                        path: SOURCE_PATH,
                        ...getSenderSourceRouteComponent(USLUGA_ID_EMAIL)
                    } as RouteConfig;
                },
                getMenu: () => {
                    return (new MenuItem(EMAIL_SOURCE_ROUTE_NAME, sourceTitle)).setIcon(SOURCE_ICON)
                }
            }
        },
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-email" */ '~/cabinet/vue/client/tarif/EmailTarifs.vue')
        }
    }),
    [USLUGA_ID_PAGER]: SenderUsluga.new({
        ...commonUsluga,
        id: USLUGA_ID_PAGER,
        hideContentFormWhenHasNoSources: true,
        rules: () => import(/* webpackChunkName: "client-viber" */ '~/cabinet/vue/client/sender/pager/Rules.vue'),
        isRegionalTimeAvailable: true,
        contentFormComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/ContentForm.vue'),
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/PagerTaskPreview.vue'),
                lg: 7,
                md: 8,
                taskInfoComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/PagerTaskInfo.vue')

            },
            topContentComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/source/PagerDealerOpsosSourceWarning.vue')
        },
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-pager" */ '~/cabinet/vue/dealer/config/uslugi/tarif/pager/NewPagerTarifList.vue'),
                commonUslugaSettingsComponent: () => import(/* webpackChunkName: "dealer-pager" */ '~/cabinet/vue/dealer/config/uslugi/common/Pager.vue')
            }
        },
        report: {
            recipientComponent: mobileRecipientComponent,
            recipientColumnWidth: 155,
            opsosCorrectAvailable: true
        },
        rawTarifPriceToAbstractTarifPrice: (tarifCurrencyId, price, path: any[]) => {
            if (PAGER_DEALER_OWNER_PRICE_KEYS.includes(path[0])) {
                return Usluga.new().rawTarifPriceToAbstractTarifPrice(tarifCurrencyId, price, path);
            }
            return AbstractPagerTarifPrice.new(ObjectHelper.jsonClone(price));
        },
        source: {
            cardSection: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/source/PagerSourceCardSection.vue'),
            cardSectionDisplayCondition: (source: IPagerSourceForList) => source.dealerOpsosList.length && source.status.isActive,
            listPrependInfo: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/source/PagerDealerOpsosSourceWarning.vue'),
            formComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/source/PagerAddSourceDialog.vue'),
            title: sourceTitle,
            dealersApprove: true,
            routing: {
                getRoutes: () => {
                    return {
                        name: PAGER_SOURCE_ROUTE_NAME,
                        path: SOURCE_PATH,
                        children: [
                            {
                                name: PAGER_SOURCE_LIST_ROUTE_NAME,
                                path: "list",
                                ...getSenderSourceRouteComponent(USLUGA_ID_PAGER)
                            },
                            {
                                name: PAGER_DEALER_OPSOS_SOURCE_ROUTE_NAME,
                                path: "replace",
                                component: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/source/PagerDealerOpsosSourceList.vue')
                            }
                        ]
                    } as RouteConfig;

                },
                getMenu: () => {
                    return (new MenuItem(PAGER_SOURCE_ROUTE_NAME, sourceTitle, SOURCE_ICON))
                        .addChildrenItem(new MenuItem(PAGER_SOURCE_LIST_ROUTE_NAME, "Мои имена"))
                        .addChildrenItem(new MenuItem(PAGER_DEALER_OPSOS_SOURCE_ROUTE_NAME, "Специфичные для операторов"))
                }
            },
            dealersApproveComponent: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/pager/PagerSources.vue')
        },
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/tarif/PagerTarifs.vue')
        },
        additionalConfigRoutes: {
            routes: [
                {
                    name: "pager_smpp_api",
                    path: "smpp",
                    component: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/smpp/Smpp.vue')
                }
            ],
            menu: [
                new MenuItem(PAGER_SMPP_ROUTE_NAME, "SMPP")
            ]
        }
    } as SenderUsluga),
    [USLUGA_ID_CASCADE]: SenderUsluga.new({
        ...commonUsluga,
        id: USLUGA_ID_CASCADE,
        recipientIdPhonebookField: null,
        source: null,
        contentFormComponent: () => import(/* webpackChunkName: "client-cascade" */ '~/cabinet/vue/client/sender/cascade/ContentForm.vue'),
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-cascade" */ '~/cabinet/vue/client/sender/cascade/CascadeTaskPreview.vue'),
                lg: 7,
                md: 8,
                priceDescr: "Стоимость запуска каскадной рассылки",
                taskInfoComponent: () => import(/* webpackChunkName: "client-cascade" */ '~/cabinet/vue/client/sender/cascade/CascadeTaskInfo.vue')

            },
            hideReceiversByContentForm: true
        },
        report: {
            contentComponent: () => import(/* webpackChunkName: "client-cascade" */ '~/cabinet/vue/client/sender/cascade/report/parts/CascadeMessageContent.vue'),
            recipientComponent: () => import(/* webpackChunkName: "client-cascade" */ '~/cabinet/vue/client/sender/cascade/report/parts/CascadeMessageRecipient.vue'),
            tasksReportExpandedInfo: () => import(/* webpackChunkName: "client-cascade" */ '~/cabinet/vue/client/sender/cascade/report/CascadeTaskExpandedInfo.vue'),
        },
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-cascade" */ '~/cabinet/vue/dealer/config/uslugi/tarif/cascade/CascadeTarifList.vue')
            }
        }
    }),
    [USLUGA_ID_MAILRU]: SenderUsluga.new({
        id: USLUGA_ID_MAILRU,
        ...commonUsluga,
        hideContentFormWhenHasNoSources: true,
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-mailru" */ '~/cabinet/vue/client/tarif/MailRuTarifs.vue')
        },
        rules: () => import(/* webpackChunkName: "client-mailru" */ '~/cabinet/vue/client/sender/mailru/Rules.vue'),
        rawTarifPriceToAbstractTarifPrice: (tarifCurrencyId, price) => {
            return SingleTarifPrice.new(price);
        },
        source: {
            title: sourceTitle,
            formComponent: () => import(/* webpackChunkName: "client-mailru" */ '~/cabinet/vue/client/sender/mailru/source/MailRuSourceForm.vue'),
            cardHeader: () => import(/* webpackChunkName: "client-mailru" */ '~/cabinet/vue/client/sender/mailru/source/MailRuSourceHeader.vue'),

            routing: {
                getRoutes: () => {
                    return {
                        name: MAILRU_SOURCE_ROUTE_NAME,
                        path: SOURCE_PATH,
                        ...getSenderSourceRouteComponent(USLUGA_ID_MAILRU)
                    } as RouteConfig;

                },
                getMenu: () => {
                    return (new MenuItem(MAILRU_SOURCE_ROUTE_NAME, sourceTitle)).setIcon(SOURCE_ICON)
                }
            },
            dealersApproveComponent: () => import(/* webpackChunkName: "dealer-tasks" */ '~/cabinet/vue/dealer/tasks/sender/mailRu/MailRuSources.vue')
        },
        contentFormComponent: () => import(/* webpackChunkName: "client-mailru" */ '~/cabinet/vue/client/sender/mailru/ContentForm.vue'),
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/pager/PagerTaskPreview.vue'),
                lg: 7,
                md: 8
            }
        },
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-mailru" */ '~/cabinet/vue/dealer/config/uslugi/tarif/mailru/MailRuTarifList.vue')
            }
        }
    } as SenderUsluga),
    [USLUGA_ID_WHATSAPP]: SenderUsluga.new({
        id: USLUGA_ID_WHATSAPP,
        ...commonUsluga,
        hideContentFormWhenHasNoSources: true,
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-pager" */ '~/cabinet/vue/client/sender/whatsapp/WhatsappTaskPreview.vue'),
                lg: 7,
                md: 8
            }
        },
        source: {
            title: sourceTitle,
            formComponent: () => import(/* webpackChunkName: "client-sender" */ '~/cabinet/vue/client/sender/source/CommonSourceForm.vue'),
            routing: {
                getRoutes: () => {
                    return {
                        name: WHATSAPP_SOURCE_ROUTE_NAME,
                        path: SOURCE_PATH,
                        ...getSenderSourceRouteComponent(USLUGA_ID_WHATSAPP)
                    } as RouteConfig;

                },
                getMenu: () => {
                    return new MenuItem(WHATSAPP_SOURCE_ROUTE_NAME, sourceTitle, SOURCE_ICON);
                }
            },
        },
        contentFormComponent: () => import(/* webpackChunkName: "client-whatsapp" */ '~/cabinet/vue/client/sender/whatsapp/ContentForm.vue'),
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-whatsapp" */ '~/cabinet/vue/dealer/config/uslugi/tarif/whatsapp/WhatsappTarifList.vue')
            }
        },
        report: {
            recipientColumnWidth: 155,
            contentComponent: () => import(/* webpackChunkName: "client-whatsapp" */ '~/cabinet/vue/client/sender/whatsapp/report/WhatsappMessageContent.vue')
        },
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-whatsapp" */ '~/cabinet/vue/client/tarif/WhatsappTarifs.vue')
        }
    } as SenderUsluga),
    [USLUGA_ID_MESSENGER]: SenderUsluga.new({
        id: USLUGA_ID_MESSENGER,
        ...commonUsluga,
        recipientIdPhonebookField: PHONEBOOK_COLUMN_NAME_MESSENGERS,
        //hideContentFormWhenHasNoSources: true,
        create: {
            confirm: {
                taskPreviewComponent: () => import(/* webpackChunkName: "client-messenger" */ '~/cabinet/vue/client/sender/messenger/MessengerTaskPreview.vue'),
                lg: 7,
                md: 8
            },
            hideOtherRecipient: true
        },
        contentFormComponent: () => import(/* webpackChunkName: "client-messenger" */ '~/cabinet/vue/client/sender/messenger/ContentForm.vue'),
        dealer: {
            config: {
                tarifComponent: () => import(/* webpackChunkName: "dealer-messenger" */ '~/cabinet/vue/dealer/config/uslugi/tarif/messenger/MessengerTarifList.vue')
            }
        },
        report: {
            contentComponent: () => import(/* webpackChunkName: "client-messenger" */ '~/cabinet/vue/client/sender/messenger/report/MessengerMessageContent.vue')
        },
        client: {
            tarifComponent: () => import(/* webpackChunkName: "client-messenger" */ '~/cabinet/vue/client/tarif/MessengerTarifs.vue'),
        }
    } as SenderUsluga)
};

for (let uslugaId in SenderUslugi) {
    if (SenderUslugi.hasOwnProperty(uslugaId)) {
        SenderUslugi[uslugaId].store = useSenderStore();
    }
}

export default SenderUslugi;