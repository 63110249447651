import MenuItem from "~/cabinet/vue/interface/menu/MenuItem";
import {Route, RouteConfig} from "vue-router";
import {Location} from "vue-router/types/router";
import {IRouteMeta} from "~/core-ui/ts/router/Interface";

export const DASHBOARD_ID = "dashboardId";

export default class DashboardRouteBuilder {
    public static getMenu(routeName: string, descr: string = "Сводка"): MenuItem {
        return (new MenuItem(routeName, descr))
        //.setIcon("tachometer-alt")
    }

    public static getRouteConfig(routeName: string, dashboardTypeId: () => string, keepAlive: boolean = undefined): RouteConfig {
        const dashboardRouteName = `${routeName}_dashboard`;
        return {
            name: routeName,
            path: "dashboard",
            component: () => import(/* webpackChunkName: "dashboard" */ '~/cabinet/vue/client/dashboard/Dashboards.vue'),
            props: () => ({
                typeId: dashboardTypeId(),
                getRawLocationByDashboardId: (dashboardId: string) => {
                    return {
                        name: dashboardRouteName,
                        params: {
                            typeId: dashboardTypeId(),
                            [DASHBOARD_ID]: dashboardId
                        }
                    } as Location;
                }
            }),
            children: [
                {
                    name: dashboardRouteName,
                    path: ":dashboardId",
                    props: (route: Route) => ({
                        typeId: dashboardTypeId(),
                        [DASHBOARD_ID]: route.params.dashboardId
                    })
                }
            ],
            meta: {
                keepAlive
            } as IRouteMeta
        } as RouteConfig;
    }
}

export function getCurrentDashboardIdFromRoute(route: Route): string | null {
    return route.params.dashboardId;
}