import AbstractHtmlConstructorType
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/AbstractHtmlConstructorType";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {HTML_BLOCK_TYPE_TEXT} from "~/cabinet/vue/interface/htmlConstructor/blocks/Constants";
import KnowledgeBaseHtmlContentDisplay
    from "~/cabinet/vue/interface/htmlConstructor/constructorType/knowledgeBase/KnowledgeBaseHtmlContentDisplay.vue";
import {Component} from "vue";

export default class KnowledgeBaseHtmlConstructorType extends AbstractHtmlConstructorType {
    get contentDisplayComponent(): Component {
        return KnowledgeBaseHtmlContentDisplay;
    }

    getDefaultBlockLists(): IHtmlConstructorBlock<any>[] {
        return [
            {
                type: HTML_BLOCK_TYPE_TEXT,
                values: {}
            }
        ];
    }

    get isStyleEditableInContentEditor(): boolean {
        return false;
    }
}