var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'position-relative': true,
        'h-100': true,
        empty: !_vm.value.length && _setup.editable
    },attrs:{"id":_setup.instanceId}},[_c(_setup.editable ? 'draggable' : 'div',{tag:"draggable",class:{
            draggable: true,
            'h-100': true
        },attrs:{"list":_vm.value,"group":{ name: _setup.group, put: [_setup.group], revertClone: true },"handle":".toolbar-button-move","animation":200,"ghost-class":"sortable-ghost"},on:{"change":_setup.onChange}},_vm._l((_vm.value),function(block,key){return _c('div',{key:_setup.getBlockKey(block),class:{
                selected: _setup.isSelected(block),
                block: true,
                [_setup.instanceBlockClass]: true
            }},[_c(_setup.HtmlConstructorBlock,{class:{
                    selected: _setup.isSelected(block)
                },style:(_vm.blockStyle),attrs:{"multi-column":_vm.multiColumn},on:{"remove":function($event){return _setup.makeRemove(key)},"replace":function($event){return _setup.replaceBlock(key, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();return _setup.selectBlock(block)}},model:{value:(_vm.value[key]),callback:function ($$v) {_vm.$set(_vm.value, key, $$v)},expression:"value[key]"}},[(_setup.editable && !_setup.drag && _setup.isSelected(block))?_c('div',{class:['toolbar', _setup.getToolbarPosition(block), 'html-editor-ui']},[(_setup.isShowEditButton(block))?_c('el-button',{attrs:{"type":"text"}},[_c(_setup.FontAwesomeIcon,{attrs:{"icon":"pencil"}})],1):_vm._e(),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"Перетащите блок, зажав левую кнопку мыши"}},[_c('el-button',{staticClass:"toolbar-button-move",attrs:{"type":"text"}},[_c(_setup.FontAwesomeIcon,{attrs:{"icon":"arrows"}})],1)],1),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"Сохранить блок, для того чтобы переиспользовать его в других шаблонах"}},[(_setup.isSaveable(_vm.value[key]))?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _setup.saveBlock(_vm.value[key], key)}}},[_c(_setup.FontAwesomeIcon,{attrs:{"icon":"save"}})],1):_vm._e()],1),_vm._v(" "),_c('el-tooltip',{attrs:{"content":"Удалить блок"}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _setup.removeBlock(key)}}},[_c(_setup.FontAwesomeIcon,{attrs:{"icon":"trash"}})],1)],1)],1):_vm._e()])],1)}),0),_vm._v(" "),_vm._m(0),_vm._v(" "),(_setup.css)?_c(_setup.MyStyle,{attrs:{"id":`${_setup.instanceId}_style`,"root-selector":`#${_setup.instanceId}`,"important":false,"css":_setup.css}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"placeholder absolute-full-size align-items-center html-editor-ui"},[_c('div',{staticClass:"w-100 text-center text-muted"},[_vm._v("Перетащите сюда новые блоки")])])
}]

export { render, staticRenderFns }