import {IHtmlConstructorParams, IHtmlConstructorServerParams} from "~/cabinet/vue/interface/htmlConstructor/Interface";
import {
    HTML_CONSTRUCTOR_BLOCK_TYPES_DICTIONARY
} from "~/cabinet/vue/interface/htmlConstructor/blocks/BlockTypesCollection";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {HTML_CONSTRUCTOR_TYPES} from "~/cabinet/vue/interface/htmlConstructor/constructorType/ConstructorTypeList";
import {ContentVariableInterface} from "~/cabinet/vue/interface/ContentVariableInterface";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";

export default class HtmlConstructorParamsBuilder {
    public static async fetchParams(typeId: string, contentVariables: ContentVariableInterface[], constructorId?: string): Promise<IHtmlConstructorParams> {
        let result = await (new LcabApiRequest<IHtmlConstructorServerParams>({
            url: `/api/htmlConstructor/${typeId}/getParams`,
            p: {
                constructorId
            }
        })).send()
        if (result && result.isSuccess) {
            return this.compileParams(result.data, contentVariables);
        } else {
            throw new Error(result.descr);
        }
    }

    private static compileParams(payload: IHtmlConstructorServerParams, contentVariables: ContentVariableInterface[]): IHtmlConstructorParams {
        let blockTypes = payload.blockTypes
            .filter(item => !!HTML_CONSTRUCTOR_BLOCK_TYPES_DICTIONARY[item.id])
            .map(item => new HTML_CONSTRUCTOR_BLOCK_TYPES_DICTIONARY[item.id](item));
        return {
            ...payload,
            blockTypes,
            blockTypesDictionary: ObjectHelper.dictionaryFromArray(blockTypes, "id"),
            typeObject: HTML_CONSTRUCTOR_TYPES[payload.type.id],
            contentVariables
        };
    }
}