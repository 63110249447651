import Dictionary from "~/ts/library/Dictionary";
import {PagerOpsosInterface} from "~/cabinet/vue/client/sender/pager/Interfaces";
import {DealerTarifTableColumnKeyInterface} from "~/cabinet/vue/dealer/config/uslugi/tarif/common/NewTarifInterfaces";

export default class PagerTarifHelper {
    /** @deprecated */
    public static isSpecPrice(price: Dictionary<number>): boolean {
        let value: number = null;
        for (let key in price) {
            if (price.hasOwnProperty(key)) {
                if (value == null) {
                    value = price[key];
                } else if (value != price[key]) {
                    return true;
                }
            }
        }
        return false;
    }

    /** @deprecated */
    static dictionaryPriceConvert(price: Dictionary<number>): number | number[] {
        let result = [];
        for (let key in price) {
            if (price.hasOwnProperty(key)) {
                result.push(price[key]);
            }
        }
        if (!PagerTarifHelper.isSpecPrice(price)) {
            return result[0];
        }
        return result;
    }

    public static getColumnsByOpsos(opsos: PagerOpsosInterface): PagerTarifOpsosColumnInterface[] {
        let result: PagerTarifOpsosColumnInterface[] = [];
        if (opsos.isUseDealerOpsosSource) {
            if (opsos.isUseDealerOpsosSourceCommon) {
                let key: PagerTarifColumnKeyInterface = {
                    countryId: opsos.countryId,
                    opsosId: opsos.id,
                    isCommon: true,
                    priceColumn: true
                };
                result.push({
                    key,
                    keyString: JSON.stringify(key),
                    opsos,
                    extraDescr: "Общее имя",
                    pricePath: this.columnKeyToPath(key)
                });
            }
            for (let sourceType of opsos.sourceTypeList) {
                let key: PagerTarifColumnKeyInterface = {
                    countryId: opsos.countryId,
                    opsosId: opsos.id,
                    sourceTypeId: sourceType.id,
                    priceColumn: true
                };
                result.push({
                    key,
                    keyString: JSON.stringify(key),
                    opsos,
                    extraDescr: sourceType.descr,
                    pricePath: this.columnKeyToPath(key)
                })
            }

        } else {
            let key: PagerTarifColumnKeyInterface = {
                countryId: opsos.countryId,
                opsosId: opsos.id,
                priceColumn: true
            };
            result.push({
                key,
                keyString: JSON.stringify(key),
                opsos,
                pricePath: this.columnKeyToPath(key)
            })
        }
        return result;

    }

    public static columnKeyToPath(p: PagerTarifColumnKeyInterface) {
        return [p.countryId, "prices", p.opsosId ? p.opsosId : "", p.sourceTypeId ? p.sourceTypeId : ""];
    }

}

export const PAGER_OPSOS_SOURCE_START_PRICE = "startPrice";
export const PAGER_OPSOS_SOURCE_ABON_PRICE = "abonPrice";


export const PAGER_DEALER_OWNER_SERVICE_PRICE = "dealerOwnerServicePrice";
export const PAGER_DEALER_OWNER_PRICE_KEYS = [
    PAGER_DEALER_OWNER_SERVICE_PRICE
];

export interface PagerTarifColumnKeyInterface extends DealerTarifTableColumnKeyInterface {
    countryId: string;
    opsosId?: string;
    isCommon?: boolean;
    sourceTypeId?: string
}

export interface PagerTarifOpsosColumnInterface {
    key: PagerTarifColumnKeyInterface,
    keyString: string,
    opsos: PagerOpsosInterface,
    extraDescr?: string;
    pricePath: string[]
}
