import IStructureBlockValues from "~/cabinet/vue/interface/htmlConstructor/blocks/structure/IStructureBlockValues";
import {IHtmlConstructorBlock} from "~/cabinet/vue/interface/htmlConstructor/Interface";

export const HTML_CONSTRUCTOR_STRUCTURE_MAX_COLUMNS = 5;

export default class StructureBlockTypeHelper {

    public static getColumnsValue(columnWidths: number[]): string {
        return this.cropColumns(columnWidths).join("_");
    }

    private static cropColumns(columnWidths: number[]): number[] {
        let result = [...columnWidths];
        result.splice(HTML_CONSTRUCTOR_STRUCTURE_MAX_COLUMNS, result.length - HTML_CONSTRUCTOR_STRUCTURE_MAX_COLUMNS);
        return result;
    }

    public static getColumnWidthsByValue(value: string): number[] {
        let result = value.split("_")
            .map(value => parseInt(value));
        return this.cropColumns(result);
    }

    public static getColumnsByModel(model: IStructureBlockValues): IStructureBlockTypeColumn[] {
        return this.getColumnWidthsByValue(model.columns)
            .map((width, index) => {
                if (!model.blockLists[index]) {
                    model.blockLists.splice(index, 0, []);
                }
                return {
                    index: index,
                    blocks: model.blockLists[index],
                    width
                };
            });
    }
}

export interface IStructureBlockTypeColumn {
    index: number;
    blocks: IHtmlConstructorBlock<any>[];
    width: number;
}