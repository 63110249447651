var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-col',_vm._b({class:{
        'text-center': true,
        'my-value': true,
        'clickable': !!_vm.$listeners.click,
        'pl-1 pr-1': true,
        'right': _vm.right
    },nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.emits('click')}}},'el-col',_vm.$props,false),[_c(_vm.valueTag,{tag:"h4",class:['value', _vm.uppercase ? 'text-uppercase' : null, _vm.color ? 'text-' + _vm.color : '', _vm.fontWeightNormal ? 'font-weight-normal' : null]},[_vm._t("default")],2),_vm._v(" "),(_vm.label != null || _vm.$slots.label)?_c('div',{class:{
            'label text-muted font-size-micro break-word': true,
            'h-100': _vm.fullHeightLabel,
            'text-uppercase': _vm.uppercaseLabel
        }},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),_vm._t("label")],2):_vm._e(),_vm._v(" "),_vm._t("after-label")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }