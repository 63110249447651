import {SenderSourceInterface} from "~/cabinet/vue/client/sender/send/Interfaces";
import {
    ContentFormParamsInterface,
    SenderContentFormModelInterface
} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {ContentButtonsConfigInterface} from "~/cabinet/vue/client/sender/common/Interface";

export interface IWhatsappSource extends SenderSourceInterface {

}

export const WHATSAPP_CONTENT_TYPE_TEXT = "text";

export interface IWhatsappContentFormValue extends SenderContentFormModelInterface {
    type: "text",
    data: IWhatsappTextContentFormValue;
}

export interface IWhatsappTextContentFormValue {
    text: IWhatsappTextValue;
}

export interface WhatsappContentFormParamsInterface extends ContentFormParamsInterface {
    whatsapp: {
        maxKeyboardButtonCount: number;
        maxKeyboardButtonTextLength: number;
    }
}

export interface IWhatsappTextValue extends ContentButtonsConfigInterface {
    text: string;
    isImageEnabled: boolean;
    imageFileId: string;
    imageFileName: string;
}
