let offsetBase = document.createElement('div');
offsetBase.style.cssText = 'position:absolute;left:0;top:0';
document.body.appendChild(offsetBase);

export default class DomHelper {
    public static isMatchedSelector(e: HTMLElement, selector: string) {
        if (e) {
            var matches = e.matches || (e as any).matchesSelector || e.webkitMatchesSelector || (e as any).mozMatchesSelector || (e as any).msMatchesSelector || (e as any).oMatchesSelector;
            return matches ? matches.call(e, selector) : false;
        } else {
            return false;
        }
    }

    public static setPointerCapture(e: HTMLElement, id: number) {
        if (e.setPointerCapture) {
            e.setPointerCapture(id);
        }
    }

    public static releasePointerCapturer(e: HTMLElement, id: number) {
        if (e.releasePointerCapture) {
            e.releasePointerCapture(id);
        }
    }

    public static fullPath(element: HTMLElement): string | null {
        let result = [];
        while (element) {
            let item = element.tagName;
            if (element == element.ownerDocument.documentElement) {
                result.unshift(element.tagName);
                break;
            } else {
                let id = element.id;
                if (id) {
                    item += "#" + id;
                }
                let parent = element.parentElement;
                if (parent != null) {
                    let childrens = element.parentElement.children;
                    let eq = 0;
                    for (let i = 0; i < childrens.length; i++) {
                        let child = childrens.item(i);
                        eq++;
                        if (child == element) {
                            item += ":nth-child(" + eq + ")";
                            break;
                        }
                    }
                } else {
                    result = [];
                    break;
                }
                element = parent;

                result.unshift(item);
            }
        }
        return result.length ? result.join(" > ") : null;

    }


    public static getOffset(e: HTMLElement | Element) {
        return this.getOffsetRect(e)
    }

    private static isRelative(element: HTMLElement | Element){
        return this.getComputedStyle(element).position == "relative" || this.getComputedStyle(element.parentElement).position == "relative";
    }

    private static getComputedStyle(elem: HTMLElement | Element) {
        return window.getComputedStyle ? getComputedStyle(elem, "") : (elem as any).currentStyle;
    }

    private static getOffsetRect(el: HTMLElement | Element) {
        var
            found,
            left = 0,
            top = 0,
            width = 0,
            height = 0;

        if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
            var boundingRect = el.getBoundingClientRect();
            var baseRect = offsetBase.getBoundingClientRect();
            found = true;
            left = boundingRect.left - baseRect.left;
            top = boundingRect.top - baseRect.top;
            width = boundingRect.right - boundingRect.left;
            height = boundingRect.bottom - boundingRect.top;
        }

        if (el == document.body) {
            if (this.isRelative(el)) {
                var marginTop = this.findFirstMarginTopChild(el);
                top += marginTop;
            }
        }

        return {
            found: found,
            left: left,
            top: top,
            width: width,
            height: height,
            right: left + width,
            bottom: top + height
        };
    }

    private static findFirstMarginTopChild(el: HTMLElement | Element) {
        for (var i = 0; i < el.children.length; i++) {
            let children = el.children[i] as HTMLElement;
            var style = this.getComputedStyle(children);
            if (style.position == "static" || style.position == "relative") {
                if (style.display != "none" && style.display && style.display.indexOf("flex") == -1 && style['float'] != "left" && style['float'] != "right") {
                    var marginTop = parseInt(style.marginTop);
                    if (marginTop) {
                        return marginTop;
                    } else {
                        marginTop = this.findFirstMarginTopChild(children);
                        if (marginTop) {
                            return marginTop;
                        }
                    }
                    break;
                }
            }
        }
        return 0;
    }

    public static unsetAutocomplete() {

        let autocomplete, input;
        let body = document.body;
        if (body && body.querySelectorAll) {
            let textInputs = body.querySelectorAll("input[type=text], input[type=password]");
            for (let i = 0; i < textInputs.length; i++) {
                input = textInputs.item(i);
                autocomplete = input.getAttribute("autocomplete");
                if (autocomplete != "new-password") {
                    if (autocomplete) {
                        input.setAttribute("prev-autocomplete", autocomplete);
                    }
                    input.setAttribute("autocomplete", "new-password");
                }
            }
        }
    }
}