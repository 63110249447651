import AbstractEntity, {AbstractEntityFields} from "~/ts/library/AbstractEntity";
import Auth from "~/cabinet/ts/data/integrations/Auth";
import Platform from "~/cabinet/ts/data/integrations/Platform";
import Dictionary from "~/ts/library/Dictionary";


export default class Integration extends AbstractEntity {
    id: string;
    auth: Auth;
    platform: Platform;
    params: IntegrationParams

    protected getFields(): AbstractEntityFields {
        return {
            auth: Auth,
            platform: Platform,
            params: IntegrationParams
        };
    }
}

class IntegrationParams extends AbstractEntity {
    operators: IntegrationParamsOperators;
    chatConfig: Dictionary<any> = {};
    excludedEventIds: string[];

    protected getFields(): AbstractEntityFields {
        return {
            operators: IntegrationParamsOperators
        };
    }
}

class IntegrationParamsOperators extends AbstractEntity {
    isConfigured: boolean;
    main: IntegrationParamsOperatorsType;
    reserve: IntegrationParamsOperatorsType;


    protected getFields(): AbstractEntityFields {
        return {
            main: IntegrationParamsOperatorsType,
            reserve: IntegrationParamsOperatorsType
        };
    }
}

export class IntegrationParamsOperatorsType extends AbstractEntity {
    logins: string[] = [];
    groupIds: string[] = [];
}