import LcabApiResult from "./LcabApiResult";
import {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";

export default class LcabApiFetchListResult<T> {
    private result: LcabApiResult<any>;
    private _items: T[] = [];

    constructor(result: LcabApiResult<any>, dataType?: AbstractEntityBuilder<T>) {
        this.result = result;
        if (this.isSuccess) {
            let items: T[] = this.result.getData("items");
            if (!Array.isArray(items)) {
                items = [];
            }
            if (dataType) {
                items = items.map(item => dataType.new(item));
            }
            this._items = items;
        }
    }

    get isSuccess(): boolean {
        return this.result.isSuccess;
    }

    showMessageOnError() {
        return this.result.showMessageOnError();
    }

    get descr(): string {
        return this.result.descr;
    }

    get items(): any[] {
        return this._items;
    }

    get count(): number {
        return this.isSuccess ? this.result.getData("count") : 0;
    }

    get page(): number {
        return this.isSuccess ? this.result.getData("page") : 0;
    }

    get onPage(): number {
        return this.isSuccess ? this.result.getData("onPage") : 30;
    }

    get onPageDisabled(): boolean {
        return this.isSuccess ? this.result.getData("onPageDisabled") : false
    }

    get originalResult(): LcabApiResult<any> {
        return this.result;
    }
}