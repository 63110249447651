import {computed, getCurrentInstance} from "vue";

export default function useDialog(props: Readonly<{ visible?: boolean }>/*, visibleComputedProperty: ComputedRef<boolean>*/) {
    let instance = getCurrentInstance().proxy;
    let isVisible = computed({
        get: () => {
            return props.visible;
        },
        set: (value: boolean) => {
            instance.$emit('update:visible', value);
        }
    });

    function close() {
        isVisible.value = false;
    }

    return {
        close,
        isVisible
    }
}