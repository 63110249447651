import {Timeouts} from "~/ts/library/delay/Timeouts";

function getNow() {
    return (new Date()).getTime();
}

let minSaveTime = 500;

let LoadingPlugin = {
    install: function (Vue: any) {

        Vue.mixin({
            data: function() {
                let result: any = {};
                if (!this.$props || !this.$props.hasOwnProperty("loading")) {
                    result.loading = false;
                }
                if (this.saving == null) {
                    result.saving = false;
                }
                return result;
            },
            computed: {
                isParentLoading: function () {
                    return this.$parent && this.$parent.$isLoading;
                },
                $isLoading: function () {
                    return this.loading || this.isParentLoading;
                },
                isSaving: function () {
                    return this.saving;
                }
            }
        });

        Vue.prototype.$loadingStart = function() {
            this.$set(this, "loading", true);
        };

        Vue.prototype.$loadingStop = function() {
            this.$set(this, "loading", false);
        };


        Vue.prototype.$savingStart = function () {
            this._savingStartTime = getNow();
            this.$set(this, "saving", true);
        };

        Vue.prototype.$savingStop = function () {
            let now = getNow();
            let delta = now - this._savingStartTime;
            if (delta < minSaveTime) {
                let that = this;
                let prevStartTime = this._savingStartTime;
                Timeouts.set(function () {
                    if (prevStartTime === that._savingStartTime) {
                        that.$savingStop();
                    }
                }, minSaveTime - delta);
            } else {
                this.$set(this, "saving", false);
            }
        }
    }
};

export default LoadingPlugin;