import Random from "~/ts/library/Random";
import Vue from "vue";
import AbstractApiRequest from "~/core-ui/ts/request/AbstractApiRequest";

export default class ReactiveRequest<T> {
    protected _request: AbstractApiRequest<any, T>;

    public code: number;
    public descr: string;
    public data: T;

    protected loaded = false;
    protected loading = false;
    private uid: string = undefined;
    private callback: (data: T) => void;
    private errorCallback: () => void;
    private showErrorMessage: boolean = true;

    public constructor(request: AbstractApiRequest<any, T>, autoStart: boolean = true) {
        this.reset();
        this._request = request;
        if (typeof this.requestConfig.silent != "boolean") {
            this.requestConfig.silent = true;
        }
        Vue.observable(this);

        if (autoStart) {
            this.make();
        }
    }

    public setShowErrorMessage(value: boolean) {
        this.showErrorMessage = value;
        return this;
    }

    get requestConfig() {
        return this._request.options;
    }

    setCallback(callback?: (data: T) => void) {
        this.callback = callback;
        return this;
    }

    setErrorCallback(callback?: () => void) {
        this.errorCallback = callback;
        return this;
    }

    private reset() {
        this.code = undefined;
        this.descr = undefined;
        this.data = undefined;
    }

    public get isLoading() {
        return this.loading;
    }

    public get isLoaded() {
        return this.loaded;
    }

    get isSuccess() {
        return this.code == 1;
    }

    getRequestParam(key: string) {
        return this.requestConfig.p ? this.requestConfig.p[key] : null;
    }

    addRequestParam(key: string, value: any) {
        if (!this.requestConfig.p) {
            Vue.set(this.requestConfig, "p", {});
        }
        Vue.set(this.requestConfig.p, key, value);
        //this.requestConfig.p[key] = value;
        return this;
    }

    protected processData(data: T) {

    }

    public async make() {
        this.reset();
        let uid = this.uid = Random.uid();

        this.loaded = false;
        this.loading = true;
        try {
            let result = await this._request.send();//new LcabApiRequest<T>(this.requestConfig, this.resultType)).send();
            if (this.uid == uid) {
                if (result.isSuccess) {
                    this.data = result.getData();
                    this.processData(this.data);
                    if (this.callback) {
                        this.callback(this.data);
                    }
                } else {
                    if (this.showErrorMessage) {
                        result.showMessageOnError();
                    }
                    if (this.errorCallback) {
                        this.errorCallback();
                    }
                }
                this.code = result.code;
                this.descr = result.descr;
            }
        } catch (e) {
            if (this.uid == uid && this.errorCallback) {
                this.errorCallback();
            }
        }
        if (this.uid == uid) {
            this.loaded = true;
            this.loading = false;
        }
        return this;
    }

}
