import { render, staticRenderFns } from "./UslugaAccessCard.vue?vue&type=template&id=63857d34&scoped=true&"
import script from "./UslugaAccessCard.vue?vue&type=script&lang=ts&setup=true&"
export * from "./UslugaAccessCard.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63857d34",
  null
  
)

export default component.exports