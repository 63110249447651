import {AttachmentsConfigModelInterface} from "~/cabinet/vue/interface/AttachmentsConfigInterface";
import {SenderContentFormModelInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";
import {ContentButtonsConfigInterface} from "~/cabinet/vue/client/sender/common/Interface";


export const MESSENGER_CONTENT_TYPE_TEXT = "text";

export const CHANNEL_TYPE_RANDOM_FIRST = "randomFirst";
export const CHANNEL_TYPE_ALL_SELECTED = "allSelected";
export const CHANNEL_TYPE_FIRST_SELECTED = "firstSelected";


export interface IMessengerContentFormValue extends SenderContentFormModelInterface {
    type: "text",
    data: IMessengerTextContentFormValue;
    contactPlatformType: "randomFirst" | "allSelected" | "firstSelected";
    platformIds: string[];
}

export interface IMessengerTextContentFormValue extends ContentButtonsConfigInterface {
    text: string;
    attachments: AttachmentsConfigModelInterface[]
}
