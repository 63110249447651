import Random from "~/ts/library/Random";
import Dictionary from "~/ts/library/Dictionary";
import Delay from "~/ts/library/Delay";


export default class Lock {
    private static locks: Dictionary<{
        instanceId: string,
        expire?: number
    }> = {};

    private readonly type: string;
    private readonly timeout: number;
    private readonly instanceId: string;

    constructor(type: string, timeout: number = 0) {
        this.instanceId = Random.uid();
        this.type = type;
        this.timeout = timeout;
    }


    public async lock(waitUntilSuccessfullyLock: boolean = false): Promise<boolean> {
        let $this = this;
        let result = $this.getValue();
        let lockResult = !result || result == this.instanceId;


        if (lockResult) {
            $this.setValue();
        } else if (waitUntilSuccessfullyLock) {
            await Delay.make(50);
            lockResult = await $this.lock(waitUntilSuccessfullyLock);
        }

        return lockResult;
    }

    public async unlock(): Promise<void> {
        await this.lock(true);
        this.unsetValue();
    }


    private getValue(): string | null {
        let result = Lock.locks[this.type];
        if (result) {
            if (result.expire) {
                if ((new Date()).getTime() > result.expire) {
                    result = null;
                }
            }
        }
        return result ? result.instanceId : null;
    }

    private setValue() {
        Lock.locks[this.type] = {
            instanceId: this.instanceId,
            expire: this.timeout ? this.timeout + (new Date()).getTime() : null
        };
    }

    private unsetValue() {
        delete Lock.locks[this.type];
    }


}

