import {BackgroundColor} from "~/core-ui/vue/ui/gradient-picker/Interface";

export default class GradientPickerHelper {
    public static getCssBackground(value: BackgroundColor): string {
        if (typeof value == "string") {
            return value;
        }
        return `linear-gradient(${value.angle}deg, ${value.colors[0]} 0%, ${value.colors[1]} 100%)`;
    }

    public static getOneColor(value: BackgroundColor): string {
        return typeof value == "string" ? value : value.colors[0];
    }
}