import StringHelper from "~/ts/library/StringHelper";

let NumbersPlugin = {
    install: function (Vue: any) {
        Vue.prototype.$numberWithSpaces = (number: string | number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        };


        Vue.filter('numberWithSpaces', function (value: string | number) {
            return Vue.prototype.$numberWithSpaces(value);
        });

        Vue.filter('getSclon', function (value: number, i: string, r: string, rm: string) {
            return StringHelper.getSclon(parseInt(value as any), i, r, rm);
        });
    }
};

export default NumbersPlugin;

