import { render, staticRenderFns } from "./AbtractDataProviderActionBlockForm.vue?vue&type=template&id=4c687691&scoped=true&"
import script from "./AbtractDataProviderActionBlockForm.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AbtractDataProviderActionBlockForm.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c687691",
  null
  
)

export default component.exports