import {onBeforeUnmount} from "vue";

/*
export default function useDisposableEvent() {
    //let vue = getCurrentInstance().proxy;
    let list = ref<Function[]>([]);
    onBeforeUnmount(() => {
        let eventsList = list.value;
        if (eventsList.length) {
            for (let cb of eventsList) {
                cb();
            }
            eventsList.splice(0, eventsList.length);
        }
    });
    return (dispose: Function) => {
        list.value.push(dispose);
    }
}
*/

export function addDisposableEvent(dispose: Function) {
    onBeforeUnmount(() => {
        dispose();
        dispose = null;
    });
}