import AbstractEntity, {AbstractEntityBuilder} from "~/ts/library/AbstractEntity";
import {computed, ExtractPropTypes, getCurrentInstance, PropType, Ref, ref, watch} from "vue";

function propFunc<T>() {
    return {
        value: {
            type: Object as PropType<T>
        }
    }
}

export function useObjectModelByExtractPropTypes<T>(
    props: Readonly<ExtractPropTypes<ReturnType<typeof propFunc<T>>>>,
    getDefaultValue?: () => T,
    initializeCallback?: (model: Ref<T>) => void,
    abstractEntityBuilder?: AbstractEntityBuilder<any>
) {
    return useObjectModel<T>(
        props as Readonly<{ value?: T }>,
        getDefaultValue,
        initializeCallback,
        abstractEntityBuilder
    )
}

export default function useObjectModel<T>(
    props: Readonly<{ value?: T }>/* | Readonly<ExtractPropTypes<ReturnType<typeof propFunc<T>>>>*/,
    getDefaultValue?: () => T,
    initializeCallback?: (model: Ref<T>) => void,
    abstractEntityBuilder?: AbstractEntityBuilder<any>
) {
    let model: Ref<T> = ref<T>();

    let instance = getCurrentInstance().proxy;

    if (!getDefaultValue) {
        getDefaultValue = () => undefined;
    }

    function initializeModel() {
        let changed = true;
        if (props.value == null) {
            model.value = getDefaultValue();
        } else {
            let defaultValue = getDefaultValue();
            changed = false;
            for (let key in defaultValue) {
                if (defaultValue.hasOwnProperty(key) && !props.value.hasOwnProperty(key)) {
                    model.value = {...getDefaultValue() as any, ...props.value as any};
                    changed = true;
                    break;
                }
            }
            if (!changed) {
                model.value = props.value/* as T*/;
            }
        }

        if (abstractEntityBuilder && model.value && !(model.value instanceof (abstractEntityBuilder as typeof AbstractEntity))) {
            model.value = abstractEntityBuilder.new(model.value);
            changed = true;
        }

        if (changed) {
            instance.$emit("input", model.value);
        }

        if (initializeCallback) {
            initializeCallback(model);
        }
    }

    initializeModel();

    watch(computed(() => props.value), () => {
        if (props.value != model.value) {
            initializeModel();
        }
    }, {deep: true});

    return model;
}