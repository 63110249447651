import Usluga from "~/cabinet/ts/data/usluga/Usluga";
import {USLUGA_ID_PCOUNT} from "~/cabinet/ts/Constant";

const Pcount: Usluga = Usluga.new({
    id: USLUGA_ID_PCOUNT,
    dealer: {
        config: {}
    },
    client: {}
});

export default Pcount;
